import React from 'react';
import PropTypes from 'prop-types';

const FilterListComponent = ({ filters, children, ...props }) => (
    <ul className="filter-list" {...props}>
        {filters.map(filter => children(filter))}
    </ul>
);

FilterListComponent.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    children: PropTypes.func.isRequired,
};

export default FilterListComponent;
