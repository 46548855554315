import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { hot } from 'react-hot-loader';
import { connect, Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'react-router-redux';
import { compose } from 'recompose';

import NotificationsSystem from 'reapop';
import theme from '../reapop-theme';

import { history } from '../store';
import App from './App';

const Root = ({ store, i18n /* , translations */ }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
                <Fragment>
                    <NotificationsSystem theme={theme} />
                    <Route path="/" component={App} />
                </Fragment>
            </I18nextProvider>
        </ConnectedRouter>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.shape({}).isRequired,
    i18n: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (/* state */) => ({
    /* translations: state.common.i18n.translations */
});

export default compose(
    hot(module),
    connect(mapStateToProps)
)(Root);
