import React from 'react';
import PropTypes from 'prop-types';

import { Link, Route, Switch } from 'react-router-dom';

import BackButton from './BackButton';

const ToggleableLink = ({ to, exact, children, className, activeClassName }) => (
    <Switch>
        <Route
            path={to}
            exact={exact}
            render={() => (
                <BackButton className={`btn-nav-link ${className} ${activeClassName}`}>{children}</BackButton>
            )}
        />
        <Route
            render={() => (
                <Link to={to} className={`btn-nav-link ${className}`}>
                    {children}
                </Link>
            )}
        />
    </Switch>
);

ToggleableLink.propTypes = {
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
};

ToggleableLink.defaultProps = {
    exact: false,
    className: '',
    activeClassName: '',
};

export default ToggleableLink;
