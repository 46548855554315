import { createActions } from 'redux-actions';
import { normalize } from 'normalizr';

import * as schema from 'reducers/schema';

// Action Creators

export const {
  metadatas: {
    fetch: { request: requestMetadatas, response: receiveMetadatas },
  },
} = createActions({
  METADATAS: {
    FETCH: {
      REQUEST: undefined,
      RESPONSE: [
        metadatas => normalize(metadatas, schema.metadataListSchema),
        (_, type) => ({ type }),
      ],
    },
  },
});
