import React from 'react';
import PropTypes from 'prop-types';

import LegalDisclaimerEn from './LegalDisclaimer.en';
import LegalDisclaimerFr from './LegalDisclaimer.fr';

const LegalDisclaimer = ({ lang }) => (lang === 'fr' ? <LegalDisclaimerFr /> : <LegalDisclaimerEn />);

LegalDisclaimer.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default LegalDisclaimer;
