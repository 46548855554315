import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { translate } from 'react-i18next';
import AsyncSelect from 'react-select/lib/Async';
import isEmpty from 'lodash/isEmpty';
import debounce from 'debounce-promise';

import BackButton from 'components/BackButton';
import LanguageSelect from './LanguageSelectComponent';

const Header = ({ t, user }) => (
    <Fragment>
        <h2>{t('profile')}</h2>
        <small className="text-muted">{user.basicCredentials.user}</small>
    </Fragment>
);
Header.propTypes = {
    user: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
};

const TimezoneSelect = ({ handleTimezoneChange, timezoneOptions, loadTimezoneOptions, timezone, t }) => (
    <div className="form-group">
        <label htmlFor="timezoneSelect">{t('selectTimezoneLabel')}</label>
        <AsyncSelect
            className="react-select"
            classNamePrefix="react-select"
            id="timezoneSelect"
            name="timezoneSelect"
            value={timezone || ''}
            defaultValue={timezone}
            options={timezoneOptions}
            loadOptions={debounce(loadTimezoneOptions, 300)}
            onChange={handleTimezoneChange}
            placeholder={t('selectTimezonePlaceholder')}
            loadingMessage={() => t('optionsLoading')}
            noOptionsMessage={({ inputValue }) => (isEmpty(inputValue) ? t('selectTimezone') : t('optionsNoResults'))}
        />
    </div>
);

TimezoneSelect.propTypes = {
    timezone: PropTypes.shape({}),
    timezoneOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loadTimezoneOptions: PropTypes.func.isRequired,
    handleTimezoneChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};
TimezoneSelect.defaultProps = {
    timezone: undefined,
};

const FilterSelect = ({ defaultFilterId, filters, handleFilterChange, t }) => (
    <div className="form-group">
        <label htmlFor="defaultFilterSelect">{t('selectFilter')}</label>
        <select
            id="defaultFilterSelect"
            name="defaultFilterSelect"
            className="custom-select"
            value={defaultFilterId || ''}
            onChange={handleFilterChange}>
            {filters.map(filter => (
                <option key={filter.id} value={filter.id}>
                    {filter.label.fr}
                </option>
            ))}
        </select>
    </div>
);
FilterSelect.propTypes = {
    defaultFilterId: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};
FilterSelect.defaultProps = {
    defaultFilterId: '',
};

const ProfileComponent = ({
    t,
    user,
    selectedLocale,
    // defaultFilterId,
    timezone,
    timezoneOptions,
    preferences,
    // filters,
    onLogoutClick,
    handleLanguageChange,
    handleTimezoneChange,
    // handleFilterChange,
    handleSubmit,
    loadTimezoneOptions,
}) => (
    <div className="content-wrapper profile-container">
        <Header t={t} user={user} />
        <div>
            <form>
                <LanguageSelect handleLanguageChange={handleLanguageChange} selectedLocale={selectedLocale} t={t} />
                {/* {filters &&
                    filters.length > 0 && (
                        <FilterSelect
                            defaultFilterId={defaultFilterId}
                            filters={filters}
                            handleFilterChange={handleFilterChange}
                            t={t}
                        />
                    )} */}
                <TimezoneSelect
                    handleTimezoneChange={handleTimezoneChange}
                    loadTimezoneOptions={loadTimezoneOptions}
                    timezoneOptions={timezoneOptions}
                    timezone={timezone}
                    t={t}
                />
                <p className="actions">
                    <button type="submit" className="btn-primary btn-block" onClick={handleSubmit}>
                        {t('savePreferences')}
                    </button>
                    <button type="button" className="btn-danger btn-block" onClick={onLogoutClick}>
                        {t('logout', {
                            name: user && user.basicCredentials.user,
                        })}
                    </button>
                    <BackButton className="btn-outline">{t('actions.back')}</BackButton>
                </p>
                <p>
                    <Link to="/rules/legal-disclaimer">{t('legalDisclaimer')}</Link>
                </p>
            </form>
        </div>
    </div>
);
ProfileComponent.propTypes = {
    user: PropTypes.shape({}).isRequired,
    preferences: PropTypes.shape({}).isRequired,
    selectedLocale: PropTypes.string.isRequired,
    timezone: PropTypes.shape({}),
    timezoneOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loadTimezoneOptions: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleTimezoneChange: PropTypes.func.isRequired,
    handleLanguageChange: PropTypes.func.isRequired,
    onLogoutClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

ProfileComponent.defaultProps = {
    timezone: undefined,
};
export default translate()(ProfileComponent);
