import { compose, withState, withHandlers } from 'recompose';

import uuid from 'uuid/v1';
import { SEARCH_FIELDS } from 'utils/constants';

const generateEmptyRefinement = () => ({
  id: uuid(),
  type: '',
  value: '',
});

const emptySearch = {
  searchText: '',
  refinements: [],
  shouldSearchForTopNewsOnly: false,
};

const onResetSearch = ({
  setSearchText,
  setRefinements,
  setShouldSearchForTopNewsOnly,
}) => () => {
  setSearchText(emptySearch.searchText);
  setRefinements(emptySearch.refinements);
  setShouldSearchForTopNewsOnly(emptySearch.shouldSearchForTopNewsOnly);
};

const onSearchChange = ({ setSearchText }) => event => {
  setSearchText(event.target.value);
};
const onShouldSearchForTopNewsOnlyChange = ({
  setShouldSearchForTopNewsOnly,
}) => event => {
  setShouldSearchForTopNewsOnly(event.target.checked);
};

const onAddRefinement = ({ setRefinements }) => () => {
  setRefinements(prevRefinements => [
    ...prevRefinements,
    generateEmptyRefinement(),
  ]);
};

const onChangeValueRefinement = ({ setRefinements }) => (id, value) => {
  setRefinements(prevRefinements =>
    prevRefinements.map(refinement => {
      const mappedRefinement = { ...refinement };
      if (refinement.id === id) {
        mappedRefinement.value = value;
      }

      return mappedRefinement;
    })
  );
};

const onChangeTypeRefinement = ({ setRefinements }) => (id, type) => {
  setRefinements(prevRefinements =>
    prevRefinements.map(refinement => {
      const mappedRefinement = { ...refinement };
      if (refinement.id === id) {
        if (refinement.type !== type) {
          mappedRefinement.type = type;
          mappedRefinement.value = undefined;
        }
      }

      return mappedRefinement;
    })
  );
};

const onDeleteRefinement = ({ setRefinements }) => id => {
  setRefinements(prevRefinements =>
    prevRefinements.filter(refinement => refinement.id !== id)
  );
};

export default compose(
  withState('searchText', 'setSearchText', emptySearch.searchText),
  withState('refinements', 'setRefinements', emptySearch.refinements),
  withState(
    'shouldSearchForTopNewsOnly',
    'setShouldSearchForTopNewsOnly',
    emptySearch.shouldSearchForTopNewsOnly
  ),
  withState('types', 'setTypes', SEARCH_FIELDS),
  withHandlers({
    onResetSearch,
    onSearchChange,
    onAddRefinement,
    onChangeTypeRefinement,
    onChangeValueRefinement,
    onDeleteRefinement,
    onShouldSearchForTopNewsOnlyChange,
  })
);
