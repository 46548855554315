// import { DateTime } from 'luxon';
// import FileSaver from 'file-saver';

import * as api from 'api/events';
import { requestIcsExport, receiveIcsExport } from 'reducers/ics/icsActions';

// Side Effects
// eslint-disable-next-line import/prefer-default-export
export const exportEventsToICS = eventIds => dispatch => {
  dispatch(requestIcsExport());

    return api.exportEventsToICS(eventIds).then(data => {
        dispatch(receiveIcsExport(data));
        return data;
    });
    // .then(data => {
    //     const text = eventIds.length === 1 ? ` - ${eventIds[0]}` : '';
    //     const icsFile = new File([data], `AFP Agenda - ${DateTime.local().toISODate()}${text}.ics`, {
    //         type: 'text/calendar;charset=utf-8',
    //     });
    //     FileSaver.saveAs(icsFile);
    //     // window.open(`data:text/calendar;charset=utf8,${escape(data)}`);
    //     return data;
    // })
};
