import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import throttle from 'lodash/throttle';
import flowRight from 'lodash/flowRight';
import property from 'lodash/property';

import Spinner from 'components/SpinnerComponent';
import EventsByDay from './EventsByDayComponent';

export class EventsListComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.scrollContainer = React.createRef();
    }

    onScroll = flowRight(
        throttle(target => {
            if (target) {
                const { scrollTop, scrollHeight, offsetHeight, clientHeight } = target;
                const { hasMoreEvents, events, isLoading, isInfiniteScrollEnabled, onPaginatedSearch } = this.props;
                const numberOfEventsLoaded = events.length;

                if (
                    !isLoading &&
                    isInfiniteScrollEnabled &&
                    scrollTop >= scrollHeight - offsetHeight - (clientHeight * 50) / 100 &&
                    numberOfEventsLoaded &&
                    hasMoreEvents
                    // window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
                ) {
                    onPaginatedSearch();
                }
            }
        }, 300),
        property('target')
    );

    scrollTop = () => {
        this.scrollContainer.current.scrollTop = 0;
    };

    render() {
        const {
            t,
            events,
            days,
            isLoading,
            hasMoreEvents,
            locale,
            lang,
            userLanguages,
            onPaginatedSearch,
            timezone,
        } = this.props;

        const resultList = (
            <div className="events-wrapper" onScroll={this.onScroll} ref={this.scrollContainer}>
                <div className="events">
                    {Object.keys(days)
                        .sort()
                        .map(
                            day =>
                                days[day].length > 0 && (
                                    <EventsByDay
                                        key={day}
                                        locale={locale}
                                        lang={lang}
                                        userLanguages={userLanguages}
                                        day={day}
                                        events={days[day]}
                                        timezone={timezone}
                                    />
                                )
                        )}
                </div>

                {events.length > 0 && hasMoreEvents && (
                    <div className="info-wrapper more-results">
                        <button
                            type="button"
                            disabled={isLoading}
                            className="btn-outline-strong btn-center"
                            onClick={onPaginatedSearch}>
                            {t('moreResults')}
                            {isLoading && <Spinner />}
                        </button>
                    </div>
                )}

                {!isLoading && !hasMoreEvents && (
                    <div className="info-wrapper">
                        <div className="info-container">
                            <div className="info no-more-results">{t('noMoreResults')}</div>
                            <button
                                type="button"
                                className="btn-reset big-action top-page icon top-page-icon"
                                onClick={this.scrollTop}
                            />
                        </div>
                    </div>
                )}
            </div>
        );

        return (
            <Fragment>
                {isLoading && events.length === 0 && <Spinner />}

                {!isLoading && events.length === 0 ? (
                    <div className="events-wrapper">
                        <div className="event">
                            <div className="info-wrapper">
                                <div className="info">{t('noResultsForSearch')}</div>
                                <Link to="/search" className="btn-outline">
                                    <span className="icon search-icon" />
                                    {t('search.edit')}
                                </Link>
                            </div>
                        </div>
                    </div>
                ) : (
                    resultList
                )}
            </Fragment>
        );
    }
}

EventsListComponent.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    days: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasMoreEvents: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    onPaginatedSearch: PropTypes.func.isRequired,
    isInfiniteScrollEnabled: PropTypes.bool.isRequired,
    timezone: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
};

EventsListComponent.defaultProps = {
    timezone: undefined,
};

export default translate()(EventsListComponent);
