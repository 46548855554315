import { createActions } from 'redux-actions';

import searchTypes from './searchTypes';

const searchActions = createActions({
    QUERY: {
        SET: {
            SEARCH: [undefined, () => ({ type: searchTypes.SEARCH })],
            FILTER: [filter => filter.query, filter => ({ type: searchTypes.FILTER, filterId: filter.id })],
            AGENDA: [
                (query, _) => query,
                (_, agenda) => ({ type: searchTypes.AGENDA, agendaId: agenda.id }),
            ],
        },
        RESET: undefined,
    },
    RESULTS: {
        NEXT: undefined,
        RESET: undefined,
    },
});

export const setQueryFromSearch = searchActions.query.set.search;
export const setQueryFromFilter = searchActions.query.set.filter;
export const setQueryFromAgenda = searchActions.query.set.agenda;
export const resetQuery = searchActions.query.reset;

export const nextResults = searchActions.results.next;
export const resetResults = searchActions.results.reset;
