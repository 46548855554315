import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CookieConsent from 'react-cookie-consent';
import { CONTACT_URL_FR, CONTACT_URL_EN, CONTACT_URL_DE, CONTACT_URL_ES } from 'utils/constants';

class LoginComponent extends PureComponent {
    renderLoginForm() {
        const {
            areCredentialsInvalid,
            hasAttemptedAuth,
            handleSubmit,
            hasAlreadyAcceptedCGU,
            hasTimeout,
            t,
        } = this.props;

        const hasErrored = (hasAttemptedAuth && areCredentialsInvalid) || hasTimeout;

        return (
            <section className="login-form">
                {hasErrored && (
                    <p className="text-danger">
                        {!hasTimeout && areCredentialsInvalid && t('loginError.credentialsInvalid')}
                        {hasTimeout && t('loginError.authTimeout')}
                    </p>
                )}

                <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                    {this.renderLoginField()}
                    {this.renderPassworldField()}
                    {!hasAlreadyAcceptedCGU && this.renderCGUfield()}

                    <button type="submit" className="btn-login" tabIndex={4}>
                        {t('connexion')}
                    </button>
                </form>
            </section>
        );
    }

    renderLoginField() {
        const { formValidated, isInputInvalid, login, handleInputChange, t } = this.props;

        return (
            <div className="form-group">
                <input
                    type="text"
                    name="login"
                    autoComplete="username"
                    tabIndex={1}
                    className={`form-control ${formValidated && isInputInvalid.login ? 'is-invalid' : ''}`}
                    placeholder={t('login')}
                    value={login}
                    onChange={handleInputChange}
                    required
                />
                <div className="invalid-feedback">{isInputInvalid.login && t('loginError.emptyLogin')}</div>
            </div>
        );
    }

    renderPassworldField() {
        const { formValidated, isInputInvalid, password, handleInputChange, t } = this.props;

        return (
            <div className="form-group">
                <input
                    type="password"
                    name="password"
                    autoComplete="password"
                    tabIndex={2}
                    className={`form-control ${formValidated && isInputInvalid.password ? 'is-invalid' : ''}`}
                    placeholder={t('password')}
                    value={password}
                    onChange={handleInputChange}
                    required
                />
                <div className="invalid-feedback">{isInputInvalid.password && t('loginError.emptyPassword')}</div>
            </div>
        );
    }

    renderCGUfield() {
        const { formValidated, hasAcceptedConditions, handleInputChange, t } = this.props;

        return (
            <div className="form-group">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        name="hasAcceptedConditions"
                        tabIndex={3}
                        id="inputAcceptConditions"
                        className={`custom-control-input ${
                            formValidated && !hasAcceptedConditions ? 'is-invalid' : ''
                        }`}
                        checked={hasAcceptedConditions}
                        onChange={handleInputChange}
                        required
                    />
                    <label className="custom-control-label" htmlFor="inputAcceptConditions">
                        {t('acceptConditions')}
                        <Link to="/rules" tabIndex="-1">
                            {t('salesConditions')}
                        </Link>
                    </label>
                    <div className="invalid-feedback">
                        {formValidated && !hasAcceptedConditions && t('loginError.conditionsNotAccepted')}
                    </div>
                </div>
            </div>
        );
    }

    renderContactSection() {
        const { t, locale } = this.props;

	const getContactLink = () => (locale.indexOf('fr') !== -1 ? CONTACT_URL_FR : locale.indexOf('en') !== -1 ? CONTACT_URL_EN : locale.indexOf('es') !== -1 ? CONTACT_URL_ES : locale.indexOf('de') !== -1 ? CONTACT_URL_DE : CONTACT_URL_EN);
        
	return (
            <section className="sign-up">
                <p className="text-muted">
                    <strong>{t('notYetRegistered')}</strong> {t('contactCommercial')}
                </p>
                <a href={getContactLink()} target="_blank" rel="noreferrer noopener" className="btn-contact">
                    {t('askForAccount')}
                </a>

                <p className="help">
                    <Link to="/help" target="_blank" rel="noreferrer noopener">
                        {t('needHelp')}
                    </Link>
                </p>
            </section>
        );
    }

    renderCookieConsent() {
        const { t } = this.props;

        return (
            <CookieConsent
                location="none"
                cookieName="agendaCookieConsent"
                expires={150}
                disableStyles
                buttonText={t('cookieConsent')}
                containerClasses="cookie-consent"
                contentClasses="cookie-consent-text"
                buttonClasses="btn-cookie-consent">
                <Trans i18nKey="cookieConsentText">
                    By continuing to browse, you agree to <Link to="/rules/privacy-policy">our Privacy Policy</Link> and
                    the use of cookies to perform audience analysis in order to improve our services.
                    <HashLink to="/rules/privacy-policy#cookies">More information</HashLink>
                </Trans>
            </CookieConsent>
        );
    }

    render() {
        return (
            <>
                <div className="login-container content">
                    {this.renderLoginForm()}
                    {this.renderContactSection()}
                </div>
                {this.renderCookieConsent()}
            </>
        );
    }
}

LoginComponent.propTypes = {
    areCredentialsInvalid: PropTypes.bool.isRequired,
    hasAttemptedAuth: PropTypes.bool.isRequired,
    hasAlreadyAcceptedCGU: PropTypes.bool.isRequired,
    hasAcceptedConditions: PropTypes.bool.isRequired,
    hasTimeout: PropTypes.bool.isRequired,
    isInputInvalid: PropTypes.shape({}).isRequired,
    formValidated: PropTypes.bool.isRequired,
    login: PropTypes.string,
    password: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};

LoginComponent.defaultProps = {
    login: '',
    password: '',
};

export default translate()(LoginComponent);
