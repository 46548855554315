import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { unique } from 'utils/utils';

import {
  requestFilters,
  requestAddFilter,
  requestUpdateFilter,
  requestDeleteFilter,
  receiveFitlers,
  receiveAddedFitler,
  receiveUpdatedFitler,
  receiveDeleteFitler,
  receiveFitler,
} from './filtersActions';

// Reducers
const isLoading = handleActions(
  {
    [combineActions(
      requestFilters,
      requestAddFilter,
      requestUpdateFilter,
      requestDeleteFilter
    )]: () => true,
    [combineActions(
      receiveFitlers,
      receiveAddedFitler,
      receiveUpdatedFitler,
      receiveDeleteFitler
    )]: () => false,
  },
  false
);

const byId = handleActions(
  {
    [combineActions(
      receiveFitlers,
      receiveFitler,
      receiveAddedFitler,
      receiveUpdatedFitler
    )]: (state, action) => ({
      ...state,
      ...action.payload.entities.filters,
    }),
    [receiveDeleteFitler]: (state, action) => {
      const { [action.payload]: filterToDelete, ...restState } = state;
      return restState;
    },
  },
  {}
);

const byList = handleActions(
  {
    [receiveFitlers]: (state, action) =>
      unique([...state, ...action.payload.result]),
    [receiveAddedFitler]: (state, action) =>
      unique([...state, action.payload.result]),
    [receiveDeleteFitler]: (state, action) => {
      const index = state.findIndex(id => id === action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    },
  },
  []
);

export default combineReducers({
  isLoading,
  byId,
  byList,
});
