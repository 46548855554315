import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import ReactGA from 'react-ga';

import { getLanguageFromLocalStorage } from 'utils/storage';
import { setUpInterceptors } from 'api';

import { changeLocale } from 'reducers/common/commonEffects';

import Root from 'app/Root';

import configureStore, { history } from './store';
import i18n from './i18n';
// import * as serviceWorker from './serviceWorker';

import 'font-awesome/scss/font-awesome.scss';
import './scss/style.scss';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

history.listen(location => {
    ReactGA.pageview(location.pathname);
});

const store = configureStore();
setUpInterceptors(store, history);

const storedLocale = getLanguageFromLocalStorage();
if (storedLocale) {
    store.dispatch(changeLocale(storedLocale));
}

i18n.on('languageChanged', newLocale => {
    store.dispatch(changeLocale(newLocale));
});

const rootEl = document.getElementById('root');
ReactDOM.render(<Root store={store} i18n={i18n} />, rootEl);
// registerServiceWorker();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();

// HMR or react-app-rewire not working with CRA2
if (module.hot) {
    module.hot.accept();
    // module.hot.accept('app/Root', () => {
    //   const NextRoot = require('app/Root').default;
    //   ReactDOM.render(<NextRoot store={store} i18n={i18n} />, rootEl);
    // });
    // module.hot.accept('reducers/root', () => {
    //   const newRootReducer = require('reducers/root').default;
    //   store.replaceReducer(newRootReducer);
    // });
}
