import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Share from './Share/ShareContainer';
import EventDetail from './EventDetail/EventDetailContainer';
import Filter from './FilterForm/FilterContainer';
import Search from './SearchForm/SearchContainer';
import Profile from './UserProfile/ProfileContainer';

const OverlayComponent = () => (
    <div className="overlay">
        <Switch>
            <Route path="/profile" component={Profile} />

            <Route path="/filters/add" component={Filter} />
            <Route path="/filters/:filterId/edit" render={props => <Filter {...props} inEdition />} />
            <Route path="/filters/:filterId/share" render={props => <Share {...props} isSharingFilter />} />

            <Route exact path="/events/:eventId" component={EventDetail} />
            <Route exact path="/events/:eventId/share" render={props => <Share {...props} isSharingEvent />} />

            <Route path="/search" component={Search} />
        </Switch>
    </div>
);

export default OverlayComponent;
