import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import upperFirst from 'lodash/upperFirst';

import VisibilitySensor from 'react-visibility-sensor';

import Event from './EventComponent';

export class EventsByDayComponent extends PureComponent {
    state = { isVisible: false };

    onVisibibleChange = isVisible => this.setState({ isVisible });

    render() {
        const isToday = day => DateTime.local().toFormat('yyyy-MM-dd') === day;

        const { day, locale, lang, userLanguages, events, timezone, t } = this.props;
        const { isVisible } = this.state;

        return (
            <div className={classNames('day', { visible: isVisible })}>
                <VisibilitySensor onChange={this.onVisibibleChange} offset={{ top: 10 }}>
                    <h3>
                        {isToday(day)
                            ? t('today')
                            : upperFirst(
                                DateTime.fromISO(day)
                                    .setLocale(locale)
                                    .toLocaleString(DateTime.DATE_HUGE)
                            )}
                    </h3>
                </VisibilitySensor>
                <div className="events-by-day">
                    {events.map(
                        event =>
                            event && (
                                <Event
                                    key={event.sequenceId}
                                    locale={locale}
                                    lang={lang}
                                    userLanguages={userLanguages}
                                    event={event}
                                    day={day}
                                    timezone={timezone}
                                />
                            )
                    )}
                </div>
            </div>
        );
    }
}

EventsByDayComponent.propTypes = {
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    timezone: PropTypes.shape({}).isRequired,
    day: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(EventsByDayComponent);
