import React from 'react';
import PropTypes from 'prop-types';

import PrivacyPolicyEn from './PrivacyPolicy.en';
import PrivacyPolicyFr from './PrivacyPolicy.fr';

const PrivacyPolicy = ({ lang }) => (lang === 'fr' ? <PrivacyPolicyFr /> : <PrivacyPolicyEn />);

PrivacyPolicy.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default PrivacyPolicy;
