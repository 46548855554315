import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
    getResultEvents,
    getIsSearchLoading,
    getQuery,
    getNextLink,
    getLocale,
    getUserTimezone,
    getSearchFilterId,
    getLang,
    getUserLanguages,
    getDaysFromEvents,
} from 'reducers/selectors';
import * as searchActions from 'reducers/search/searchActions';
import * as searchEffects from 'reducers/search/searchEffects';

import { EVENTS_PER_PAGE, MAX_INFINITE_SCROLL } from 'utils/constants';
import EventsList from './EventsListComponent';

class EventsContainer extends PureComponent {
    componentDidMount() {
        const { query } = this.props;

        this.doSearch({ query });
    }

    componentWillReceiveProps(nextProps) {
        const { query: currentQuery, searchFilterId: currentFilterId } = this.props;
        const { query: nextQuery, searchFilterId: nextFilterId } = nextProps;

        if (currentFilterId !== nextFilterId || !isEqual(nextQuery, currentQuery)) {
            this.doSearch({ query: nextQuery });
        }
    }

    onPaginatedSearch = () => {
        const { nextLink, simpleSearch } = this.props;

        simpleSearch({ nextLink });
    };

    doSearch({ query }) {
        const { simpleSearch, lang, timezone } = this.props;

        simpleSearch({ query, lang, timezone });
    }

    render() {
        const { locale, lang, userLanguages, events, days, nextLink, timezone, isLoading } = this.props;
        const isInfiniteScrollEnabled = events && events.length % MAX_INFINITE_SCROLL !== 0;

        return (
            (events && (
                <EventsList
                    isLoading={isLoading}
                    events={events}
                    days={days}
                    hasMoreEvents={nextLink !== '' && events.length % EVENTS_PER_PAGE === 0}
                    isInfiniteScrollEnabled={isInfiniteScrollEnabled}
                    locale={locale}
                    lang={lang}
                    userLanguages={userLanguages}
                    timezone={timezone}
                    onPaginatedSearch={this.onPaginatedSearch}
                />
            )) ||
            null
        );
    }
}

const mapStateToProps = state => ({
    query: getQuery(state),
    searchFilterId: getSearchFilterId(state),
    events: getResultEvents(state),
    days: getDaysFromEvents(state),
    nextLink: getNextLink(state),
    isLoading: getIsSearchLoading(state),
    locale: getLocale(state),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
    timezone: getUserTimezone(state),
});

EventsContainer.propTypes = {
    query: PropTypes.shape({}),
    nextLink: PropTypes.string.isRequired,
    searchFilterId: PropTypes.string,
    simpleSearch: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    isLoading: PropTypes.bool.isRequired,
    timezone: PropTypes.shape({}),
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    days: PropTypes.shape({}).isRequired,
};

EventsContainer.defaultProps = {
    query: null,
    timezone: undefined,
    searchFilterId: null,
};

export default connect(
    mapStateToProps,
    { ...searchActions, ...searchEffects }
)(EventsContainer);
