import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

i18n.use(XHR)
    .use(LanguageDetector)
    .use(reactI18nextModule) // if not using I18nextProvider
    .init({
        load: 'currentOnly',
        fallbackLng: 'en-GB',
        debug: process.env.NODE_ENV === 'development',

        ns: [
            // 'common',
        ],
        defaultNS: 'common',

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        // react i18next special options (optional)
        react: {
            wait: true,
            bindStore: false,
            bindI18n: 'languageChanged',
        },
    });

export default i18n;
