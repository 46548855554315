import React from 'react';
import { PropTypes } from 'prop-types';

import classNames from 'classnames';
import { translate } from 'react-i18next';

import Refinements from './RefinementsComponent';

const SearchFormComponent = ({
    t,
    searchText,
    handleSearchChange,
    handleShouldSearchForTopNewsOnlyChange,
    shouldSearchForTopNewsOnly,
    ...props
}) => {
    const renderFieldTopNews = () => (
        <div
            className={classNames('top-news-checkbox', {
                checked: shouldSearchForTopNewsOnly,
            })}>
            <div className={classNames('form-group', 'custom-checkbox')}>
                <input
                    type="checkbox"
                    id="topnews"
                    className="custom-control-input"
                    checked={shouldSearchForTopNewsOnly || false}
                    onChange={handleShouldSearchForTopNewsOnlyChange}
                />
                <label className="custom-control-label" htmlFor="topnews">
                    {t('searchOnlyTopNews')}
                </label>
            </div>
        </div>
    );

    return (
        <div className="search-form">
            <div className="input-group">
                <input
                    className="form-control standard"
                    type="text"
                    name="searchText"
                    value={searchText || ''}
                    placeholder={t('search.placeholder')}
                    onChange={handleSearchChange}
                />
                {/* @TODO ajouter croix pour vider le champ */}
            </div>

            <Refinements {...props} />

            {renderFieldTopNews()}
        </div>
    );
};

SearchFormComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    searchText: PropTypes.string.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    handleShouldSearchForTopNewsOnlyChange: PropTypes.func.isRequired,
    shouldSearchForTopNewsOnly: PropTypes.bool.isRequired,
};

export default translate()(SearchFormComponent);
