import { DateTime } from 'luxon';
import uuid from 'uuid/v1';

import { fetchMetadatasByCodes } from 'reducers/metadatas/metadatasEffects';
import { mapMetadatasToOptions } from 'utils/utils';
import { SEARCH_FIELD_TYPES, METADATA_TYPES, FIELD_TO_QUERY_MAP } from 'utils/constants';

export const refinementsToQuery = refinements => {
    const params = {
        services: [],
        iptcs: [],
        regions: [],
        locations: [],
        agendas: [],
        relativeDuration: null,
        fixedDateFrom: null,
        fixedDateTo: null,
    };

    refinements.forEach(refinement => {
        if (refinement.type === SEARCH_FIELD_TYPES.service && refinement.value) {
            params.services.push(refinement.value);
        } else if (refinement.type === SEARCH_FIELD_TYPES.region && refinement.value) {
            params.regions.push(refinement.value);
        } else if (refinement.type === SEARCH_FIELD_TYPES.agendaPreset && refinement.value) {
            params.agendas.push(refinement.value);
        } else if (refinement.type === SEARCH_FIELD_TYPES.iptcLvl1 && refinement.value) {
            params.iptcs.push(refinement.value);
        } else if (refinement.type === SEARCH_FIELD_TYPES.location && refinement.value) {
            params.locations.push(refinement.value.value);
        } else if (refinement.type === SEARCH_FIELD_TYPES.fromdate && refinement.value) {
            params.fixedDateFrom = DateTime.fromISO(refinement.value).toString();
        } else if (refinement.type === SEARCH_FIELD_TYPES.todate && refinement.value) {
            params.fixedDateTo = DateTime.fromISO(refinement.value).toString();
        } else if (refinement.type === SEARCH_FIELD_TYPES.relativeDuration && refinement.value) {
            params.relativeDuration = refinement.value;
        }
    });

    return params;
};

export const mapSearchToQuery = ({ searchText, refinements, shouldSearchForTopNewsOnly }) => ({
    type: 'simple',
    text: searchText,
    ...refinementsToQuery(refinements),
    topNewsOnly: shouldSearchForTopNewsOnly,
});

export const queryToRefinements = (lang, userLanguages) => query => addRefinement => {
    Object.keys(FIELD_TO_QUERY_MAP).forEach(type => {
        const queryField = (query && query[FIELD_TO_QUERY_MAP[type]]) || [];
        if (queryField.length > 0) {
            fetchMetadatasByCodes(type, queryField)
                .then(mapMetadatasToOptions(lang, userLanguages)(type))
                .then(metadatas => {
                    metadatas.forEach(metadata => {
                        addRefinement({
                            id: uuid(),
                            type,
                            value: metadata.value,
                        });
                    });
                });
        }
    });

    if (query && query.locations && query.locations.length > 0) {
        fetchMetadatasByCodes(METADATA_TYPES.location, query.locations)
            .then(mapMetadatasToOptions(lang, userLanguages)(METADATA_TYPES.location))
            .then(locations => {
                locations.forEach(location => {
                    addRefinement({
                        id: uuid(),
                        type: SEARCH_FIELD_TYPES.location,
                        value: location,
                    });
                });
            });
    }

    if (query && query.fixedDateFrom) {
        addRefinement({
            id: uuid(),
            type: SEARCH_FIELD_TYPES.fromdate,
            value: DateTime.fromISO(query.fixedDateFrom).toFormat('yyyy-MM-dd'),
        });
    }

    if (query && query.fixedDateTo) {
        addRefinement({
            id: uuid(),
            type: SEARCH_FIELD_TYPES.todate,
            value: DateTime.fromISO(query.fixedDateTo).toFormat('yyyy-MM-dd'),
        });
    }

    if (query && query.relativeDuration) {
        addRefinement({
            id: uuid(),
            type: SEARCH_FIELD_TYPES.relativeDuration,
            value: query.relativeDuration,
        });
    }
};
