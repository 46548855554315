import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import {
  requestAfoDocs,
  receiveAfoDocs,
  resetSelectedEvent,
} from 'reducers/events/eventActions';

const isLoading = handleActions(
  {
    [requestAfoDocs]: () => true,
    [combineActions(receiveAfoDocs, resetSelectedEvent)]: () => false,
  },
  false
);

const docs = handleActions(
  {
    [receiveAfoDocs]: (_, action) => action.payload,
    [resetSelectedEvent]: () => null,
  },
  null
);

export default combineReducers({ docs, isLoading });
