import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import {
    receiveSession,
    authLogout,
    receiveSavedPreferences,
    requestSession,
    requestSavePreferences,
    authTimeout,
    attemptAuth,
} from './usersActions';

// Reducer

const authUser = handleActions(
    {
        [receiveSession]: {
            next: (_, action) => action.payload,
        },
        [authLogout]: () => null,
    },
    null
);

const preferences = handleActions(
    {
        [combineActions(receiveSession, receiveSavedPreferences)]: {
            next: (_, action) => action.payload.preferences,
        },
        [authLogout]: () => null,
    },
    null
);

const isLoading = handleActions(
    {
        [combineActions(requestSession, requestSavePreferences)]: () => true,
        [combineActions(receiveSession, receiveSavedPreferences, authLogout)]: () => false,
    },
    false
);

const isAuthenticated = handleActions(
    {
        [receiveSession]: { next: () => true, throw: () => false },
        [authTimeout]: () => false,
        [authLogout]: () => false,
    },
    false
);

const hasTimeout = handleActions(
    {
        [authTimeout]: () => true,
        [combineActions(receiveSession, authLogout)]: { next: () => false },
    },
    false
);

const areCredentialsInvalid = handleActions(
    {
        [requestSession]: () => false,
        [combineActions(receiveSession, authLogout)]: { next: () => false, throw: () => true },
    },
    false
);

const hasAttemptedAuth = handleActions(
    {
        [attemptAuth]: () => true,
        [combineActions(authTimeout, authLogout)]: { next: () => false },
    },
    false
);

export default combineReducers({
    isLoading,
    isAuthenticated,
    authUser,
    preferences,
    hasTimeout,
    areCredentialsInvalid,
    hasAttemptedAuth,
});
