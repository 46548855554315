import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import * as searchEffects from 'reducers/search/searchEffects';
import { getFilter, getSearchFilterId, getMetadata, getLang, getUserLanguages } from 'reducers/selectors';
import { stringForLang } from 'utils/locale';

const getFilterIdFromMatchParams = ({ match }) => match.params.filterId;

class FilterResultsContainer extends PureComponent {
    componentDidMount() {
        const { matchFilterId, searchFilterId, setFilterSearch, filter } = this.props;

        if (matchFilterId !== searchFilterId) {
            setFilterSearch(matchFilterId);

            if (filter) {
                this.updateTitle(filter);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { searchFilterId: currentSearchFilterId } = this.props;
        const {
            matchFilterId: nextMatchFilterId,
            searchFilterId: nextSearchFilterId,
            setFilterSearch,
            filter: nextFilter,
        } = nextProps;

        const isMatchFilterIdDifferentOfCurrentSearchFilterId =
            nextMatchFilterId !== currentSearchFilterId && nextMatchFilterId !== nextSearchFilterId;
        const doesFilterExists = nextFilter;

        if (isMatchFilterIdDifferentOfCurrentSearchFilterId && doesFilterExists) {
            setFilterSearch(nextMatchFilterId);
            this.updateTitle(nextFilter);
        }
    }

    updateTitle = filter => {
        const { t, lang, userLanguages } = this.props;
        document.title = `${t('titles.My_agenda')}:  ${stringForLang({ field: filter.label, lang, userLanguages })}`;
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state, ownProps) => ({
    matchFilterId: getFilterIdFromMatchParams(ownProps),
    searchFilterId: getSearchFilterId(state),
    filter:
        getFilter(state, getFilterIdFromMatchParams(ownProps)) ||
        getMetadata(state, getFilterIdFromMatchParams(ownProps)),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
});

FilterResultsContainer.propTypes = {
    t: PropTypes.func.isRequired,
    matchFilterId: PropTypes.string.isRequired,
    searchFilterId: PropTypes.string,
    filter: PropTypes.shape({}),
    setFilterSearch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};

FilterResultsContainer.defaultProps = {
    searchFilterId: undefined,
    filter: undefined,
};

export default compose(
    translate(),
    connect(
        mapStateToProps,
        { ...searchEffects }
    )
)(FilterResultsContainer);
