import api, { apiAbsolute, mapResponseData } from 'api';
import { EVENTS_PER_PAGE } from 'utils/constants';

export const fetchEvent = sequenceId => {
    const url = `/event/${sequenceId}`;

    return api.get(url).then(mapResponseData);
};

export const fetchAFODocs = sequenceId => {
    const url = `/event/${sequenceId}/afodocs`;

    return api.get(url, { responseType: 'text' }).then(mapResponseData);
};

/**
 * /events-query?max=25&searchQuery=';
 *
 * SOLR : max est le nombre d'éléments retournés
 *
 * On construit la requête qui sera envoyée à SOLR à partir de ce qu'on souhaite afficher :
 *   limit : le nombre d'éléments par page
 */
export const simpleSearch = ({ query = '', nextLink = '', limit = EVENTS_PER_PAGE, lang, timezone }) => {
    let request = null;

    if (nextLink) {
        request = apiAbsolute.get(nextLink);
    } else {
        request = api.get(
            `/events-query?lang=${lang}&timezone=${encodeURIComponent(
                timezone.code
            )}&max=${limit}&searchQuery=${encodeURIComponent(JSON.stringify(query))}`
        );
    }

    return request
        .then(mapResponseData)
        .then(async data => {
            const { events: eventIds, next } = data;
            const events = await Promise.all(
                eventIds.map(event =>
                    apiAbsolute
                        .get(event.url)
                        .then(mapResponseData)
                        .catch(() => undefined)
                )
            );

            return { events, next };
        })
        .catch(err => ({ events: [], next: '' }));
};

export const exportEventsToICS = eventIds => {
    const url = `/events/export/calendar`;

    const headers = {
        'Accept-Language': 'fr/FR',
    };

    return api
        .post(url, JSON.stringify(eventIds), {
            headers,
        })
        .then(mapResponseData);
};
