import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { Link, Route, Switch, Redirect } from 'react-router-dom'
import { getLang } from 'reducers/selectors'
import { translate } from 'react-i18next'
import { changeLocale } from 'reducers/common/commonEffects'
import HelpContainer from './Help/HelpContainer'
import RulesContainer from './LegalDocs/RulesContainer'

import logo from '../images/logo-2020-white.svg'
import appIcon from '../images/app-icon.png'
import LanguageSelect from './UserProfile/LanguageSelectComponent'

class AnonymousLayout extends PureComponent {
    handleLanguageChange = (event) => {
        const { dispatch } = this.props
        dispatch(changeLocale(event.target.value))
    }

    render() {
        const { lang, t } = this.props

        return (
            <div className="anonymous-layout">
                <div className="navbar public-navbar">
                    <div className="container">
                        <div className="mr-left">
                            <Link to="/help">
                                <img src={logo} alt="" />
                                <h1>AFP Agenda</h1>
                            </Link>
                        </div>
                        <div className="languages-selector">
                            <LanguageSelect
                                shortLabel
                                onlySelect
                                selectedLocale={lang}
                                handleLanguageChange={this.handleLanguageChange}
                            />
                        </div>
                    </div>
                </div>
                <Route
                    path="/help"
                    render={() => (
                        <div className="agenda-back navbar">
                            <div className="container">
                                <Link to="/">
                                    <i className="icon slide-right-icon" />
                                    <span>{t('help.GoToAFPAgenda')}</span>
                                </Link>
                                <Link to="/">
                                    <img src={appIcon} className="app-icon" alt="" />
                                </Link>
                            </div>
                        </div>
                    )}
                />
                <main>
                    <Switch>
                        <Route path="/help/faq" component={HelpContainer} />
                        <Route path="/help/videos" component={HelpContainer} />
                        <Route path="/help" component={HelpContainer} />

                        <Route path="/rules/privacy-policy" component={RulesContainer} />
                        <Route path="/rules/cgu" component={RulesContainer} />
                        <Route path="/rules/legal-disclaimer" component={RulesContainer} />
                        <Route path="/rules" component={RulesContainer} />

                        <Redirect to="/help" />
                    </Switch>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: getLang(state),
})

AnonymousLayout.propTypes = {
    lang: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

export default compose(
    translate(),
    connect(mapStateToProps),
)(AnonymousLayout)
