import React from 'react';

const IcnSave = () => (
    // <svg width={48} height={48} {...props}>
    //     <g fill="none" fillRule="evenodd">
    //         <path d="M0 0h48v48H0z" />
    //         <path
    //             className="icon"
    //             d="M26.88 20h6.456c.864 0 1.344.36 1.344.816 0 .312-.264.72-.792 1.056l-5.424 3.504 2.328 6.456c.096.288.144.528.144.744 0 .528-.264.84-.696.84-.048 0-.12 0-.168-.024-.264-.048-.552-.192-.864-.456L24 28.472l-5.208 4.464c-.384.336-.744.48-1.032.48-.408 0-.696-.312-.696-.84 0-.072 0-.144.024-.216.024-.168.048-.336.12-.528l2.328-6.456-5.424-3.504c-.528-.336-.792-.744-.792-1.056 0-.456.48-.816 1.344-.816h6.456l1.896-6.384c.216-.72.6-1.08.984-1.08s.768.36.984 1.08L26.88 20z"
    //             fill="#A3ACB1"
    //         />
    //     </g>
    // </svg>
    <span className="icon bookmark-icon" />
);

export default IcnSave;
