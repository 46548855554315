import { requestAfoAdvisoriesLink, receiveAfoAdvisoriesLink } from 'reducers/afo/afoActions';
import * as api from 'api/afo';

/* eslint-disable */
export const fetchAFOAdvisoriesLink = lang => dispatch => {
    dispatch(requestAfoAdvisoriesLink());

    return api.fetchAFOAdvisoriesLink(lang).then(afoLink => dispatch(receiveAfoAdvisoriesLink(afoLink)));
};
/* eslint-enable */
