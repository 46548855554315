import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { compose } from 'recompose';

import { getLang } from 'reducers/selectors';
import { getLanguageFromLocalStorage } from 'utils/storage';

import BackButton from 'components/BackButton';

import CGU from './CGU';
import LegalDisclaimer from './LegalDisclaimer';
import PrivacyPolicy from './PrivacyPolicy';

class RulesContainer extends PureComponent {
    componentDidMount() {
        const { t } = this.props;
        document.title = t('titles.Legal_disclaimer');
    }

    render() {
        const { t, lang, match } = this.props;
        let tabSelected = 0;
        if (match && match.path === '/rules/legal-disclaimer') {
            tabSelected = 0;
        } else if (match && match.path === '/rules/cgu') {
            tabSelected = 1;
        } else if (match && match.path === '/rules/privacy-policy') {
            tabSelected = 2;
        }

        return (
            <div className="rules-container">
                <Tabs selected={tabSelected}>
                    <Tab label={t('legalDisclaimer')}>
                        <LegalDisclaimer lang={lang} />
                    </Tab>
                    <Tab label={t('cgu')}>
                        <CGU lang={lang} />
                    </Tab>
                    <Tab label={t('privacyPolicy')}>
                        <PrivacyPolicy lang={lang} />
                    </Tab>
                </Tabs>

                <section className="back-button">
                    <BackButton className="btn-outline-strong">
                        <span className="icon slide-left-icon" />
                        &nbsp;
                        {t('actions.back')}
                    </BackButton>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    lang: getLang(state) || getLanguageFromLocalStorage(),
});

RulesContainer.propTypes = {
    match: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(RulesContainer);
