import { getLocale } from 'reducers/common/commonSelectors';
import { parseToLocale } from 'utils/locale';

import i18n from 'i18n';

import { setLocale } from './commonActions';

// Side Effects
// eslint-disable-next-line import/prefer-default-export
export const changeLocale = locale => (dispatch, getState) => {
  const nextLocale = parseToLocale(locale);

  const currentLocale = getLocale(getState().common);

  if (nextLocale !== currentLocale) {
    dispatch(setLocale(nextLocale));
    i18n.changeLanguage(nextLocale);
  }
};
