import React from 'react';

const PrivacyPolicyFR = () => (
    <div className="legal-content">
        <h1>CHARTE SUR LA PROTECTION DES DONNEES PERSONNELLES</h1>

        <p>
            La présente Charte sur la protection des données à caractère personnel vise à vous informer sur la manière
            dont l’AFP, en sa qualité de responsable du traitement des données personnelles des utilisateurs du Site,
            collecte, traite et partage vos données personnelles.
        </p>

        <h2>1. Les données que nous recueillons</h2>
        <p>
            Nous sommes susceptibles de recueillir les données énumérées ci-après dans le cadre de votre utilisation du
            Site.
        </p>
        <p>
            Notez que nous ne sollicitons jamais de votre part la transmission de données sensibles au sens de la loi et
            nous vous demandons de ne pas nous transmettre de telles données.{' '}
        </p>

        <h3>1.1 Informations que vous nous communiquez</h3>
        <p>
            Le service AFP Agenda proposé sur ce Site est un service optionnel qui implique votre souscription préalable
            à d’autres services de l’AFP. Vos données de contact et de connexion au Site sont les mêmes que celles que
            vous nous avez transmises lors de votre souscription aux autres services de l’AFP et vous ne serez pas
            invité à nous transmettre d’autres données pour ce service spécifique.{' '}
        </p>
        <p>
            Certains liens disponibles sur le Site vous permettent d’accéder à des formulaires à partir desquels vous
            êtes susceptible de nous communiquer d’autres données. Ces formulaires étant situés sur d’autres sites de
            l’AFP (afp.com et afpforum en particulier), nous vous invitons à prendre connaissance de la politique
            relative à la protection des données personnelles applicable à ces autres sites lorsque vous y accédez.{' '}
        </p>

        <h3>1.2 Informations que nous collectons lorsque vous utilisez le Site</h3>
        <p>
            Lors de chacune de vos visites sur notre Site, nous sommes susceptibles de recueillir les données
            suivantes :
        </p>
        <ul>
            <li>
                Données relatives aux appareils, aux réseaux et aux plateformes que vous utilisez : identifiant de
                l’équipement que vous utilisez (adresse IP, identifiant Apple, identifiant Android, etc.), données de
                connexion (date, heure, durée), type et version du logiciel de navigation (Internet Explorer, Safari,
                Mozilla Firefox, Google Chrome, etc.), type de système d’exploitation (Microsoft Windows, Apple Os,
                Linux, Unix, etc.).
            </li>
            <li>
                Données relatives à vos préférences et autres actions sur le Site : langue d’interface, référence de la
                page de démarrage de l’utilisateur, date d’acceptation des CGU et adresse IP de l’appareil ayant accepté
                les CGU.
            </li>

            <li>
                Données relatives à votre navigation : parcours sur les pages du Site et durée de consultation de
                certaines pages, contenu auquel vous accédez ou que vous consultez, recherches et résultats des
                recherches et de façon générale vos actions sur le Site.
            </li>
        </ul>

        <p>
            Pour recueillir certaines des données listées ci-dessus, nous pouvons avoir recours à l’utilisation de
            cookies. Pour en savoir plus sur l’utilisation des cookies, reportez-vous à la partie 4 ci-dessous.
        </p>

        <h2>2. Finalités d’utilisation des données</h2>
        <p>
            Les données que nous recueillons sont utilisées pour les finalités et sur la base des fondements décrits
            ci-dessous.
        </p>

        <table>
            <thead>
                <tr>
                    <th>Finalités d’utilisation</th>
                    <th>Fondement légal</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Exécuter les contrats conclus entre vous et nous et vous fournir les informations et services
                        demandés
                    </td>
                    <td>Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives</td>
                </tr>
                <tr>
                    <td>
                        Gérer votre compte utilisateur ou des comptes utilisateurs de votre employeur, notamment par
                        l’attribution et l’envoi de rappels concernant votre identifiant/mot de passe, l’envoi de
                        notifications relatives aux services, aux opérations de maintenance, à la modification de nos
                        CGU ou de la présente Charte relative aux données personnelles
                    </td>
                    <td>
                        Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives et/ou
                        réalisé avec votre consentement
                    </td>
                </tr>
                <tr>
                    <td>
                        Vérifier la bonne exécution du contrat conclu entre vous et nous, assurer un suivi de votre
                        utilisation au regard des conditions souscrites, vérifier la bonne exécution de nos CGU et le
                        cas échéant vérifier le respect de la loi applicable.
                    </td>
                    <td>
                        Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives et/ou
                        réalisé avec votre consentement et/ou nécessaire à la constatation, l’exercice ou la défense
                        d’un droit en justice
                    </td>
                </tr>
                <tr>
                    <td>Vous fournir la possibilité de personnaliser vos préférences sur le Site</td>
                    <td>
                        Ce traitement est fondé sur la réalisation de votre intérêt légitime et/ou réalisé avec votre
                        consentement
                    </td>
                </tr>
                <tr>
                    <td>Traiter et corriger d’éventuels incidents ou anomalies de fonctionnement du Site</td>
                    <td>
                        Ce traitement est fondé sur la réalisation de notre intérêt légitime (assurer le fonctionnement
                        et la sécurité du Site)
                    </td>
                </tr>
                <tr>
                    <td>Pour des opérations internes d’audit et d’analyse des données et de statistiques d’audience</td>
                    <td>
                        Ce traitement est fondé sur la réalisation de notre intérêt légitime (mieux comprendre les
                        intérêts des utilisateurs et améliorer nos services et notre support)
                    </td>
                </tr>
            </tbody>
        </table>

        <h2>3. Durée de conservation des données</h2>
        <p>
            Vos données sont conservées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour
            lesquelles elles sont traitées. Les tableaux ci-dessous mentionnent les principales durées de conservation
            de vos données.
        </p>
        <table>
            <thead>
                <tr>
                    <th>Catégories de données</th>
                    <th>Durée de conservation</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Données de connexion et de navigation</td>
                    <td>15 mois</td>
                </tr>
                <tr>
                    <td>Cookies</td>
                    <td>13 mois</td>
                </tr>
                <tr>
                    <td>Simple demande d’information</td>
                    <td>3 ans après la clôture du dossier portant sur cette demande</td>
                </tr>
                <tr>
                    <td>Plainte, demande d’un droit d’accès</td>
                    <td>10 ans après la clôture du dossier (au-delà de cette durée, ces données sont archivées)</td>
                </tr>
                <tr>
                    <td>
                        Données relatives à l’exécution du contrat : gestion du compte client, de la facturation, des
                        paiements
                    </td>
                    <td>10 ans après la fin du contrat</td>
                </tr>
                <tr>
                    <td>
                        Données de contact de clients : nom, prénom, civilité, email, numéro de téléphone, activité,
                        société
                    </td>
                    <td>5 ans après la fin du contrat</td>
                </tr>
                <tr>
                    <td>
                        Données de contact de prospects : nom, prénom, email, civilité, numéro de téléphone, activité,
                        société
                    </td>
                    <td>3 ans après la collecte des données ou du dernier contact émanant de votre part</td>
                </tr>
            </tbody>
        </table>

        <h2 id="cookies">4. Utilisation des cookies</h2>
        <p>
            Nous utilisons des cookies pour améliorer la qualité de nos services et votre expérience en tant
            qu’utilisateur.{' '}
        </p>

        <h3>4.1 Qu’est-ce qu’un cookie ?</h3>
        <p>
            Un cookie est un fichier texte de petite taille déposé par un site web sur le terminal de l’internaute. La
            majorité des sites internet utilisent des cookies pour améliorer leur fonctionnement et optimiser la qualité
            de navigation des internautes.{' '}
        </p>

        <h3>4.2 Cookies utilisés sur le Site</h3>
        <p>Sur le Site, nous utilisons trois types de cookies définis ci-après. </p>
        <ul>
            <li>
                Les cookies absolument nécessaires (cookies internes AFP) sont des cookies techniques permettant la
                navigation sur le Site et l’accès à ses fonctionnalités et Contenus.
            </li>
            <li>
                Les cookies fonctionnels (cookies internes AFP) permettent de vous reconnaître lorsque vous revenez sur
                le Site, de mémoriser les informations relatives à vos préférences, et ainsi d’améliorer votre qualité
                de navigation : adapter la configuration du Site à vos préférences.{' '}
            </li>
            <li>
                Les cookies de mesure d’audience (cookies tiers de Google Analytics) : ces cookies sont utilisés afin de
                nous permettre de reconnaître et de compter le nombre de visiteurs sur le Site et de collecter des
                informations sur la manière dont celui-ci est utilisé (parcours de navigation, pages les plus visitées,
                etc.).{' '}
            </li>
        </ul>
        <p>
            Vous pouvez consulter la politique de confidentialité de Google via le lien ci-après : Confidentialité
            Google
        </p>

        <h3>4.3 Refus et Suppression des cookies</h3>
        <ul>
            <li>
                Paramétrage de votre navigateur
                <p>
                    Si vous souhaitez refuser les cookies ou supprimer les cookies enregistrés sur votre terminal, vous
                    pouvez le faire via les préférences de votre navigateur internet. Ces options de navigation
                    relatives aux cookies se trouvent en principe dans les menus « Options », « Outils » ou «
                    Préférences » du navigateur que vous utilisez pour accéder à ce Site. Cependant, selon les
                    différents navigateurs existants, des moyens différents peuvent être utilisés pour désactiver les
                    cookies. Pour en savoir plus vous pouvez consulter des informations complémentaires à partir des
                    liens référencés ci-dessous :
                </p>
                <ul>
                    <li>
                        <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">
                            Microsoft Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a href="https://support.google.com/accounts/answer/61416?hl=fr">Google Chrome</a>
                    </li>
                    <li>
                        <a href="https://support.apple.com/kb/PH21411?locale=fr_FR&viewlocale=fr_FR">Safari</a>
                    </li>
                    <li>
                        <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences?redirectlocale=fr&redirectslug=activer-desactiver-cookies">
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">Opera</a>
                    </li>
                </ul>
                <p>
                    Veuillez noter que si vous refusez, depuis votre navigateur internet, l’enregistrement de cookies
                    sur votre terminal, certaines fonctionnalités et parties du Site pourraient ne pas fonctionner
                    correctement.{' '}
                </p>
            </li>
            <li>
                Désactivation en ligne des cookies Google Analytics
                <p>
                    Vous pouvez désactiver l’utilisation des cookies de Google Analytics via le téléchargement du module
                    complémentaire de navigation accessible sur le lien ci-après :{' '}
                    <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">Désactiver Google Analytics</a>
                </p>
            </li>
        </ul>

        <h2>5. Transfert des données à des tiers </h2>

        <h3>5.1 Cas dans lesquels des transferts peuvent être réalisés</h3>
        <p>Nous conservons vos données au sein de l’AFP, sauf dans des cas limités définis ci-après. </p>
        <ul>
            <li>
                <p>
                    Des entités tierces avec lesquelles nous collaborons peuvent avoir accès à vos données personnelles,
                    en particulier les sous-traitants auxquels nous recourons en matière de prestations techniques,
                    commerciales, marketing, juridiques et de messagerie électronique.
                </p>
                <p>
                    Ces transferts s’inscrivent toujours dans le cadre de la poursuite des finalités décrites dans la
                    partie 2 ci-dessus, et seules les données strictement nécessaires à l’accomplissement de ces
                    finalités sont concernées.
                </p>
            </li>
            <li>
                Nous pouvons transférer vos données s’il s’avère que cela est nécessaire pour (i) faire exécuter tout
                contrat, CGU et la présente Charte de protection des données, (ii) répondre à toute réclamation à
                l’encontre de l’AFP, d’une filiale de l’AFP ou de l’un de nos partenaires commerciaux, (iii) se
                conformer à toute demande judiciaire, notamment dans le cadre d’enquêtes et d’investigations (iv) en cas
                d’urgence mettant en danger la santé publique ou l’intégrité physique d’une personne, (v) afin de
                garantir les droits, les biens et la sécurité de l’AFP, les vôtres et ceux des tiers.
            </li>
            <li>
                Nous pouvons transférer vos données aux filiales de l’AFP et/ou à une entité tierce en cas de
                réorganisation du groupe AFP (fusion, acquisition, scission, etc.).
            </li>
        </ul>

        <h3>5.2 Lieux vers lesquels des transferts peuvent être réalisés</h3>
        <p>
            Nous attachons une importance particulière au stockage et au maintien de vos données au sein de l’Union
            Européenne. Toutefois, il est possible que les données soient transférées dans des pays tiers, dont certains
            peuvent avoir une législation moins protectrice que celle applicable au sein de l’Union Européenne.{' '}
        </p>
        <p>
            C’est le cas des données transmises à certains de nos sous-traitants basés en dehors de l’Union Européenne,
            auxquels nous pouvons faire appel pour certains services spécifiques.
        </p>
        <p>
            En cas de transfert de ce type, nous nous assurons qu’il soit encadré par des instruments juridiques
            permettant le respect par les destinataires des données de toutes les obligations applicables au sein de
            l’Union Européenne en matière de traitement des données (clauses contractuelles types de la Commission
            européenne), afin de garantir un niveau de protection suffisant de la vie privée et des droits fondamentaux
            des personnes.{' '}
        </p>

        <h2>6. La mise en œuvre de vos droits sur vos données personnelles</h2>
        <p>
            Vous avez la possibilité de nous demander une copie des données personnelles vous concernant que nous
            détenons, ainsi que leur rectification ou effacement. Vous pouvez aussi nous demander de limiter un
            traitement ou vous opposer à un traitement. Notez toutefois que dans ces deux derniers cas, vos accès à tout
            ou partie du Site et/ou des services pourront être impactés.{' '}
        </p>
        <p>
            Vous pouvez demander à exercer votre droit à la portabilité de vos données, c’est-à-dire le droit de
            recevoir les données personnelles que vous nous avez fournies dans un format structuré, et le droit de
            transmettre ces données à un autre responsable de traitements.
        </p>
        <p>
            Vous pouvez également formuler des directives spéciales relatives au sort de vos données à caractère
            personnel après votre mort.
        </p>
        <p>
            Dans tous les cas ci-dessus, nous sommes susceptibles, de façon à pouvoir répondre à votre demande, de (i)
            vérifier votre identité et (ii) vous demander de nous fournir des informations complémentaires.
        </p>
        <p>
            Notez que nous sommes susceptibles de conserver certaines informations lorsque la loi nous l’impose ou
            lorsque nous avons un motif légitime de le faire.{' '}
        </p>
        <p>
            Si vous souhaitez exercer vos droits, contactez-nous par e-mail ou voie postale aux adresses indiquées
            ci-dessous. Nous nous efforcerons de répondre à vos demandes dans un délai raisonnable. Vous avez également
            la possibilité d’introduire une réclamation auprès d’une autorité de contrôle.
        </p>
        <p>
            <a href="mailto:afpagendaprivacy@afp.com">afpagendaprivacy@afp.com</a>
        </p>
        <p>ou</p>
        <p>
            Direction juridique
            <br />
            Agence France-Presse
            <br />
            11-15 Place de la Bourse
            <br />
            75002 Paris
        </p>
    </div>
);

export default PrivacyPolicyFR;
