import * as api from 'api/user';

import { getBasicAuthHeader, getTokenAuthHeader } from 'api';
import { getTokenFromCookie, saveCGUToLocalStorage } from 'utils/storage';

import { IANAZone } from 'luxon';
import { changeLocale } from 'reducers/common/commonEffects';
import { fetchAllMetadatas } from 'reducers/metadatas/metadatasEffects';
import { fetchMetadataByCode } from 'api/metadatas';
import { getPreferredFilter } from 'utils/utils';
import i18n from 'i18n';
import { notify } from 'reapop';
import { push } from 'react-router-redux';
import { setFilterSearch } from 'reducers/search/searchEffects';
import {
    authLogout,
    receiveSavedPreferences,
    receiveSession,
    requestSavePreferences,
    requestSession,
} from './usersActions';

// Side Effects
export const saveUserPreferences = preferences => dispatch => {
    dispatch(requestSavePreferences());

    return api.saveUserPreferences(preferences).then(updatedPreferences => {
        dispatch(receiveSavedPreferences(updatedPreferences));
        const preferredLang = updatedPreferences.languages[0][0];
        dispatch(changeLocale(preferredLang));
        dispatch(fetchAllMetadatas(preferredLang));
        dispatch(
            notify({
                title: i18n.t('feedback.preferencesSaved'),
                // message: 'you clicked on the button',
                status: 'success',
            })
        );
    });
};

const loginWithToken = (token, getAuthHeader) => dispatch => {
    dispatch(requestSession());

    return api
        .loginWithToken(token, getAuthHeader)
        .then(session => {
            saveCGUToLocalStorage();

            const preferences = session.preferences || {};
            if (preferences.langages) {
                const preferredLocale = preferences.languages[0][0];
                dispatch(changeLocale(preferredLocale));
            }
            if (!preferences.timezone) {
                const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const isZoneValid = IANAZone.isValidZone(browserTimezone);
                fetchMetadataByCode('timezone', isZoneValid ? browserTimezone : 'GMT').then(timezone => {
                    dispatch(
                        saveUserPreferences({
                            ...preferences,
                            timezone,
                        })
                    );
                });
            }

            if (getAuthHeader === getBasicAuthHeader) {
                // redirect to preferredFilter only on real authentication, not autologin
                const preferredFilter = getPreferredFilter(preferences);
                if (preferredFilter) {
                    dispatch(setFilterSearch(preferredFilter));
                    dispatch(push(`/filters/${preferredFilter}`));
                } else {
                    dispatch(push('/'));
                }
            }

            dispatch(receiveSession(session));
        })
        .catch(error => {
            dispatch(receiveSession(error));
        });
};

export const loginWithCookie = () => loginWithToken(getTokenFromCookie(), getTokenAuthHeader);

export const loginWithCredentials = (login, password) => {
    const token = btoa(unescape(encodeURIComponent(`${login}:${password}`)));

    return loginWithToken(token, getBasicAuthHeader);
};

export const logout = () => dispatch =>
    api.logout().then(() => {
        dispatch(authLogout());
    });
