import React from 'react';

const IcnSearch = () => (
    // <svg width={48} height={48} {...props}>
    //     <g fill="none" fillRule="evenodd">
    //         <path d="M0 0h48v48H0z" />
    //         <path
    //             className="icon"
    //             d="M21.336 28.776L17.112 33c-.288.312-.672.48-1.056.48-.744 0-1.512-.648-1.512-1.512 0-.384.144-.768.456-1.08l4.224-4.224A7.663 7.663 0 0 1 18 22.512C18 18.36 21.336 15 25.488 15A7.508 7.508 0 0 1 33 22.512C33 26.664 29.64 30 25.488 30a7.663 7.663 0 0 1-4.152-1.224zM21 22.512A4.466 4.466 0 0 0 25.488 27 4.486 4.486 0 0 0 30 22.512 4.507 4.507 0 0 0 25.488 18 4.486 4.486 0 0 0 21 22.512z"
    //             fill="#A3ACB1"
    //         />
    //     </g>
    // </svg>
    <span className="icon search-icon" />
);

export default IcnSearch;
