import React, { PureComponent } from 'react';
import { addFilter, deleteFilter, fetchFilter, fetchFilters, updateFilter } from 'reducers/filters/filtersEffects';
import {
    getAgendaPresetsAsOptions,
    getFilterFromMatch,
    getFilterIdFromMatch,
    getIptcsAsOptions,
    getLang,
    getQuery,
    getRegionsAsOptions,
    getRelativeDurationsAsOptions,
    getServicesAsOptions,
    getUserLanguages,
} from 'reducers/selectors';
import { mapSearchToQuery, queryToRefinements } from 'utils/search';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import { redirectTo } from 'reducers/common/commonActions';
import { resetQuery } from 'reducers/search/searchActions';
import startCase from 'lodash/startCase';
import { translate } from 'react-i18next';
import FilterComponent from './FilterComponent';
import withFormState from '../SearchForm/SearchFormHOC';

class FilterContainer extends PureComponent {
    state = {
        mandatoryFieldsMissing: false,
        dirty: false,
        filterName: '',
        preferredFilter: false,
    };

    componentDidMount() {
        const {
            dispatch,
            lang,
            userLanguages,
            inEdition,
            filter,
            filterId,
            query: searchQuery,
            setSearchText,
            setRefinements,
            setShouldSearchForTopNewsOnly,
        } = this.props;

        if (inEdition) {
            dispatch(fetchFilter(filterId));
        }

        const query = inEdition ? filter && filter.query : searchQuery;
        const filterName = inEdition ? filter && filter.label.fr : (searchQuery && startCase(searchQuery.text)) || '';
        const preferredFilter = inEdition ? filter && filter.isPreferredFilter : false;

        this.setState({ filterName, preferredFilter });
        setSearchText((query && query.text) || '');
        queryToRefinements(lang, userLanguages)(query)(newRefinement =>
            setRefinements(prevRefinement => [...prevRefinement, newRefinement])
        );
        setShouldSearchForTopNewsOnly((query && query.topNewsOnly) || false);

        if (filterName) {
            this.updateTitle(inEdition, filterName);
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            dispatch,
            filterId: currentFilterId,
            lang,
            userLanguages,
            setSearchText,
            setRefinements,
            setShouldSearchForTopNewsOnly,
            filter: currentFilter,
        } = this.props;
        const { filterId: nextFilterId, filter: nextFilter } = nextProps;

        if (nextFilterId !== currentFilterId || !isEqualWith(currentFilter, nextFilter, this.customizeEqual)) {
            dispatch(fetchFilter(nextFilterId));

            if (!this.isCancelled) {
                const query = nextFilter && nextFilter.query;
                const filterName = (nextFilter && nextFilter.label.fr) || '';
                const preferredFilter = (nextFilter && nextFilter.isPreferredFilter) || false;

                this.setState({
                    dirty: false,
                    filterName,
                    preferredFilter,
                });

                setRefinements([]);
                setSearchText((query && query.text) || '');
                queryToRefinements(lang, userLanguages)(query)(newRefinement =>
                    setRefinements(prevRefinement => [...prevRefinement, newRefinement])
                );
                setShouldSearchForTopNewsOnly((query && query.topNewsOnly) || false);

                if (filterName) {
                    this.updateTitle(true, filterName);
                }
            }
        }
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    updateTitle = (inEdition, filterName) => {
        const { t } = this.props;
        document.title = `${inEdition ? t('titles.Edit') : t('titles.Add')}: ${filterName}`;
    };

    handleFilterNameChanged = event => {
        this.setState({ filterName: event.target.value });
    };

    // arrow function for binding
    handleDeleteFilter = () => {
        const { dispatch, filter } = this.props;
        dispatch(deleteFilter(filter.id)).then(() => {
            dispatch(redirectTo('/'));
        });
    };

    // arrow function for binding
    handleFavoriteFilter = event => {
        this.setState({ preferredFilter: event.target.checked });
    };

    // arrow function for binding
    handleSaveFilter = () => {
        const { dispatch, inEdition, filter, refinements, shouldSearchForTopNewsOnly, searchText } = this.props;
        const { filterName, preferredFilter } = this.state;

        if (!filterName) {
            this.setState({ mandatoryFieldsMissing: true });
            return;
        }

        if (inEdition) {
            const updatedQuery = {
                ...filter.query,
                ...mapSearchToQuery({
                    searchText,
                    refinements,
                    shouldSearchForTopNewsOnly,
                }),
            };

            const updatedFilter = {
                ...filter,
                label: { fr: filterName },
                query: updatedQuery,
                isPreferredFilter: preferredFilter,
            };

            dispatch(resetQuery());
            dispatch(updateFilter(updatedFilter)).then(() => {
                dispatch(fetchFilters());
                dispatch(redirectTo(`/filters/${filter.id}`));
            });
        } else {
            const newFilter = {
                label: {
                    fr: filterName,
                },
                query: mapSearchToQuery({
                    searchText,
                    refinements,
                    shouldSearchForTopNewsOnly,
                }),
            };
            dispatch(addFilter(newFilter));
        }
    };

    // eslint-disable-next-line class-methods-use-this
    customizeEqual(filter1, filter2) {
        if (filter1 === undefined && filter2 === undefined) {
            return true;
        }

        if (filter1 === undefined || filter2 === undefined) {
            return false;
        }

        return (
            filter1.id === filter2.id && isEqual(filter1.label, filter2.label) && isEqual(filter1.query, filter2.query)
        );
    }

    render() {
        const { dirty, filterName, preferredFilter, mandatoryFieldsMissing } = this.state;
        const {
            query,
            inEdition,
            services,
            iptcs,
            regions,
            agendaPresets,
            relativeDurations,
            lang,
            userLanguages,

            searchText,
            refinements,
            shouldSearchForTopNewsOnly,
            types,

            onSearchChange,
            onShouldSearchForTopNewsOnlyChange,
            onAddRefinement,
            onChangeTypeRefinement,
            onChangeValueRefinement,
            onDeleteRefinement,
            onResetSearch,
        } = this.props;

        return (
            <FilterComponent
                inEdition={inEdition}
                dirty={dirty}
                query={query}
                mandatoryFieldsMissing={mandatoryFieldsMissing}
                filterName={filterName}
                preferredFilter={preferredFilter}
                handleFilterNameChanged={this.handleFilterNameChanged}
                handleSaveFilter={this.handleSaveFilter}
                handleDeleteFilter={this.handleDeleteFilter}
                handleFavoriteFilter={this.handleFavoriteFilter}
                searchText={searchText}
                refinements={refinements}
                types={types}
                services={services}
                iptcs={iptcs}
                regions={regions}
                agendaPresets={agendaPresets}
                relativeDurations={relativeDurations}
                lang={lang}
                userLanguages={userLanguages}
                shouldSearchForTopNewsOnly={shouldSearchForTopNewsOnly}
                handleShouldSearchForTopNewsOnlyChange={onShouldSearchForTopNewsOnlyChange}
                handleSearchChange={onSearchChange}
                handleAddRefinement={onAddRefinement}
                handleChangeTypeRefinement={onChangeTypeRefinement}
                handleChangeValueRefinement={onChangeValueRefinement}
                handleDeleteRefinement={onDeleteRefinement}
                handleResetSearch={onResetSearch}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    filterId: getFilterIdFromMatch(state, ownProps),
    filter: getFilterFromMatch(state, ownProps),
    query: getQuery(state),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
    services: getServicesAsOptions(state),
    iptcs: getIptcsAsOptions(state),
    regions: getRegionsAsOptions(state),
    agendaPresets: getAgendaPresetsAsOptions(state),
    relativeDurations: getRelativeDurationsAsOptions(state),
});

FilterContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    inEdition: PropTypes.bool,
    filter: PropTypes.shape({}),
    filterId: PropTypes.string,
    query: PropTypes.shape({}),
    setSearchText: PropTypes.func.isRequired,
    setRefinements: PropTypes.func.isRequired,
    setShouldSearchForTopNewsOnly: PropTypes.func.isRequired,
    refinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    shouldSearchForTopNewsOnly: PropTypes.bool.isRequired,
    searchText: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    iptcs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    regions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    agendaPresets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    relativeDurations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onAddRefinement: PropTypes.func.isRequired,
    onShouldSearchForTopNewsOnlyChange: PropTypes.func.isRequired,
    onChangeTypeRefinement: PropTypes.func.isRequired,
    onChangeValueRefinement: PropTypes.func.isRequired,
    onDeleteRefinement: PropTypes.func.isRequired,
    onResetSearch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

FilterContainer.defaultProps = {
    inEdition: false,
    filter: undefined,
    filterId: undefined,
    query: null,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(withFormState(FilterContainer));
