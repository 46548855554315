import * as api from 'api/metadatas';
import { METADATA_TYPES } from 'utils/constants';

import { requestMetadatas, receiveMetadatas } from './metadatasActions';

// Side effects
export const fetchMetadatasByType = (type, lang) => dispatch => {
    dispatch(requestMetadatas(type));

    return api
        .fetchMetadatas({ type, search: '', lang })
        .then(metadatas => {
            dispatch(receiveMetadatas(metadatas, type));
            return metadatas;
        })
        .catch(err => {
            dispatch(receiveMetadatas(err));
            return null;
        });
};

export const fetchAllMetadatas = lang => dispatch => {
    dispatch(fetchMetadatasByType(METADATA_TYPES.service, lang));
    dispatch(fetchMetadatasByType(METADATA_TYPES.iptcLvl1, lang));
    dispatch(fetchMetadatasByType(METADATA_TYPES.region, lang));
    dispatch(fetchMetadatasByType(METADATA_TYPES.agendaPreset, lang));
    dispatch(fetchMetadatasByType(METADATA_TYPES.relativeDuration, lang));
};

export const fetchTimezone = (search, lang) => api.fetchMetadatas({ type: METADATA_TYPES.timezone, search, lang });
export const fetchLocations = (search, lang) =>
    api
        .fetchMetadatas({ type: METADATA_TYPES.location, search, lang })
        .then(metadatas => metadatas.filter(metadata => metadata.locationStatus === 'VALID'));

export const fetchMetadatasByCodes = (type, codes, lang) =>
    Promise.all(codes.map(code => api.fetchMetadataByCode(type, code, lang)));
