import { createActions } from 'redux-actions';

// Action Creators
const { user: userActions, auth: authActions } = createActions({
    USER: {
        SESSION: {
            REQUEST: undefined,
            RESPONSE: undefined,
        },
        SAVE_PREFERENCES: {
            REQUEST: undefined,
            RESPONSE: preferences => ({ preferences }),
        },
    },
    AUTH: {
        ATTEMPT: undefined,
        TIMEOUT: undefined,
        LOGOUT: undefined,
    },
});

export const requestSession = userActions.session.request;
export const receiveSession = userActions.session.response;
export const requestSavePreferences = userActions.savePreferences.request;
export const receiveSavedPreferences = userActions.savePreferences.response;
export const attemptAuth = authActions.attempt;
export const authTimeout = authActions.timeout;
export const authLogout = authActions.logout;
