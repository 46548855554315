import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import * as searchEffects from 'reducers/search/searchEffects';
import { getAgenda, getSearchAgendaId, getMetadata, getLang, getUserLanguages } from 'reducers/selectors';
import { stringForLang } from 'utils/locale';

const getAgendaIdFromMatchParams = ({ match }) => match.params.agendaId;

class FilterResultsContainer extends PureComponent {
    componentDidMount() {
        const { matchAgendaId, searchAgendaId, setAgendaSearch, agenda } = this.props;

        if (matchAgendaId !== searchAgendaId) {
            setAgendaSearch(matchAgendaId);
            if (agenda) {
                this.updateTitle(agenda);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { searchAgendaId: currentSearchAgendaId } = this.props;
        const {
            matchAgendaId: nextMatchAgendaId,
            searchAgendaId: nextSearchAgendaId,
            setAgendaSearch,
            agenda: nextAgenda,
        } = nextProps;

        const isMatchAgendaIdDifferentOfCurrentSearchAgendaId =
            nextMatchAgendaId !== currentSearchAgendaId && nextMatchAgendaId !== nextSearchAgendaId;
        const doesAgendaExists = nextAgenda;

        if (isMatchAgendaIdDifferentOfCurrentSearchAgendaId && doesAgendaExists) {
            setAgendaSearch(nextMatchAgendaId);
            this.updateTitle(nextAgenda);
        }
    }

    updateTitle = filter => {
        const { t, lang, userLanguages } = this.props;
        document.title = `${t('titles.AFP_agenda')}:  ${stringForLang({ field: filter.label, lang, userLanguages })}`;
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state, ownProps) => ({
    matchAgendaId: getAgendaIdFromMatchParams(ownProps),
    searchAgendaId: getSearchAgendaId(state),
    agenda:
        getAgenda(state, getAgendaIdFromMatchParams(ownProps)) ||
        getMetadata(state, getAgendaIdFromMatchParams(ownProps)),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
});

FilterResultsContainer.propTypes = {
    t: PropTypes.func.isRequired,
    matchAgendaId: PropTypes.string.isRequired,
    searchAgendaId: PropTypes.string,
    agenda: PropTypes.shape({}),
    setAgendaSearch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};

FilterResultsContainer.defaultProps = {
    searchAgendaId: undefined,
    agenda: undefined,
};

export default compose(
    translate(),
    connect(
        mapStateToProps,
        { ...searchEffects }
    )
)(FilterResultsContainer);
