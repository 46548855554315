import { createSelector } from 'reselect';
import { METADATA_TYPES } from 'utils/constants';

// Selectors
const getIdMap = state => state.byId || [];
export const getMetadata = (state, id) => getIdMap(state)[id];
const getIdsList = state => state.listByType;
const getIdsListByType = type => state => getIdsList(state)[type];

export const getMetadatasByType = type =>
    createSelector(
        getIdsListByType(type),
        getIdMap,
        (ids = [], idMap) => ids.map(id => idMap[id])
    );
export const getServices = state => getMetadatasByType(METADATA_TYPES.service)(state);
export const getIptcs = state => getMetadatasByType(METADATA_TYPES.iptcLvl1)(state);
export const getRegions = state => getMetadatasByType(METADATA_TYPES.region)(state);
export const getAgendaPresets = state => getMetadatasByType(METADATA_TYPES.agendaPreset)(state);
export const getAgenda = (state, id) => getIdMap(state)[id];
export const getRelativeDurations = state => getMetadatasByType(METADATA_TYPES.relativeDuration)(state);
