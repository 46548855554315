import { createActions } from 'redux-actions';

export const { sidebarMenu: sidebarMenuActions, collapse: collapseActions } = createActions({
    SIDEBAR_MENU: {
        OPEN: () => true,
        CLOSE: () => false,
        TOGGLE: () => undefined,
    },
    COLLAPSE: {
        USER_AGENDA: {
            OPEN: () => true,
            CLOSE: () => false,
            TOGGLE: () => undefined,
        },
        AFP_AGENDA: {
            OPEN: () => true,
            CLOSE: () => false,
            TOGGLE: () => undefined,
        },
    },
});
