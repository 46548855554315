import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { mapMetadatasToOptions, getPreferredLanguage } from 'utils/utils';
import { METADATA_TYPES } from 'utils/constants';

import { getUser, getFilters, getUserPreferences, getLang, getUserLanguages } from 'reducers/selectors';
import { fetchTimezone } from 'reducers/metadatas/metadatasEffects';
import { logout, saveUserPreferences, loginWithCookie } from 'reducers/users/usersEffects';

import { stringForLang } from 'utils/locale';
import Profile from './ProfileComponent';

class ProfileContainer extends PureComponent {
    state = {
        selectedLocale: '',
        defaultFilterId: null,
        timezone: null,
        timezoneOptions: [],
    };

    componentDidMount() {
        const { dispatch, preferences, lang } = this.props;
        // force reload of user/session to get fresh informations
        dispatch(loginWithCookie()).then(() => {
            const language = getPreferredLanguage(preferences);
            const { defaultFilterId } = preferences;
            this.onUpdateDefaultFilterIdAndLanguage({ language, defaultFilterId });
            this.onUpdateTimezone(preferences.timezone, lang, preferences.languages);
        });

        this.updateTitle();
    }

    componentDidUpdate(prevProps) {
        const { preferences, lang } = this.props;
        const currentTimezone = preferences.timezone;
        const prevTimezone = prevProps.preferences.timezone;
        if ((currentTimezone && currentTimezone.code) !== (prevTimezone && prevTimezone.code)) {
            this.onUpdateTimezone(currentTimezone, lang, preferences.languages);
        }

        this.updateTitle();
    }

    updateTitle = () => {
        const { t } = this.props;
        document.title = t('titles.My_profile');
    };

    onUpdateDefaultFilterIdAndLanguage = ({ language, defaultFilterId }) => {
        this.setState({
            selectedLocale: language,
            defaultFilterId,
        });
    };

    onUpdateTimezone = (timezone, lang, userLanguages) => {
        this.setState({
            timezone: timezone && this.mapTimezoneToOption(timezone, lang, userLanguages),
            timezoneOptions: (timezone && [this.mapTimezoneToOption(timezone, lang, userLanguages)]) || [],
        });
    };

    onLogoutHandle = () => {
        const { dispatch } = this.props;
        dispatch(logout());
    };

    handleLanguageChange = event => {
        this.setState({ selectedLocale: event.target.value });
    };

    handleTimezoneChange = event => {
        this.setState({ timezone: event });
    };

    mapTimezoneToOption = (timezone, lang, userLanguages) => ({
        value: timezone.id,
        label: stringForLang({ field: timezone.label, lang, userLanguages }),
        data: timezone,
    });

    handleFilterChange = event => {
        this.setState({ defaultFilterId: event.target.value });
    };

    loadTimezoneOptions = inputValue => {
        const { lang, userLanguages } = this.props;
        if (!inputValue) {
            return Promise.resolve({
                options: [],
            });
        }

        return fetchTimezone(inputValue, lang).then(
            mapMetadatasToOptions(lang, userLanguages)(METADATA_TYPES.timezone),
            () => ({
                options: [],
            })
        );
    };

    handleSubmit = event => {
        const { dispatch, preferences } = this.props;
        const { selectedLocale, timezone, defaultFilterId } = this.state;

        event.preventDefault();

        preferences.languages = [
            ...preferences.languages.filter(lang => lang[0] === selectedLocale),
            ...preferences.languages.filter(lang => lang[0] !== selectedLocale),
        ];
        preferences.timezone = timezone && timezone.data;
        preferences.defaultFilterId = defaultFilterId;
        preferences.defaultFilter = undefined;

        dispatch(saveUserPreferences(preferences));
    };

    render() {
        const { user, preferences, filters } = this.props;
        const { selectedLocale, defaultFilterId, timezone, timezoneOptions } = this.state;

        return (
            <Profile
                user={user}
                preferences={preferences}
                filters={filters}
                selectedLocale={selectedLocale}
                defaultFilterId={defaultFilterId}
                timezone={timezone}
                timezoneOptions={timezoneOptions}
                onLogoutClick={this.onLogoutHandle}
                handleLanguageChange={this.handleLanguageChange}
                handleTimezoneChange={this.handleTimezoneChange}
                handleFilterChange={this.handleFilterChange}
                handleSubmit={this.handleSubmit}
                loadTimezoneOptions={this.loadTimezoneOptions}
            />
        );
    }
}

const mapStateToProps = state => ({
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
    user: getUser(state),
    preferences: getUserPreferences(state),
    filters: getFilters(state),
});

ProfileContainer.propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    preferences: PropTypes.shape({ timezone: PropTypes.shape({}), languages: PropTypes.arrayOf(PropTypes.array) })
        .isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    user: PropTypes.shape({}).isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(ProfileContainer);
