import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import * as searchActions from 'reducers/search/searchActions';

import { DEFAULT_QUERY } from 'utils/constants';
import { getQuery } from 'reducers/selectors';

class SearchResultsContainer extends PureComponent {
    componentDidMount() {
        const { query, setQueryFromSearch, t } = this.props;

        if (!query) {
            setQueryFromSearch(DEFAULT_QUERY);
        }

        document.title = t('titles.All_events');
    }

    componentWillReceiveProps(nextProps) {
        const { setQueryFromSearch } = this.props;
        const { query: nextQuery } = nextProps;
        if (!nextQuery) {
            setQueryFromSearch(DEFAULT_QUERY);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({ query: getQuery(state) });

SearchResultsContainer.propTypes = {
    t: PropTypes.func.isRequired,
    query: PropTypes.shape({}),
    setQueryFromSearch: PropTypes.func.isRequired,
};

SearchResultsContainer.defaultProps = {
    query: null,
};

export default compose(
    translate(),
    connect(
        mapStateToProps,
        { ...searchActions }
    )
)(SearchResultsContainer);
