import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { getIsMenuVisible, getLang, getRedirectTo } from 'reducers/selectors';
import { sidebarMenuActions } from 'reducers/ui/uiActions';
import { fetchAllMetadatas } from 'reducers/metadatas/metadatasEffects';

import Navbar from './Navbar/NavbarComponent';
import Sidebar from './Sidebar/SidebarContainer';
import OverlayComponent from './OverlayComponent';

import Events from './SearchResults/EventsContainer';
import FilterResults from './SearchResults/FilterResultsContainer';
import AgendaPresetResults from './SearchResults/AgendaPresetResultsContainer';
import SearchResults from './SearchResults/SearchResultsContainer';

class Main extends PureComponent {
    componentDidMount() {
        const { dispatch, lang } = this.props;
        dispatch(fetchAllMetadatas(lang));
    }

    // Arrow fn for binding
    // eslint-disable-next-line no-undef
    handleMenuVisibility = () => {
        const { dispatch } = this.props;
        dispatch(sidebarMenuActions.toggle());
    };

    render() {
        const { isMenuVisible } = this.props;

        return (
            <Fragment>
                <div className="organizer">
                    <header>
                        <Navbar isMenuOpen={isMenuVisible} onClick={this.handleMenuVisibility} />
                    </header>

                    <Sidebar isMenuOpen={isMenuVisible} onClick={this.handleMenuVisibility} />

                    <main>
                        <Events />
                    </main>
                </div>

                <Switch>
                    <Route exact path="/home" component={SearchResults} />
                    <Route exact path="/filters/add" />
                    <Route exact path="/filters/:filterId" component={FilterResults} />
                    <Route exact path="/agendas/:agendaId" component={AgendaPresetResults} />
                    <Route exact path="/search/events" component={SearchResults} />
                    <Route exact path="(/|/index.html)" render={() => <Redirect to="/home" />} />
                </Switch>

                <Switch>
                    <Route exact path="/" />
                    <Route exact path="/home" />
                    <Route exact path="/search/events" />
                    <Route exact path="/filters/add" component={OverlayComponent} />
                    <Route exact path="/filters/:filterId" />
                    <Route exact path="/agendas/:agendaId" />
                    <Route component={OverlayComponent} />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    lang: getLang(state),
    isMenuVisible: getIsMenuVisible(state),
    redirectTo: getRedirectTo(state),
});

Main.propTypes = {
    dispatch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    isMenuVisible: PropTypes.bool.isRequired,
};

Main.defaultProps = {};

export default connect(mapStateToProps)(Main);
