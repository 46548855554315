import * as selectedEventSelectors from './eventSelectors';

// Selectors
export const getIdMap = state => state.byId;
export const getEvent = (state, id) => getIdMap(state)[id];
export const getEvents = (state, ids) => ids.map(id => getIdMap(state)[id]);

export const getSelectedEventId = state => selectedEventSelectors.getSelectedEventId(state.selectedEvent);
export const isSelectedEventLoading = state => selectedEventSelectors.isSelectedEventLoading(state.selectedEvent);

export const getSelectedEventAfoDocs = state => selectedEventSelectors.getSelectedEventAfoDocs(state.selectedEvent);
export const isAfoDocsLoading = state => selectedEventSelectors.isAfoDocsLoading(state.selectedEvent);
