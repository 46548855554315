import { notify } from 'reapop';

import i18n from 'i18n';
import { redirectTo } from 'reducers/common/commonActions';

import * as api from 'api/filters';
import {
    requestFilters,
    receiveFitlers,
    requestFilter,
    receiveFitler,
    requestAddFilter,
    receiveAddedFitler,
    requestUpdateFilter,
    receiveUpdatedFitler,
    requestDeleteFilter,
    receiveDeleteFitler,
} from 'reducers/filters/filtersActions';

// Side Effects
export const fetchFilters = () => dispatch => {
    dispatch(requestFilters());

    return api.fetchFilters().then(filters => {
        dispatch(receiveFitlers(filters));
        return filters;
    });
};

export const fetchFilter = filterId => dispatch => {
    dispatch(requestFilter(filterId));

    return api.fetchFilter(filterId).then(filter => {
        dispatch(receiveFitler(filter));
        return filter;
    });
};

export const addFilter = filter => dispatch => {
    dispatch(requestAddFilter());

    return api.addFilter(filter).then(addedFilter => {
        dispatch(receiveAddedFitler(addedFilter));
        dispatch(redirectTo(`/filters/${addedFilter.id}`));
        dispatch(
            notify({
                title: i18n.t('feedback.filterAdded'),
                status: 'success',
            })
        );
        return addedFilter;
    });
};

export const updateFilter = filter => dispatch => {
    dispatch(requestUpdateFilter());

    return api.updateFilter(filter).then(updatedFilter => {
        dispatch(receiveUpdatedFitler(updatedFilter));
        dispatch(redirectTo(`/filters/${updatedFilter.id}`));
        dispatch(
            notify({
                title: i18n.t('feedback.filterUpdated'),
                // message: 'you clicked on the button',
                status: 'success',
            })
        );
        return updatedFilter;
    });
};

export const deleteFilter = filterId => dispatch => {
    dispatch(requestDeleteFilter());

    return api.deleteFilter(filterId).then(() => {
        dispatch(receiveDeleteFitler(filterId));
        dispatch(
            notify({
                title: i18n.t('feedback.filterDeleted'),
                // message: 'you clicked on the button',
                status: 'success',
            })
        );
    });
};

export const favoriteFilter = filter => dispatch => {
    const preferredFilter = { ...filter, isPreferredFilter: true };

    dispatch(requestUpdateFilter());

    return api.updateFilter(preferredFilter).then(updatedFilter => {
        dispatch(receiveUpdatedFitler(updatedFilter));
        dispatch(fetchFilters());
        dispatch(
            notify({
                title: i18n.t('feedback.preferredFilterChanged'),
                status: 'success',
            })
        );
        return updatedFilter;
    });
};
