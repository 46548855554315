import React from 'react';

const PrivacyPolicyEn = () => (
    <div className="legal-content">
        <h1>PRIVACY POLICY</h1>

        <p>
            The purpose of this Privacy Policy is to inform you about how AFP, as data controller of the personal data
            of the users of this Platform, collects, processes and shares your personal data.
        </p>

        <h2>1. The data we collect</h2>
        <p>We may collect the data listed below in connection with your use of the Platform.</p>
        <p>
            Note that we never solicit the communication of sensitive data (or “special categories of data” as defined
            by law) and we ask that you do not transmit such data to us.
        </p>

        <h3>1.1 Information you provide us</h3>
        <p>
            The AFP Agenda service offered on this Platform is an optional service that involves your prior subscription
            to other AFP services. Your contact and connection data to the Platform are the same as those you have given
            us when you subscribed to the other services proposed by AFP and you will not be invited to send us other
            data for this specific service.
        </p>
        <p>
            Some links available on the Platform allow you to access forms from which you may communicate other data.
            These forms being located on other AFP platforms (afp.com and afpforum in particular), we invite you to read
            the privacy policy applicable to these other platforms.
        </p>

        <h3>1.2 Information we collect when you use the Platform</h3>
        <p>During each of your visits to our Platform, we may collect the following data:</p>
        <ul>
            <li>
                Data about the devices, networks and platforms you use: identifier of the device you are using (IP
                address, Apple ID, Android ID, etc.), connection data (date, time, duration), type and version of the
                browser software (Internet Explorer, Safari, Mozilla Firefox, Google Chrome, etc.), type of operating
                system (Microsoft Windows, Apple OS, Linux, Unix, etc.).
            </li>
            <li>
                Data about your preferences and other actions on the Platform: interface language, reference of the
                user&apos;s start page, date of acceptance of the Terms of Use and License and IP address of the device
                from which the Terms of Use and License were accepted.
            </li>

            <li>
                Data relating to your browsing: route on the Platorm’s pages, Content that you consult, searches and
                results of searches and generally your actions on the Site.
            </li>
        </ul>

        <p>
            To collect some of the data listed above, we may use cookies. For more information on the use of cookies,
            see section 4 below.
        </p>

        <h2>2. Purposes of data processing</h2>
        <p>The data we collect is used for the purposes and based on the legal grounds described below.</p>

        <table>
            <thead>
                <tr>
                    <th>Purposes of use</th>
                    <th>Legal basis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Execute contracts between you and us and provide you with the requested information and services
                    </td>
                    <td>This processing is necessary to fulfil our respective contractual obligations</td>
                </tr>
                <tr>
                    <td>
                        Manage your user account or your employer&apos;s user account, including by providing you or
                        sending you reminders about your username / password, sending you some notifications about the
                        services, maintenance operations, modifications to our Terms of Use and License and to this
                        Privacy Policy
                    </td>
                    <td>
                        This processing is necessary to fulfil our respective contractual obligations and / or carried
                        out with your consent
                    </td>
                </tr>
                <tr>
                    <td>
                        Verify the execution of the contract concluded between you and us, ensure a follow-up of your
                        use with regard to the subscribed conditions, verify the execution of our Terms of Use and
                        License and, where applicable, verify your compliance of applicable law
                    </td>
                    <td>
                        This processing is necessary to fulfil our respective contractual obligations and / or carried
                        out with your consent and / or necessary for the establishment, exercise or defence of legal
                        claims
                    </td>
                </tr>
                <tr>
                    <td>Provide you with the ability to customize your preferences on the Site</td>
                    <td>
                        This processing is carried out for the purpose of your legitimate interest and / or with your
                        consent
                    </td>
                </tr>
                <tr>
                    <td>Handle and correct any incidents or errors</td>
                    <td>
                        This processing is carried out for the purpose of our legitimate interest (ensuring the
                        performance and security of the Platform)
                    </td>
                </tr>
                <tr>
                    <td>For internal audits on data analysis and audience statistics</td>
                    <td>
                        This processing is carried out for the purpose of our legitimate interest (better understanding
                        the interests of users and improving our services and support)
                    </td>
                </tr>
            </tbody>
        </table>

        <h2>3. Data retention period</h2>
        <p>
            Your data are kept for a duration not exceeding that necessary for the purposes for which they are
            processed. The tables below mention the retention periods of your data.
        </p>
        <table>
            <thead>
                <tr>
                    <th>Data categories</th>
                    <th>Retention period</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Connection and navigation data</td>
                    <td>15 months</td>
                </tr>
                <tr>
                    <td>Cookies</td>
                    <td>13 months</td>
                </tr>
                <tr>
                    <td>Basic information request</td>
                    <td>3 years after the closure of the file</td>
                </tr>
                <tr>
                    <td>Complaint, request for a right of access</td>
                    <td>10 years after the closure of the file (beyond this period, these data are archived)</td>
                </tr>
                <tr>
                    <td>
                        Data relating to the enforcement of the contract: management of the client account, billing,
                        payments
                    </td>
                    <td>10 years after the end of the contract</td>
                </tr>
                <tr>
                    <td>Clients contact details: name, surname, civility, email, phone number, activity, company</td>
                    <td>5 years after the end of the contract</td>
                </tr>
                <tr>
                    <td>
                        Prospects contact details: surname, first name, email, civility, telephone number, activity,
                        company
                    </td>
                    <td>3 years after data collection or last contact from you</td>
                </tr>
            </tbody>
        </table>

        <h2 id="cookies">4. Use of cookies</h2>
        <p>We use cookies to improve the quality of our services and your experience as a user.</p>

        <h3>4.1 What is a cookie?</h3>
        <p>
            A cookie is a small text file stored by a website on the user&apos;s terminal. The majority of websites use
            cookies to improve their functioning and optimize the browsing experience of their users.
        </p>

        <h3>4.2 Cookies used on the Platform</h3>
        <p>We use the cookies defined below on this Platform.</p>
        <ul>
            <li>
                Absolutely necessary cookies (AFP internal cookies) are technical cookies allowing navigation on the
                Platform and access to its functionalities and Content.
            </li>
            <li>
                Functional cookies (AFP internal cookies) make it possible to recognize you when you return to the
                Platform, to memorize the information relating to your preferences, and thus to improve your browsing
                quality: adapt the configuration of the Platform to your preferences.
            </li>
            <li>
                Audience measurement cookies (Google Analytics third-party cookies): these cookies are used to enable us
                to recognize and count the number of visitors on the Platform and to collect information on how it is
                used. (navigation route, most visited pages, etc.).
            </li>
        </ul>
        <p>
            To find out more about Google&apos;s privacy policy:
            <a href="https://support.google.com/analytics/answer/6004245?hl=en">Google Privacy Policy</a>
        </p>

        <h3>4.3 Refusing and Deleting Cookies</h3>
        <ul>
            <li>
                Configuration of your browser settings
                <p>
                    If you wish to refuse cookies or delete cookies stored on your device, you can do so via the
                    preferences of your internet browser. These navigation options relating to cookies are normally
                    found in the &quot;Options&quot;, &quot;Tools&quot; or &quot;Preferences&quot; menus of the browser
                    that you use to access this Platform. However, according to the different existing browsers,
                    different means can be used to disable cookies. To know more you can consult additional information
                    from the referenced links below:
                </p>
                <ul>
                    <li>
                        <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">
                            Microsoft Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a href="https://support.google.com/accounts/answer/61416?hl=fr">Google Chrome</a>
                    </li>
                    <li>
                        <a href="https://support.apple.com/kb/PH21411?locale=fr_FR&viewlocale=fr_FR">Safari</a>
                    </li>
                    <li>
                        <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences?redirectlocale=fr&redirectslug=activer-desactiver-cookies">
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">Opera</a>
                    </li>
                </ul>
                <p>
                    Please note that if you refuse to accept cookies from your internet browser on your device, some
                    features and parts of the Platform may not work properly.
                </p>
            </li>
            <li>
                Online deactivation of Google Analytics cookies
                <p>
                    ou may prevent your data from being used by Google Analytics by downloading and installing the
                    add-on accessible on the following link:
                    <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">Disabling Google Analytics</a>
                </p>
            </li>
        </ul>

        <h2>5. Data transfers to third parties</h2>

        <h3>5.1 Cases in which transfers can be made</h3>
        <p>We keep your data within AFP, except in limited cases defined below.</p>
        <ul>
            <li>
                <p>
                    Third parties with whom we collaborate may have access to your personal data, in particular the
                    subcontractors we use in relation to some technical, commercial, marketing, legal and e-mail
                    services.
                </p>
                <p>
                    These transfers are always made in the context of the purposes described in Part 2 above, and only
                    data strictly necessary for the achievement of these purposes are concerned.
                </p>
            </li>
            <li>
                We may transfer your data if it appears necessary to (i) enforce any contract, terms of use and this
                Privacy Policy, (ii) respond to any claim against AFP, an AFP subsidiary or one of our business
                partners, (iii) comply with any judicial request, including in the context of inquiries and
                investigations (iv) in case of emergency involving danger to public health or the physical integrity of
                a person, (v) to guarantee the rights, property and safety of AFP, yourself and third parties.
            </li>
            <li>
                We may transfer your data to AFP subsidiaries and / or a third party in case of reorganization of the
                AFP group (merger, acquisition, split, etc.)
            </li>
        </ul>

        <h3>5.2 Places to which transfers can be made</h3>
        <p>
            We strive to host and keep your data within the European Union. However, data may be transferred to
            countries outside of the European Union, some of which may have a less protective legislation relating to
            personal data than that applicable within the European Union.
        </p>
        <p>
            This is the case of data transmitted to some of our subcontractors located outside of the European Union,
            which we can use for certain specific services.
        </p>
        <p>
            When such a transfer takes place, we make sure that it is implemented through legal instruments allowing the
            recipients to comply with all obligations applicable within the European Union as regards data processing
            (standard contractual clauses of the European Commission), in order to ensure individuals a sufficient level
            of privacy.
        </p>

        <h2>6. The implementation of your rights on your personal data</h2>
        <p>
            You have the possibility to ask us for a copy of your personal data that we hold, as well as their
            rectification or erasure. You can also ask us to restrict a processing or to oppose a processing. Note
            however that in these last two cases, your access to all or part of the Platform and / or to the services
            may be impacted.
        </p>
        <p>
            You can request to exercise your right to data portability, that is to say the right to receive the personal
            data that you have provided us in a structured format, in order to transmit such data to another controller.
        </p>
        <p>You can also make special instructions regarding the fate of your personal data after your death.</p>
        <p>
            In each of the above cases, we are likely, in order to respond to your request, to (i) verify your identity
            and (ii) ask you to provide us with additional information.
        </p>
        <p>
            Note that we may retain some information when required by law or when we have a legitimate reason to do so.
        </p>
        <p>
            If you wish to exercise your rights, you may contact us by e-mail or by post at the addresses indicated
            below. We will endeavor to respond to your requests within a reasonable time. You also have the possibility
            to lodge a complaint with a supervisory authority.
        </p>
        <p>
            <a href="mailto:afpagendaprivacy@afp.com">afpagendaprivacy@afp.com</a>
        </p>
        <p>OR</p>
        <p>
            Legal Department
            <br />
            Agence France-Presse
            <br />
            11-15 Place de la Bourse
            <br />
            75002 Paris
        </p>
    </div>
);

export default PrivacyPolicyEn;
