import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-i18next'

import {
    getFilters,
    getAgendaPresets,
    getIsAgendaSectionOpen,
    getIsAfpAgendaSectionOpen,
    getLang,
    getAFOAdvisoriesLink,
    getSearchFilterId,
    getUserLanguages,
    getSearchAgendaId,
    getLocale,
} from 'reducers/selectors'
import * as filtersActions from 'reducers/filters/filtersEffects'
import * as searchActions from 'reducers/search/searchActions'
import * as commonActions from 'reducers/common/commonActions'
import * as afoActions from 'reducers/afo/afoEffects'
import { sidebarMenuActions, collapseActions } from 'reducers/ui/uiActions'

import FilterList from 'app/FilterList/FiltersComponent'
import FilterItem from 'app/FilterList/FilterItem'
import CollapseSection from 'components/CollapseSection'

import ReactGA from 'react-ga'
import { FEEDBACK_URL_FR, FEEDBACK_URL_EN } from 'utils/constants'

import help from '../../images/help.svg'
import beta from '../../images/beta.svg'

class SidebarContainer extends PureComponent {
    state = {
        listFilters: true,
        listAfpFilters: false,
    }

    componentDidMount() {
        const { fetchFilters, fetchAFOAdvisoriesLink, lang } = this.props
        fetchFilters()
        fetchAFOAdvisoriesLink(lang)
    }

    goToHomepage = () => {
        const { resetQuery, redirectTo } = this.props
        resetQuery()

        redirectTo('/home')
    }

    toggleList = (listId) => {
        this.setState((prevState) => ({
            [listId]: !prevState[listId],
        }))
    }

    // arrow function for binding
    handleFavoriteFilter = (filter) => {
        const { favoriteFilter, updateFilter, redirectTo } = this.props

        if (filter.isPreferredFilter) {
            updateFilter({ ...filter, isPreferredFilter: false })

            return
        }

        favoriteFilter(filter)
        redirectTo(`/filters/${filter.id}`)
    }

    showAdvisoriesOnAfpForum = (afoLink) => {
        ReactGA.event({
            category: 'afpforum',
            action: 'to define',
            label: 'View advisories on AFPForum',
        })

        const refWindow = window.open(afoLink, '_blank')
        refWindow.opener = null
    }

    render() {
        const {
            isMenuOpen,
            isAgendaSectionOpen,
            toggleUserAgendaCollapse,
            t,
            isLoading,
            filters,
            searchFilterId,
            searchAgendaId,
            afoLink,
            lang,
            userLanguages,
            toggleSidebarMenu,
            afpAgendas,
            isAfpAgendaSectionOpen,
            toggleAfpAgendaCollapse,
            locale,
        } = this.props

        return (
            <aside className={isMenuOpen ? 'open' : ''}>
                <div className="sidebar-header">
                    <div className="logo">
                        <Link className="navbar-brand" to="/">
                            <svg width="72px" height="40px" viewBox="0 0 165 92" version="1.1">
                                <g
                                    id="logo/blue/logo-2020"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd">
                                    <path
                                        d="M88.6108135,45.1110663 L83.6430738,45.1110663 L83.6430738,38.4805376 L88.6108135,38.4805376 C90.9888867,38.4805376 92.1942202,39.5691557 92.1942202,41.8227432 C92.1942202,44.0763307 90.9888867,45.1110663 88.6108135,45.1110663 L88.6108135,45.1110663 Z M89.1856418,33.0749305 L77.6114903,33.0585315 L77.6114903,57.9056037 L83.6430738,57.9056037 L83.6430738,50.5330725 L89.1856418,50.5330725 C91.8773646,50.5330725 98.138388,49.650507 98.138388,41.8037885 C98.138388,33.95707 91.8773646,33.0749305 89.1856418,33.0749305 L89.1856418,33.0749305 Z M62.2332552,45.1110663 L73.370862,45.1110663 L69.8530706,50.5330725 L62.2332552,50.5330725 L62.2332552,57.9056037 L56.2016716,57.9056037 L56.2016716,33.0585315 L73.370862,33.0585315 L73.370862,38.4805376 L62.2332552,38.4805376 L62.2332552,45.1110663 Z M51.1834916,33.0585315 L51.1834916,57.9056037 L45.1523355,57.9056037 L45.1523355,52.7208509 L34.3480415,52.7208509 L30.9844566,57.9056037 L24.4638575,57.9056037 L40.5816004,33.0585315 L40.5903633,33.0585315 L51.1834916,33.0585315 Z M45.1523355,38.464245 L43.5958426,38.464245 L37.7411211,47.4898827 L45.1523355,47.4898827 L45.1523355,38.464245 Z M121.869107,26.8470296 C111.54047,26.8470296 103.167682,35.1901912 103.167682,45.4822805 C103.167682,55.7742634 111.54047,64.1175315 121.869107,64.1175315 C132.197637,64.1175315 140.570532,55.7742634 140.570532,45.4822805 C140.570532,35.1901912 132.197637,26.8470296 121.869107,26.8470296 L121.869107,26.8470296 Z"
                                        id="logo"
                                        fill="#325AFF"
                                    />
                                </g>
                            </svg>
                        </Link>
                    </div>
                    {lang !== 'fr' && lang !== 'en' && <img className="beta" src={beta} alt="" />}
                    <span
                        className="icon navbar-toggler"
                        onClick={toggleSidebarMenu}
                        onKeyPress={toggleSidebarMenu}
                        role="button"
                        tabIndex="0"
                    />
                </div>
                <div className="sidebar-wrapper">
                    <div className="section">
                        <div onClick={this.goToHomepage} onKeyPress={this.goToHomepage} role="button" tabIndex="0">
                            <h4>
                                <span className="icon homepage-icon" /> {t('home')}
                            </h4>
                        </div>
                    </div>
                    <CollapseSection
                        title={t('myFilters')}
                        iconClassname="bookmark-icon"
                        isOpened={isAgendaSectionOpen}
                        onToggleCollapse={toggleUserAgendaCollapse}>
                        {filters && filters.length ? (
                            <FilterList filters={filters}>
                                {(filter) => (
                                    <FilterItem
                                        routePath="filters"
                                        filter={filter}
                                        activeFilter={searchFilterId}
                                        lang={lang}
                                        userLanguages={userLanguages}
                                        key={filter.id}
                                        handleFavoriteFilter={this.handleFavoriteFilter}
                                        edit
                                    />
                                )}
                            </FilterList>
                        ) : (
                            !isLoading && <div className="info">{t('noAgenda')}</div>
                        )}
                    </CollapseSection>

                    {afpAgendas.length ? (
                        <CollapseSection
                            title={t('afpFilters')}
                            iconClassname="afpfilter-icon"
                            isOpened={isAfpAgendaSectionOpen}
                            onToggleCollapse={toggleAfpAgendaCollapse}>
                            {filters && afpAgendas.length ? (
                                <FilterList filters={afpAgendas}>
                                    {(agenda) => (
                                        <FilterItem
                                            routePath="agendas"
                                            filter={agenda}
                                            activeFilter={searchAgendaId}
                                            lang={lang}
                                            userLanguages={userLanguages}
                                            key={agenda.id}
                                            handleFavoriteFilter={this.handleFavoriteFilter}
                                        />
                                    )}
                                </FilterList>
                            ) : (
                                !isLoading && <div className="info">{t('noAfpAgenda')}</div>
                            )}
                        </CollapseSection>
                    ) : null}

                    <div className="section">
                        <button type="button" className="btn" onClick={() => this.showAdvisoriesOnAfpForum(afoLink)}>
                            <span className="icon afpforum-advisories-icon" /> {t('afoAdvisories')}
                        </button>
                    </div>

                    <div className="section">
                        <Link to="/profile">
                            <h4>
                                <span className="icon profile-icon" />
                                {t('myProfile')}
                            </h4>
                        </Link>
                    </div>

                    <div className="section">
                        <a
                            href={locale.indexOf('fr') !== -1 ? FEEDBACK_URL_FR : FEEDBACK_URL_EN}
                            target="_blank"
                            rel="noopener noreferrer">
                            <h4>
                                <span className="icon feedback-icon" /> {t('userFeedback')}
                            </h4>
                        </a>
                    </div>

                    <div className="section">
                        <Link to="/help" target="_blank" rel="noopener noreferrer">
                            <h4>
                                {/* <i className="icon help-icon" /> */}
                                <img src={help} className="icon-svg" alt="" />
                                {t('helpCenter')}
                            </h4>
                        </Link>
                    </div>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: getFilters(state),
    searchFilterId: getSearchFilterId(state),
    searchAgendaId: getSearchAgendaId(state),
    afpAgendas: getAgendaPresets(state),
    isAgendaSectionOpen: getIsAgendaSectionOpen(state),
    isAfpAgendaSectionOpen: getIsAfpAgendaSectionOpen(state),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
    afoLink: getAFOAdvisoriesLink(state),
    isLoading: state.filters.isLoading,
    locale: getLocale(state),
})

SidebarContainer.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    isAgendaSectionOpen: PropTypes.bool.isRequired,
    toggleUserAgendaCollapse: PropTypes.func.isRequired,
    isAfpAgendaSectionOpen: PropTypes.bool.isRequired,
    toggleAfpAgendaCollapse: PropTypes.func.isRequired,
    toggleSidebarMenu: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    searchFilterId: PropTypes.string,
    searchAgendaId: PropTypes.string,
    afpAgendas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    afoLink: PropTypes.string.isRequired,
    fetchAFOAdvisoriesLink: PropTypes.func.isRequired,
    fetchFilters: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    favoriteFilter: PropTypes.func.isRequired,
    resetQuery: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
}

SidebarContainer.defaultProps = {
    searchFilterId: null,
    searchAgendaId: null,
}

export default compose(
    translate(),
    connect(
        mapStateToProps,
        {
            ...searchActions,
            ...commonActions,
            ...filtersActions,
            ...afoActions,
            toggleSidebarMenu: sidebarMenuActions.toggle,
            toggleUserAgendaCollapse: collapseActions.userAgenda.toggle,
            toggleAfpAgendaCollapse: collapseActions.afpAgenda.toggle,
        },
    ),
)(SidebarContainer)
