import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import {
  requestEvent,
  receiveEvent,
  resetSelectedEvent,
} from 'reducers/events/eventActions';

import afo from './afoDocsReducers';

const eventId = handleActions(
  {
    [requestEvent]: (_, action) => action.payload,
    [receiveEvent]: { next: (_, action) => action.payload.result },
    [resetSelectedEvent]: () => null,
  },
  null
);

const isLoading = handleActions(
  {
    [requestEvent]: () => true,
    [combineActions(receiveEvent, resetSelectedEvent)]: () => false,
  },
  false
);

export default combineReducers({
  eventId,
  isLoading,
  afo,
});
