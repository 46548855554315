import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { queryToRefinements, mapSearchToQuery } from 'utils/search';

import {
    getLang,
    getUserPreferences,
    getQuery,
    getServicesAsOptions,
    getIptcsAsOptions,
    getRegionsAsOptions,
    getAgendaPresetsAsOptions,
    getRelativeDurationsAsOptions,
    getUserLanguages,
    getUserTimezone,
} from 'reducers/selectors';
import * as commonActions from 'reducers/common/commonActions';
import * as searchActions from 'reducers/search/searchActions';
import * as searchEffects from 'reducers/search/searchEffects';

import withSearchFormState from './SearchFormHOC';
import Search from './SearchComponent';

class SearchContainer extends PureComponent {
    componentDidMount() {
        const {
            query,
            lang,
            userLanguages,
            setSearchText,
            setRefinements,
            setShouldSearchForTopNewsOnly,
            t,
        } = this.props;

        setSearchText((query && query.text) || '');
        queryToRefinements(lang, userLanguages)(query)(newRefinement =>
            setRefinements(prevRefinement => [...prevRefinement, newRefinement])
        );
        setShouldSearchForTopNewsOnly((query && query.topNewsOnly) || false);

        document.title = t('titles.Search');
    }

    doSearch = event => searchAction => nextPath => {
        event.preventDefault();

        const { redirectTo, searchText, lang, timezone, refinements, shouldSearchForTopNewsOnly } = this.props;

        const query = mapSearchToQuery({
            searchText,
            refinements,
            shouldSearchForTopNewsOnly,
        });

        redirectTo(nextPath);
        searchAction(query, lang, timezone, { forceSearch: true });
    };

    // arrow function for binding
    handleSearch = event => {
        const { resetAndSearch } = this.props;

        this.doSearch(event)(resetAndSearch)('/search/events');
    };

    // arrow function for binding
    handleSaveFilter = event => {
        const { setQueryFromSearch } = this.props;

        this.doSearch(event)(setQueryFromSearch)('/filters/add');
    };

    render() {
        const {
            services,
            iptcs,
            regions,
            agendaPresets,
            relativeDurations,
            lang,
            userLanguages,

            searchText,
            refinements,
            shouldSearchForTopNewsOnly,
            types,

            onSearchChange,
            onShouldSearchForTopNewsOnlyChange,
            onAddRefinement,
            onChangeTypeRefinement,
            onChangeValueRefinement,
            onDeleteRefinement,
            onResetSearch,
        } = this.props;

        return (
            <Search
                searchText={searchText}
                refinements={refinements}
                services={services}
                iptcs={iptcs}
                types={agendaPresets.length === 0 ? types.filter(type => type.type !== 'agendapreset') : types}
                regions={regions}
                agendaPresets={agendaPresets}
                relativeDurations={relativeDurations}
                lang={lang}
                userLanguages={userLanguages}
                shouldSearchForTopNewsOnly={shouldSearchForTopNewsOnly}
                handleShouldSearchForTopNewsOnlyChange={onShouldSearchForTopNewsOnlyChange}
                handleSearchChange={onSearchChange}
                handleAddRefinement={onAddRefinement}
                handleChangeTypeRefinement={onChangeTypeRefinement}
                handleChangeValueRefinement={onChangeValueRefinement}
                handleDeleteRefinement={onDeleteRefinement}
                handleResetSearch={onResetSearch}
                handleSaveFilter={this.handleSaveFilter}
                handleSubmit={this.handleSearch}
            />
        );
    }
}

const mapStateToProps = state => ({
    query: getQuery(state),
    userPreferences: getUserPreferences(state),
    lang: getLang(state),
    timezone: getUserTimezone(state),
    userLanguages: getUserLanguages(state),
    services: getServicesAsOptions(state),
    iptcs: getIptcsAsOptions(state),
    regions: getRegionsAsOptions(state),
    agendaPresets: getAgendaPresetsAsOptions(state),
    relativeDurations: getRelativeDurationsAsOptions(state),
});

SearchContainer.propTypes = {
    refinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    query: PropTypes.shape({}),
    lang: PropTypes.string.isRequired,
    timezone: PropTypes.shape({}).isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    setSearchText: PropTypes.func.isRequired,
    setRefinements: PropTypes.func.isRequired,
    setShouldSearchForTopNewsOnly: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    shouldSearchForTopNewsOnly: PropTypes.bool.isRequired,
    resetAndSearch: PropTypes.func.isRequired,
    setQueryFromSearch: PropTypes.func.isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    iptcs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    regions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    agendaPresets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    relativeDurations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onShouldSearchForTopNewsOnlyChange: PropTypes.func.isRequired,
    onAddRefinement: PropTypes.func.isRequired,
    onChangeTypeRefinement: PropTypes.func.isRequired,
    onChangeValueRefinement: PropTypes.func.isRequired,
    onDeleteRefinement: PropTypes.func.isRequired,
    onResetSearch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

SearchContainer.defaultProps = {
    query: null,
};

export default compose(
    translate(),
    connect(
        mapStateToProps,
        { ...commonActions, ...searchActions, ...searchEffects }
    )
)(withSearchFormState(SearchContainer));
