import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { unique } from 'utils/utils';

import { receiveMetadatas } from './metadatasActions';

// Reducers
const byId = handleActions(
    {
        [receiveMetadatas]: {
            next: (state, action) => ({
                ...state,
                ...action.payload.entities.metadatas,
            }),
            throw: state => ({ ...state }),
        },
    },
    {}
);

const listByType = handleActions(
    {
        [receiveMetadatas]: (state, action) => ({
                ...state,
                [action.meta.type]: unique([...action.payload.result]),
            }),
    },
    {}
);

export default combineReducers({
    byId,
    listByType,
});
