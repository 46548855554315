import React from 'react'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import LoginContainer from './Login/LoginContainer'
import logo from '../images/logo-2020-white.svg'

const AuthLayout = () => (
    <div className="public-layout">
        <div className="navbar public-navbar">
            <Link to="/" className="navbar-brand mx-auto">
                <img src={logo} alt="" />
            </Link>
        </div>
        <main>
            <Switch>
                <Route path="/login" component={LoginContainer} />
                <Redirect to="/login" />
            </Switch>
        </main>
    </div>
)

AuthLayout.propTypes = {}

export default AuthLayout
