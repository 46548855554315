import { createActions } from 'redux-actions';
import { normalize } from 'normalizr';

import * as schema from 'reducers/schema';

// Action Creators
export const actions = createActions({
  FILTERS: {
    FETCH: {
      REQUEST: undefined,
      RESPONSE: filters => normalize(filters, schema.filterListSchema),
    },
  },
  FILTER: {
    FETCH: {
      REQUEST: undefined,
      RESPONSE: filter => normalize(filter, schema.filterSchema),
    },
    ADD: {
      REQUEST: undefined,
      RESPONSE: addedFilter => normalize(addedFilter, schema.filterSchema),
    },
    UPDATE: {
      REQUEST: undefined,
      RESPONSE: updatedFilter => normalize(updatedFilter, schema.filterSchema),
    },
    DELETE: {
      REQUEST: undefined,
      RESPONSE: undefined,
    },
  },
});

export const requestFilters = actions.filters.fetch.request;
export const receiveFitlers = actions.filters.fetch.response;

export const requestFilter = actions.filter.fetch.request;
export const receiveFitler = actions.filter.fetch.response;
export const requestAddFilter = actions.filter.add.request;
export const receiveAddedFitler = actions.filter.add.response;
export const requestUpdateFilter = actions.filter.update.request;
export const receiveUpdatedFitler = actions.filter.update.response;
export const requestDeleteFilter = actions.filter.delete.request;
export const receiveDeleteFitler = actions.filter.delete.response;
