import React from 'react';
import PropTypes from 'prop-types';

import { Route, /* Link, */ Switch } from 'react-router-dom';
import { translate } from 'react-i18next';

import SearchIcon from 'components/icons/SearchIcon';
import ShareIcon from 'components/icons/ShareIcon';
import FavoriteIcon from 'components/icons/FavoriteIcon';
import ToggleableLink from 'components/ToggleableNavLink';

const Navbar = ({ onClick: toggleMenu, isMenuOpen, t }) => (
    <div className={`navbar navbar-expand-lg ${isMenuOpen ? 'open' : ''}`}>
        <span className="icon navbar-toggler" onClick={toggleMenu} onKeyPress={toggleMenu} role="button" tabIndex="0" />

        <ul className="actions ml-auto">
            <Route
                exact
                path="/(|search/events|filters/add)"
                render={() => (
                    <li className="nav-item">
                        <ToggleableLink to="/filters/add" activeClassName="active">
                            <FavoriteIcon className="icon" />
                            <span className="label">{t('navbar.favorite')}</span>
                        </ToggleableLink>
                    </li>
                )}
            />

            <Switch>
                <Route path="/filters/add" />
                <Route
                    path="/filters/:filterId"
                    render={({ match }) => (
                        <React.Fragment>
                            <li className="nav-item">
                                <ToggleableLink to="/filters/add" className="menu-item" activeClassName="active">
                                    <FavoriteIcon className="icon" />
                                    <span className="label">{t('navbar.favorite')}</span>
                                </ToggleableLink>
                            </li>
                            <li className="nav-item">
                                <ToggleableLink
                                    to={`/filters/${match.params.filterId}/share`}
                                    exact
                                    className="menu-item"
                                    activeClassName="active">
                                    <ShareIcon className="icon" />
                                    <span className="label">{t('navbar.share')}</span>
                                </ToggleableLink>
                            </li>
                        </React.Fragment>
                    )}
                />
                <Route
                    path="/events/:eventId"
                    render={({ match }) => (
                        <li className="nav-item">
                            <ToggleableLink
                                to={`/events/${match.params.eventId}/share`}
                                exact
                                className="menu-item"
                                activeClassName="active">
                                <ShareIcon className="icon" />
                                <span className="label">{t('navbar.share')}</span>
                            </ToggleableLink>
                        </li>
                    )}
                />
            </Switch>

            <li className="nav-item">
                <ToggleableLink to="/search" exact className="menu-item" activeClassName="active">
                    <SearchIcon className="icon" />
                    <span className="label">{t('navbar.search')}</span>
                </ToggleableLink>
            </li>
        </ul>
    </div>
);

Navbar.propTypes = {
    onClick: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(Navbar);
