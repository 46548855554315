import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { unique } from 'utils/utils';

import { requestEvents, receiveEvents } from 'reducers/events/eventsActions';
import { resetQuery, setQueryFromFilter, setQueryFromSearch, setQueryFromAgenda } from 'reducers/search/searchActions';
import searchTypes from './searchTypes';

// Reducer
const results = handleActions(
    {
        [receiveEvents]: (state, action) => ({
            ...state,
            idList: unique([...state.idList, ...action.payload.result]),
        }),
        [combineActions(setQueryFromSearch, setQueryFromFilter, setQueryFromAgenda, resetQuery)]: () => ({
            idList: [],
        }),
    },
    { idList: [] }
);

const query = handleActions(
    {
        [combineActions(setQueryFromSearch, setQueryFromFilter, setQueryFromAgenda)]: (_, action) => action.payload,
        [resetQuery]: () => null,
    },
    null
);

const nextLink = handleActions(
    {
        [receiveEvents]: (_, action) => action.meta.nextLink,
    },
    ''
);

const isLoading = handleActions(
    {
        [requestEvents]: () => true,
        [receiveEvents]: () => false,
    },
    false
);

const type = handleActions(
    {
        [combineActions(setQueryFromSearch, setQueryFromFilter, setQueryFromAgenda)]: (_, action) => action.meta.type,
    },
    searchTypes.SEARCH
);

const filterId = handleActions(
    {
        [setQueryFromFilter]: (_, action) => action.meta.filterId,
        [combineActions(setQueryFromSearch, resetQuery)]: () => null,
    },
    null
);

const agendaId = handleActions(
    {
        [setQueryFromAgenda]: (_, action) => action.meta.agendaId,
        [combineActions(setQueryFromSearch, resetQuery)]: () => null,
    },
    null
);

export default combineReducers({
    isLoading,
    query,
    results,
    nextLink,
    type,
    filterId,
    agendaId,
});
