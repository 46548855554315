import { createActions } from 'redux-actions';

import { normalize } from 'normalizr';

import * as schema from 'reducers/schema';

const { events: eventsActions } = createActions({
    EVENTS: {
        FETCH: {
            REQUEST: undefined,
            RESPONSE: [events => normalize(events, schema.eventListSchema), (events, next) => ({ nextLink: next })],
        },
    },
});

export const requestEvents = eventsActions.fetch.request;
export const receiveEvents = eventsActions.fetch.response;
