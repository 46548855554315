import includes from 'lodash/includes';

export const allLanguages = [
    { lang: 'en', locale: 'en-GB', label: 'English' },
    { lang: 'fr', locale: 'fr-FR', label: 'Français' },
    { lang: 'es', locale: 'es-ES', label: 'Español' },
    { lang: 'de', locale: 'de-DE', label: 'Deutsch' },
];

export const parseToLocale = locale => {
    if (includes(allLanguages.map(language => language.locale), locale)) {
        return locale;
    }

    if (includes(allLanguages.map(language => language.lang), locale)) {
        return allLanguages.find(language => language.lang === locale).locale;
    }

    return 'en-GB';
};

export const parseToLang = lang => {
    if (includes(allLanguages.map(language => language.locale), lang)) {
        return allLanguages.find(language => language.locale === lang).lang;
    }

    if (includes(allLanguages.map(language => language.lang), lang)) {
        return lang;
    }

    return 'en';
};

export const stringForLang = ({ field, lang: userLang, userLanguages = [], fallbackMessage = '', fallback = true }) => {
    if (field[userLang] !== undefined) {
        return field[userLang];
    }

    if (!fallback) {
        return '';
    }

    const translations = userLanguages
        .filter(preferredLang => userLang !== preferredLang[0] && field[preferredLang[0]] !== undefined)
        .map(lang => field[lang[0]]);

    if (translations.length === 0) {
        return fallbackMessage;
    }

    return translations[0];
};

// pour locations et iptc le champ volatileFields.label.LANG contient plus d'infos que le champ label.LANG
export const stringLabelForLang = ({ field, lang: userLang, userLanguages = [], fallbackMessage = '' }) => {
    if (field[`label.${userLang}`] !== undefined) {
        return field[`label.${userLang}`];
    }

    const translations = userLanguages
        .filter(preferredLang => userLang !== preferredLang[0] && field[`label.${preferredLang[0]}`] !== undefined)
        .map(lang => field[`label.${lang[0]}`]);

    if (translations.length === 0) {
        return fallbackMessage;
    }

    return translations[0];
};
