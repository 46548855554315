import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { Redirect } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { getCGUFromLocalStorage, getLanguageFromLocalStorage, getTokenFromCookie } from 'utils/storage';
import { getPreferredLanguage } from 'utils/utils';

import {
    getAreCredentialsInvalid,
    getIsSessionLoading,
    getIsAuthenticated,
    getHasTimeout,
    getUser,
    getUserPreferences,
    getHasAttemptedAuth,
    getLocale,
} from 'reducers/selectors';

import { changeLocale } from 'reducers/common/commonEffects';
import { loginWithCredentials, loginWithCookie } from 'reducers/users/usersEffects';
import { attemptAuth } from 'reducers/users/usersActions';

import LoginComponent from './LoginComponent';

class Login extends PureComponent {
    state = {
        login: '',
        password: '',
        hasAlreadyAcceptedCGU: false,
        hasAcceptedConditions: false,
        formValidated: false,
        isInputInvalid: {
            login: true,
            password: true,
        },
    };

    componentDidMount() {
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            if (getTokenFromCookie()) {
                this.logUserAndChangeLocaleIfNeeded(loginWithCookie, getTokenFromCookie());
            }
            const storageCGU = getCGUFromLocalStorage();
            this.setState({
                hasAlreadyAcceptedCGU: (storageCGU && storageCGU === 'true') || false,
            });
        }

        document.title = t('titles.Sign_in');
    }

    // arrow function for binding
    handleInputChange = event => {
        const { target } = event;
        const { name } = target;
        let value = '';

        if (target.type === 'checkbox') {
            value = target.checked;
        } else {
            ({ value } = target);

            this.setState(prevState => ({
                isInputInvalid: {
                    ...prevState.isInputInvalid,
                    [name]: isEmpty(value),
                },
            }));
        }

        this.setState({
            [name]: value,
        });
    };

    logUserAndChangeLocaleIfNeeded = (loginFn, ...params) => {
        const { dispatch } = this.props;
        dispatch(loginFn(...params)).then(() => {
            dispatch((_, getState) => {
                const preferences = getUserPreferences(getState());
                const preferredLanguage = getPreferredLanguage(preferences);
                const savedLanguage = getLanguageFromLocalStorage();

                if (preferredLanguage && preferredLanguage !== savedLanguage) {
                    dispatch(changeLocale(preferredLanguage));
                }
            });
        });
    };

    // arrow function for binding
    handleSubmit = event => {
        event.preventDefault();

        const { dispatch } = this.props;
        const { login, password, hasAcceptedConditions, hasAlreadyAcceptedCGU } = this.state;

        dispatch(attemptAuth());

        if ((hasAlreadyAcceptedCGU || hasAcceptedConditions) && !isEmpty(login) && !isEmpty(password)) {
            this.logUserAndChangeLocaleIfNeeded(loginWithCredentials, login, password);
        } else {
            this.setState({
                formValidated: true,
            });
        }
    };

    render() {
        const {
            login,
            password,
            formValidated,
            hasAcceptedConditions,
            isInputInvalid,
            hasAlreadyAcceptedCGU,
        } = this.state;
        const { isAuthenticated, areCredentialsInvalid, hasAttemptedAuth, hasTimeout, location, locale } = this.props;

        const prevPath = location.state ? location.state.from.pathname : '/';

        if (isAuthenticated) {
            return <Redirect to={prevPath} />;
        }

        return (
            <LoginComponent
                login={login}
                password={password}
                locale={locale}
                isInputInvalid={isInputInvalid}
                hasAlreadyAcceptedCGU={hasAlreadyAcceptedCGU}
                hasAcceptedConditions={hasAcceptedConditions}
                hasTimeout={hasTimeout}
                areCredentialsInvalid={areCredentialsInvalid}
                hasAttemptedAuth={hasAttemptedAuth}
                formValidated={formValidated}
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
            />
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: getIsAuthenticated(state),
    hasTimeout: getHasTimeout(state),
    isLoading: getIsSessionLoading(state),
    areCredentialsInvalid: getAreCredentialsInvalid(state),
    hasAttemptedAuth: getHasAttemptedAuth(state),
    authUser: getUser(state),
    locale: getLocale(state),
});

Login.propTypes = {
    t: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    areCredentialsInvalid: PropTypes.bool.isRequired,
    hasAttemptedAuth: PropTypes.bool.isRequired,
    hasTimeout: PropTypes.bool.isRequired,
    location: PropTypes.shape({}).isRequired,
    locale: PropTypes.string.isRequired,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(Login);
