import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { stringForLang } from 'utils/locale';

const getLabel = (filter, lang, userLanguages) => stringForLang({ field: filter.label, lang, userLanguages });

const FilterItem = ({
    routePath,
    filter,
    lang,
    userLanguages,
    activeFilter,
    handleFavoriteFilter,
    edit = false,
    ...props
}) => (
    <li {...props}>
        <Link className={activeFilter === filter.id ? 'active' : ''} to={`/${routePath}/${filter.id}`}>
            {getLabel(filter, lang, userLanguages)}
        </Link>

        {edit && (
            <div className="actions">
                <Link to={`/${routePath}/${filter.id}/edit`} className="action edit">
                    <span className="icon edit-icon" />
                </Link>
                <button
                    type="button"
                    className="btn-nav-link action favorite"
                    onClick={() => handleFavoriteFilter(filter)}>
                    <span className={`icon star-icon ${filter.isPreferredFilter ? 'selected' : ''}`} />
                </button>
            </div>
        )}
    </li>
);

FilterItem.propTypes = {
    filter: PropTypes.shape({}).isRequired,
    activeFilter: PropTypes.string,
    lang: PropTypes.string.isRequired,
    routePath: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    handleFavoriteFilter: PropTypes.func.isRequired,
    edit: PropTypes.bool,
};

FilterItem.defaultProps = {
    activeFilter: null,
    edit: false,
};

export default FilterItem;
