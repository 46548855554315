import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import { allLanguages } from 'utils/locale';

const LanguageSelect = ({ t, selectedLocale, handleLanguageChange, shortLabel = false, onlySelect = false }) => {
    const select = (
        <select
            id="languageSelect"
            name="languageSelect"
            className="custom-select"
            value={selectedLocale}
            onChange={handleLanguageChange}>
            {allLanguages.map(language => (
                <option key={language.lang} value={language.lang}>
                    {shortLabel ? language.lang.toUpperCase() : language.label}
                </option>
            ))}
        </select>
    );

    return (
        <>
            {onlySelect ? (
                select
            ) : (
                <div className="form-group">
                    <label htmlFor="languageSelect">{t('preferredLanguageLabel')}</label>
                    {select}
                </div>
            )}
        </>
    );
};

LanguageSelect.propTypes = {
    selectedLocale: PropTypes.string.isRequired,
    handleLanguageChange: PropTypes.func.isRequired,
    shortLabel: PropTypes.bool,
    onlySelect: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

LanguageSelect.defaultProps = {
    shortLabel: false,
    onlySelect: false,
};
export default translate()(LanguageSelect);
