import api, { mapResponseData } from 'api';
import { parseToLang } from 'utils/locale';

export const fetchMetadatas = ({ type, search, lang }) => {
    const langParam = parseToLang(lang);
    let url = `/metadatas?lang=${langParam}&type=${type}`;

    if (search) {
        url += `&search=${search}`;
    }

    return api.get(url).then(mapResponseData);
};

export const fetchMetadataByCode = (type, code, lang = 'fr') => {
    const langParam = parseToLang(lang, 'fr');
    const url = `/metadatas/search/types/${type}?lang=${langParam}&search-code=${code}`;

    return api.get(url).then(mapResponseData);
};
