import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import BackButton from 'components/BackButton';
import SearchFormComponent from '../SearchForm/SearchFormComponent';

const FilterComponent = props => {
    const {
        // dirty,
        inEdition,
        filterName,
        preferredFilter,
        mandatoryFieldsMissing,
        handleFilterNameChanged,
        handleDeleteFilter,
        handleFavoriteFilter,
        handleSaveFilter,
    } = props;
    const { t, ...searchFormProps } = props;

    const saveButton = (
        <button type="submit" className="btn-primary" onClick={handleSaveFilter}>
            {inEdition ? t('actions.update') : t('actions.save')}
        </button>
    );

    const deleteButton = (
        <button type="button" className="btn-danger" onClick={handleDeleteFilter}>
            {t('actions.delete')}
        </button>
    );

    const cancelButton = <BackButton className="btn-outline">{t('actions.cancel')}</BackButton>;

    return (
        <div className="content-wrapper filter-form filter-add">
            <h2>{inEdition ? t('filter.edit') : t('filter.add')}</h2>

            {mandatoryFieldsMissing && <p className="text-danger">{t('error.filterNameIsMandatory')}</p>}

            <div>
                <label htmlFor="filter-name">
                    {t('filter.name')}
                    <input
                        type="text"
                        className="form-control standard"
                        name="filterName"
                        id="filter-name"
                        value={filterName || ''}
                        onChange={handleFilterNameChanged}
                    />
                </label>
            </div>

            {inEdition && (
                <div className="default-filter-checkbox">
                    <label className="inline" htmlFor="preferred-filter">
                        <i className={preferredFilter ? 'icon star-icon active' : 'icon star-icon'} />
                        <input
                            type="checkbox"
                            id="preferred-filter"
                            checked={preferredFilter || false}
                            onChange={handleFavoriteFilter}
                        />
                        <span>{t('filter.defaultFilter')}</span>
                    </label>
                </div>
            )}

            {inEdition && (
                <div>
                    <label htmlFor="search-form">
                        {t('filter.search')}
                        <SearchFormComponent id="search-form" {...searchFormProps} />
                    </label>
                </div>
            )}

            {!inEdition && (
                <div className="actions">
                    {saveButton}
                    {cancelButton}
                </div>
            )}

            {inEdition && (
                <div className="actions">
                    {saveButton}
                    {deleteButton}
                    {cancelButton}
                </div>
            )}
        </div>
    );
};

FilterComponent.propTypes = {
    inEdition: PropTypes.bool.isRequired,
    preferredFilter: PropTypes.bool,
    mandatoryFieldsMissing: PropTypes.bool,
    filterName: PropTypes.string,
    lang: PropTypes.string.isRequired,
    handleFilterNameChanged: PropTypes.func.isRequired,
    handleSaveFilter: PropTypes.func.isRequired,
    handleDeleteFilter: PropTypes.func.isRequired,
    handleFavoriteFilter: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

FilterComponent.defaultProps = {
    preferredFilter: false,
    mandatoryFieldsMissing: false,
    filterName: '',
};

export default translate()(FilterComponent);
