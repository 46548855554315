import React from 'react';

const CGU = () => (
    <div className="legal-content">
        <h1>CONDITIONS GÉNÉRALES D&apos;UTILISATION</h1>

        <p>Dernière mise à jour : 21 05 2019</p>

        <h2>1. Champ d’application</h2>
        <p>
            Les présentes conditions générales d’utilisation (les « CGU ») régissent votre utilisation du site
            accessible à l’adresse https://agenda.afp.com (le « Site »), édité par l’Agence France-Presse (« l’AFP »).
        </p>
        <p>
            En accédant au Site, vous vous engagez à respecter les présentes CGU et à prendre connaissance de notre
            Charte sur la protection des données personnelles.
        </p>

        <p>
            Les présentes CGU et la Charte sur la protection des données personnelles peuvent être modifiées à tout
            moment. Vous êtes invités à les consulter régulièrement, toute modification entrant en vigueur
            immédiatement, dès publication sur le Site.
        </p>

        <p>
            Pour les besoins des présentes CGU et de notre Charte sur la protection des données personnelles, les
            termes :
        </p>
        <ul>
            <li>« utilisateur », « vous », « votre », « vos » désignent tout utilisateur du Site ;</li>
            <li>
                « AFP », « nous », « notre », « nos » désignent l’Agence France-Presse et, le cas échéant, ses
                partenaires.
            </li>
        </ul>

        <h2>2. Propriété du Site et du Contenu</h2>

        <p>
            Le Site, tous ses éléments constitutifs et le contenu (quelle qu’en soit la forme) présent ou accessible sur
            et/ou à travers le Site (le « Contenu ») sont la propriété exclusive de l’AFP et/ou de ses partenaires et
            sont protégés par la réglementation applicable en matière de propriété intellectuelle.
        </p>
        <p>
            Toute utilisation du Site et/ou du Contenu à d’autres fins et/ou d’une quelconque autre manière que celles
            expressément autorisées aux présentes CGU est strictement interdite.
        </p>

        <h2>3. Utilisation du Site et du Contenu</h2>
        <h3>3.1 Accès au Site</h3>
        <p>
            Le Site est réservé aux utilisateurs autorisés disposant d’un compte et de codes d’accès attribués par
            l’AFP.
        </p>
        <p>
            Les code d’accès à votre compte client vous sont communiqués par l’AFP par courrier électronique et sont
            exclusivement réservés à l’utilisateur visé dans le courrier. L’adresse de courrier électronique fournie
            doit être strictement individuelle et non collective. Une distribution en mode alias et toute liste de
            diffusion associée à l’adresse de courrier électronique fournie à l’AFP sont strictement interdites. Les
            codes d’accès à votre compte client sont strictement confidentiels. L’AFP doit être immédiatement informée
            de toute atteinte à la sécurité ou confidentialité d’un compte ou de toute utilisation non autorisée d’un
            compte.
        </p>
        <p>
            Si votre compte client vous permet de créer plusieurs comptes individuels au sein de votre organisation, il
            vous appartient de ne créer des comptes qu’aux membres de votre personnel permanent ou temporaire et de
            désactiver immédiatement tout compte associé à un utilisateur ne faisant plus partie de votre organisation.
            Vous êtes entièrement responsable de toute utilisation qui est faite de votre compte client, ainsi que de
            tout compte individuel créé par vous à partir de ce dernier.
        </p>
        <p>
            Le Site ne permet pas d’accéder aux contenus produits par l’AFP (et/ou ses partenaires) lors de la
            couverture des événements qui y sont annoncés. Pour y accéder, vous devez disposer d’un abonnement avec
            l’AFP vous permettant d’accéder à ces contenus (via la plateforme AFP Forum ou tout autre mode de
            livraison).
        </p>

        <h3>3.2 Utilisation du Contenu</h3>

        <p>
            Le Contenu du Site est fourni pour simple consultation. Vous n’êtes autorisé à sauvegarder et partager des
            extraits du Site et du Contenu que pour un usage strictement personnel, tel que rendu possible par les
            fonctionnalités mises à disposition sur le Site, et à des fins non commerciales uniquement.
        </p>
        <p>
            Toute autre utilisation, notamment par reproduction, représentation ou distribution de tout ou partie du
            Site et du Contenu à quelque fin et sous quelque forme que ce soit sans l’autorisation écrite de l’AFP est
            interdite.
        </p>

        <h2>4. Garantie</h2>
        <p>
            Vous garantissez l’AFP que vous utiliserez le Site, ses fonctionnalités et le Contenu dans le strict respect
            des présentes CGU et des lois et règlements en vigueur.
        </p>
        <p>
            Vous vous engagez à défendre et à indemniser l’AFP et ses partenaires de tous frais (y compris les frais
            d’avocat et de procédure), coûts, pertes, débours, dommages, condamnations, résultant de toute réclamation,
            demande, revendication, recours, plainte, action ou condamnation ayant pour cause, fondement ou origine la
            violation par vous de la présente garantie.
        </p>

        <h2>5. Limitation de responsabilité </h2>
        <p>
            Le calendrier des événements à venir et les modes de couverture prévus par l’AFP (et/ou ses partenaires)
            sont basés sur de simples prévisions susceptibles d’évoluer à tout moment. Ces informations sont fournies à
            titre indicatif et ne constituent en aucun cas un engagement de la part de l’AFP (et/ou de ses partenaires)
            quant à la couverture effective de ces événements, aux modes de couverture prévus et/ou à la diffusion
            effective des contenus issus d’une telle couverture.
        </p>
        <p>
            La tenue d’un événement, la couverture d’un événement, les modes de couverture d’un événement et la
            diffusion des contenus issus d’une telle couverture par l’AFP (et/ou ses partenaires) peuvent être
            suspendus, annulés, avancés, reportés, restreints ou modifiés d’une quelconque autre manière, à tout moment
            et sans préavis, pour des causes étrangères à l’AFP (et/ou ses partenaires) et/ou sur décision de l’AFP
            (et/ou ses partenaires) sans que sa responsabilité (et/ou celle de ses partenaires) ne puisse être engagée à
            ce titre.
        </p>
        <p>
            L’accès à ou la disponibilité de tout ou partie du Site et de certaines de ses fonctionnalités, peuvent être
            interrompus ou ralentis pour des causes étrangères à l’AFP.
        </p>
        <p>
            L’AFP se réserve le droit de modifier, suspendre, interrompre ou restreindre à tout moment l’accès à ou la
            disponibilité de tout ou partie du Site et de ses fonctionnalités.
        </p>
        <p>
            Le Site et ses fonctionnalités ne sont pas garantis contre toute faille de sécurité liée à un piratage,
            intrusion, bug, virus, logiciel malveillant, etc., pouvant affecter votre matériel informatique, vos
            logiciels ou vos données.
        </p>
        <p>
            Vous utilisez le Site, ses fonctionnalités et son Contenu à vos propres risques et sous votre entière
            responsabilité.
        </p>
        <p>
            L’AFP n’est responsable ni des sites internet tiers référencés sur le Site ni des sites internet tiers
            référençant le Site (notamment au moyen d’un lien hypertexte). La référence à des sites internet tiers
            n’entraîne aucunement l’affiliation de l’AFP ou de ses partenaires à ces sites ou à leurs contenus. Ces
            sites tiers et leurs contenus sont régis par des dispositions qui leur sont propres.
        </p>
        <p>
            L’AFP ne saurait en aucun cas être tenue responsable de dommages, de quelque nature qu’ils soient, résultant
            d’un quelconque accès, utilisation ou impossibilité d’accès ou d’utilisation du Site, de ses fonctionnalités
            ou de son Contenu.
        </p>

        <h2>6. Signes distinctifs</h2>
        <p>
            Les noms, marques et logos figurant sur le Site sont la propriété exclusive de l’AFP et de ses partenaires.
            Ils ne peuvent être utilisés en tout ou partie (y compris en tant que lien vers le Site) qu’en vertu d’un
            accord écrit et dans le strict respect de la charte graphique de l’AFP et/ou de ses partenaires.
        </p>

        <h2>7. Droit applicable et juridictions compétentes</h2>
        <p>Les présentes CGU ainsi que tout différend lié à celles-ci sont soumis au droit français.</p>
        <p>
            Les tribunaux du ressort de la Cour d’Appel de Paris seront seuls compétents, même en cas d’appel en
            garantie ou de pluralité de défendeurs, pour connaître de tout différend lié aux présentes CGU.
        </p>

        <h2>8. Divers</h2>
        <p>
            Si une ou plusieurs stipulations des présentes CGU sont tenues pour non valides ou déclarées comme telles en
            application d’une loi, d’un règlement ou à la suite d’une décision définitive émanant d’une juridiction
            compétente, les autres stipulations garderont toute leur force et leur portée.
        </p>
    </div>
);

export default CGU;
