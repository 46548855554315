import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';

import rootReducer from 'reducers/root';

export const history = createBrowserHistory();

const configureStore = () => {
  const persistedState = {};
  const enhancers = [];
  const middleware = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line no-underscore-dangle

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
    middleware.push(
      createLogger(
        process.env.REACT_APP_LOGGER_COLLAPSE
          ? JSON.parse(process.env.REACT_APP_LOGGER_COLLAPSE)
          : {
            collapsed: true,
            duration: true,
            diff: true,
          }
      )
    );
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  return createStore(rootReducer, persistedState, composedEnhancers);
};

export default configureStore;
