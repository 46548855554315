import { combineReducers } from 'redux';

import { routerReducer } from 'react-router-redux';
import { reducer as notificationsReducer } from 'reapop';

import uiReducer from 'reducers/ui/uiReducers';
import commonReducer from 'reducers/common/commonReducers';
import icsReducer from 'reducers/ics/icsReducers';
import userReducer from 'reducers/users/usersReducers';
import metadatasReducer from 'reducers/metadatas/metadatasReducers';

import filtersReducer from 'reducers/filters/filtersReducers';
import eventsReducer from 'reducers/events/eventsReducers';
import searchReducer from 'reducers/search/searchReducers';

const ROUTING = 'routing';
const NOTIFICATIONS = 'notifications';
const EVENTS = 'events';
const FILTERS = 'filters';
const UI = 'ui';
const USER = 'user';
const COMMON = 'common';
const SEARCH = 'search';
const ICS = 'ics';
const METADATAS = 'metadatas';

const appReducer = combineReducers({
  [ROUTING]: routerReducer,
  [NOTIFICATIONS]: notificationsReducer({
    status: 'info',
    position: 'tr',
    dismissible: true,
    dismissAfter: 2000,
    allowHTML: true,
    closeButton: false,
  }),

  [EVENTS]: eventsReducer,
  [FILTERS]: filtersReducer,
  [UI]: uiReducer,
  [USER]: userReducer,
  [COMMON]: commonReducer,
  [SEARCH]: searchReducer,
  [ICS]: icsReducer,
  [METADATAS]: metadatasReducer,
});

export default appReducer;
