import React from 'react';

export default function NotSupported(browsersList) {
  return (
    <div id="browser-not-supported">
      <p className="list">
        Your browser is not supported, please use a different one.
      <br />
        The latest versions of Chrome, Firefox, Safari and Microsoft Edge are supported.
      </p>
    </div>
  );
}