export const FEEDBACK_URL_FR = 'https://fr.surveymonkey.com/r/MYNW9ZK';
export const FEEDBACK_URL_EN = 'https://fr.surveymonkey.com/r/98HHG35';

export const CONTACT_URL_FR = 'https://view.afp.com/afp-agenda-fr/p/1';
export const CONTACT_URL_EN = 'https://view.afp.com/afp-agenda-en/p/1';
export const CONTACT_URL_DE = 'https://view.afp.com/afp-agenda-de/p/1';
export const CONTACT_URL_ES = 'https://view.afp.com/afp-agenda-es/p/1';

export const EVENTS_PER_PAGE = 25;
export const MAX_INFINITE_SCROLL = 100;

export const DEFAULT_QUERY = {
    type: 'simple',
    text: '',
    services: [],
    iptcs: [],
    regions: [],
    locations: [],
    agendas: [],
    topNewsOnly: false,
    fixedDateFrom: null,
    fixedDateTo: null,
    relativePeriod: null,
};

export const METADATA_TYPES = {
    service: 'service',
    iptcLvl1: 'iptcmediatopiclevel1',
    iptc: 'iptcmediatopic',
    location: 'location',
    timezone: 'timezone',
    region: 'region',
    agendaPreset: 'agendapreset',
    relativeDuration: 'relativePeriod',
};

export const SEARCH_FIELD_TYPES = {
    ...METADATA_TYPES,
    fromdate: 'fromdate',
    todate: 'todate',
};

export const FIELD_TO_QUERY_MAP = {
    [METADATA_TYPES.service]: 'services',
    [METADATA_TYPES.iptcLvl1]: 'iptcs',
    [METADATA_TYPES.region]: 'regions',
    [METADATA_TYPES.agendaPreset]: 'agendas',
};

export const SEARCH_FIELDS = [
    {
        type: SEARCH_FIELD_TYPES.service,
        label: 'byCoverage',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.iptcLvl1,
        label: 'byIptc',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.fromdate,
        label: 'fromDate',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.todate,
        label: 'toDate',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.relativeDuration,
        label: 'byRelativeDuration',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.location,
        label: 'byLocation',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.region,
        label: 'byRegion',
        disabled: false,
    },
    {
        type: SEARCH_FIELD_TYPES.agendaPreset,
        label: 'byAgenda',
        disabled: false,
    },
];

export const ROUTES = {
    home: '/',
    login: '/login',
    search: '/search',
    searchResults: '/events/search',
    filterResults: '/filters/:filterId',
    agendaResults: '/agendas/:agendaId',
    eventDetail: '/events/:eventId',
    shareEvent: '/events/:eventId/share',
    editFilter: '/filters/:filterId/edit',
    addFilter: '/filters/add',
    shareFilter: '/filters/:filterId/share',
    profile: '/profile',
};
