import React from 'react';

const CGU = () => (
    <div className="legal-content">
        <h1>TERMS OF USE</h1>

        <p>Last update: 05-21-2019</p>

        <h2>1. When do these terms of use apply?</h2>
        <p>
            These terms of use (the “Terms”) apply to your use of the AFP Agenda platform, which is available at the
            following address https://agenda.afp.com (the “Platform”), and operated by Agence France-Presse (“AFP”).
        </p>
        <p>
            Please read these Terms and our Privacy Policy before using the Platform. By using the Platform, you agree
            to be bound by these Terms.
        </p>

        <p>
            We reserve the right to modify or to update these Terms and our Privacy Policy at any time and at our sole
            discretion. Please consult these Terms and our Privacy Policy on a regular basis as any modification thereto
            becomes effective upon publication on the Platform.
        </p>

        <p>For the purpose of these Terms and our Privacy Policy:</p>
        <ul>
            <li>“user(s)”, “you”, “your”, “they”, “their” shall refer to any user of the Platform;</li>
            <li>
                “AFP”, “we”, “our”, “us” shall refer to Agence France-Presse and, when applicable, Agence
                France-Presse’s partners.
            </li>
        </ul>

        <h2>2. Ownership of the Platform and the Content</h2>

        <p>
            The Platform, all its components and the content (in any form) displayed on or accessible through the
            Platform (the “Content”) are the exclusive property of AFP and/or its partners and are protected by
            intellectual property laws.
        </p>
        <p>
            Any use of the Platform and/or the Content for any other purpose or in any other manner than as expressly
            authorized herein is strictly prohibited.
        </p>

        <h2>3. Use of the Platform and the Content</h2>
        <h3>3.1 Access to the Platform</h3>
        <p>Access to the Platform is strictly reserved to users who have a dedicated user ID and password.</p>
        <p>
            Your user ID and password will be sent to you by email and are intended solely for your own use of the
            Platform. Your email address must be strictly personal and not a group address. Distribution in alias mode
            and associating mailing lists with your email address are not allowed. Your user ID and password shall
            remain strictly confidential. You shall immediately notify AFP of any breach of security or confidentiality
            related to your account or any unauthorized use of your (or your staff’s) account.
        </p>
        <p>
            If your user account allows you to create individual user accounts for your staff, it is your responsibility
            to only create individual user accounts for your permanent or temporary staff and to immediately cancel any
            individual user account pertaining to an individual no longer member of your permanent or temporary staff.
            You shall take full responsibility for any and all activities that occur under your administrator account
            and under any individual user account created therethrough.
        </p>
        <p>
            The content that may be produced by AFP (and/or its partners) while covering the events scheduled on the
            Platform is not accessible on the Platform. If you wish to access such content, you may do so by acquiring
            the corresponding rights through a subscription agreement with AFP.
        </p>

        <h3>3.2 Use of the Content</h3>

        <p>
            The Content available on the Platform is provided for consultation purposes only. You may save and share
            parts of the Platform and the Content for strictly personal and non-commercial purposes only and only as
            made possible by the features available on the Platform.
        </p>
        <p>
            Any other use, in particular any reproduction, communication to the public or distribution of the Platform
            and/or the Content, in whole or in part, for any other purpose and/or by any other means, without AFP’s
            written approval, is strictly prohibited.
        </p>

        <h2>4. Warranties</h2>
        <p>
            You hereby warrant and represent that you will access and use the Platform, its features and the Content in
            strict compliance with these Terms and the applicable law.
        </p>
        <p>
            You hereby undertake to defend, indemnify and hold harmless AFP and its partners from and against all
            expenses (including, but not limited to, reasonable legal fees and expenses), costs, losses, liabilities,
            damages, arising out of or in connection with any claim, demand, action, proceeding, directly or indirectly
            resulting from or based on a breach by you (or your staff) of this warranty.
        </p>

        <h2>5. Limitations and disclaimers on liability</h2>
        <p>
            The calendar of upcoming events and the types of coverage that AFP (and/or its partners) expects to provide
            are based on mere forecasts and are subject to changes at any time. The information available on the
            Platform is provided for reference only and shall by no means constitute an undertaking by AFP (and/or its
            partners) as to the actual coverage of the scheduled events, the types of coverage to be supplied and/or the
            actual distribution of the content produced while covering the said events.
        </p>
        <p>
            An event, its coverage by AFP (and/or its partners), the types of coverage to be supplied and/or the
            distribution of the content produced by AFP (and/or its partners) while covering an event may be suspended,
            cancelled, rescheduled, restricted or modified in any other way, at any time, without notice, due to causes
            beyond AFP’s (and/or its partners’) control and/or at AFP’s (and/or its partners’) sole discretion. AFP
            (and/or its partners) shall not incur any liability to this respect.
        </p>
        <p>
            The access to or the availability of the Platform or any of its features may be totally or partially
            interrupted or may run slowly due to causes beyond AFP’s control.
        </p>
        <p>
            The access to or the availability of the Platform or any of its features may be totally or partially
            modified, suspended, interrupted or restricted by AFP at any time.
        </p>
        <p>
            AFP cannot guarantee that the Platform and its features are provided without interruption, server downtime
            or delay or free from errors or any breach of security due to hackings, security intrusions, bugs, viruses,
            malwares, etc., that may affect your hardware, software or data.
        </p>
        <p>Your use of the Platform, its features and the Content is under your full and sole liability.</p>
        <p>
            AFP is not responsible for the websites referenced on the Platform or referencing the Platform (in
            particular by means of a hyperlink). References made to third party’s websites on the Platform shall by no
            means be construed as an endorsement by AFP or its partners of these websites or their content. Third
            party’s websites and their content are governed by their own terms of use.
        </p>
        <p>
            In no event shall AFP be liable for any damages whatsoever arising out of or related to the access or use,
            or the impossibility to access or use, the Platform, its features or the Content.
        </p>

        <h2>6. Trademarks and logos</h2>
        <p>
            The trademarks, logos and distinctive signs appearing on the Platform are the sole property of AFP and/or
            its partners. They may only be used, in whole or in part (including as a link to the Platform), with AFP’s
            and/or AFP’s partners prior written approval and in strict compliance with AFP’s and/or AFP’s partners
            graphic charter.
        </p>

        <h2>7. Governing law - Jurisdiction</h2>
        <p>
            These Terms and any dispute arising thereof shall be governed by and construed in accordance with French
            law.{' '}
        </p>
        <p>
            The Courts of Paris (France) shall have exclusive jurisdiction notwithstanding any actions in warranty or
            plurality of defendants.
        </p>

        <h2>8. Miscellaneous</h2>
        <p>
            Should any provision or part of a provision of these Terms be held illegal, invalid or unenforceable in
            accordance with a law or regulation in force or by virtue of any decision rendered by a competent
            jurisdiction, the remainder of the provision shall remain in full force and effect and the validity and
            enforceability of all other provisions in these Terms shall not be affected or impaired.
        </p>
    </div>
);

export default CGU;
