import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { detect } from 'detect-browser';
import cmp from 'semver-compare';

import { push } from 'react-router-redux';

import { getRedirectTo } from 'reducers/selectors';
import { resetRedirect } from 'reducers/common/commonActions';

import { notify } from 'reapop';

import PrivateRoute from './PrivateRoute';
import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';
import NotSupported from './NotSupported';
import AnonymousLayout from './AnonymousLayout';

const supportedBrowsers = {
    chrome: '4.10',
    edge: '6',
    firefox: '19.5',
    ie: '12',
    opera: '10.0',
    safari: '10.2',
};

class App extends PureComponent {
    state = {
        supported: true,
    };

    componentDidMount() {
        const browser = detect();
        this.determineBrowserSupport(browser);
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch } = this.props;
        if (nextProps.redirectTo) {
            dispatch(push(nextProps.redirectTo));
            dispatch(resetRedirect());
        }
    }

    determineBrowserSupport(browser) {
        if (!browser) {
            return;
        }
        if (!supportedBrowsers[browser.name]) {
            this.setState({ supported: true });
        } else {
            const browserVersion = supportedBrowsers[browser.name];
            if (cmp(browser.version, browserVersion) < 0) {
                this.setState({ supported: false });
            } else {
                this.setState({ supported: true });
            }
        }
    }

    componentDidCatch(error, info) {
        const { dispatch, t } = this.props;

        // eslint-disable-next-line
        console.warn('App componentDidCatch', { error, info });

        dispatch(
            notify({
                title: t('error.title'),
                message: error.message,
                status: 'error',
                dismissible: true,
                closeButton: true,
                // dismissAfter: 3000
            })
        );
    }

    render() {
        const { supported } = this.state;

        return (
            <>
                {!supported ? (
                    <NotSupported browsersList={supportedBrowsers} />
                ) : (
                    <Switch>
                        <Route path="/login" component={AuthLayout} />
                        <Route path="/rules" component={AnonymousLayout} />
                        <Route path="/help" component={AnonymousLayout} />
                        <PrivateRoute path="/" component={MainLayout} />
                    </Switch>
                )}
            </>
        );
    }
}

App.propTypes = {
    dispatch: PropTypes.func.isRequired,
    redirectTo: PropTypes.string,
    t: PropTypes.func.isRequired,
};

App.defaultProps = { redirectTo: null };

const mapStateToProps = state => ({
    redirectTo: getRedirectTo(state),
});

export default compose(
    translate(),
    connect(mapStateToProps)
)(App);
