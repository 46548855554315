import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { Link, Route } from 'react-router-dom';
import { getLang } from 'reducers/selectors';
import { getLanguageFromLocalStorage } from 'utils/storage';

import loadable from '@loadable/component';

const AsyncPage = loadable(props => import(`./${props.page}.${props.lang}`), {
    cacheKey: props => props.lang + props.page,
});

class HelpContainer extends PureComponent {
    componentDidMount() {
        const { t } = this.props;
        document.title = t('titles.Help_center');
    }

    onToggleSection = isOpened => {
        return !isOpened;
    };

    mainMenu = () => {
        const { t } = this.props;

        return (
            <div>
                <h2>{t('helpCenter')}</h2>

                <div className="help-section-container">
                    <Link to="/help/videos" className="help-section">
                        {t('help.VideosTutorials')}
                        <i className="icon slide-right-icon" />
                    </Link>
                    <Link to="/help/faq" className="help-section">
                        {t('help.FAQ')}
                        <i className="icon slide-right-icon" />
                    </Link>
                    <a href="mailto:contact-agenda@afp.com" className="help-section">
                        {t('help.Contact')}
                        <i className="icon slide-right-icon" />
                    </a>
                </div>
            </div>
        );
    };

    render() {
        const { t, lang } = this.props;

        return (
            <div className="help-container">
                <Route path="/help" exact render={() => this.mainMenu()} />
                <Route
                    path="/help/faq"
                    render={() => <AsyncPage page="FAQ" lang={lang} onToggleSection={this.onToggleSection} />}
                />
                <Route
                    path="/help/videos"
                    render={() => <AsyncPage page="Videos" lang={lang} onToggleSection={this.onToggleSection} />}
                />

                <div className="help-footer">
                    <div className="about">
                        <h3>{t('help.AboutAFP')}</h3>
                        <AsyncPage page="About" lang={lang} />
                    </div>

                    <div className="links">
                        <h3>{t('help.SeeAlso')}</h3>
                        <p>
                            <Link to="/rules/legal-disclaimer">{t('legalDisclaimer')}</Link>
                        </p>
                        <p>
                            <Link to="/rules/cgu">{t('cgu')}</Link>
                        </p>
                        <p>
                            <Link to="/rules/privacy-policy">{t('privacyPolicy')}</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    lang: getLang(state) || getLanguageFromLocalStorage(),
});

HelpContainer.propTypes = {
    lang: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(HelpContainer);
