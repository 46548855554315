import Cookies from 'universal-cookie';

export const COOKIE_TOKEN_KEY = 'Token';
export const CGU_STORAGE_KEY = 'hasAcceptedCGU';
export const LANG_LOCAL_STORAGE_KEY = 'i18nextLng';

export const getTokenFromCookie = () => {
    const cookies = new Cookies();

    return cookies.get(COOKIE_TOKEN_KEY);
};

export const getCGUFromLocalStorage = () => localStorage && localStorage.getItem(CGU_STORAGE_KEY);
export const saveCGUToLocalStorage = () => localStorage && localStorage.setItem(CGU_STORAGE_KEY, true);

export const getLanguageFromLocalStorage = () => localStorage && localStorage.getItem(LANG_LOCAL_STORAGE_KEY);
// export const saveLanguageToLocalStorage = lang => localStorage && localStorage.setItem(LANG_LOCAL_STORAGE_KEY, lang);

// export const loadState = () => {
//     try {
//         const serializedState = localStorage && localStorage.getItem('state');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// };

// export const saveState = state => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem('state', serializedState);
//     } catch (e) {
//         // ignore
//     }
// };
