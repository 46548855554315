import { DateTime } from 'luxon';

export const isToday = day => DateTime.local().hasSame(DateTime.fromISO(day), 'day');

export const isTomorrow = day =>
    DateTime.local()
        .plus({ days: 1 })
        .hasSame(DateTime.fromISO(day), 'day');

export const duringLast48Hours = day => DateTime.local().plus({ hours: -48 }) < DateTime.fromISO(day);

export const isSameDay = (date1, date2) => DateTime.fromISO(date1).hasSame(DateTime.fromISO(date2), 'day');

export const isAllDay = event => event.isAllDayEvent;

export const isDateOnly = date => DateTime.fromISO(date).toISODate() === date;
export const isDateWithTime = date => !isDateOnly(date);

export const Time = ({ date, locale, timezone }) =>
    DateTime.fromISO(date)
        .setLocale(locale)
        .setZone((timezone && timezone.code) || 'local')
        .toLocaleString(DateTime.TIME_24_SIMPLE);

export const Date = ({ date, locale, timezone }) =>
    DateTime.fromISO(date)
        .setLocale(locale)
        .setZone((timezone && timezone.code) || 'local')
        .toLocaleString();

export const ShortDate = ({ date, locale, timezone }) =>
    DateTime.fromISO(date)
        .setLocale(locale)
        .setZone((timezone && timezone.code) || 'local')
        .toFormat('d/MM');

export const longDate = ({ date, locale, timezone }) =>
    DateTime.fromISO(date)
        .setLocale(locale)
        .setZone((timezone && timezone.code) || 'local')
        .toFormat('d/MM');

export const toISODate = ({ date, timezone }) =>
    DateTime.fromISO(date)
        .setZone((timezone && timezone.code) || 'local')
        .toISO();
