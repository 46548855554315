import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

import { history } from '../store';

const goBack = () => history.goBack();

const BackButton = ({ children, className }) => (
    <Button className={className} onClick={goBack}>
        {children}
    </Button>
);

BackButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

BackButton.defaultProps = {
    className: 'btn-outline',
};

export default BackButton;
