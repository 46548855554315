import React from 'react';
import { PropTypes } from 'prop-types';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';

import { isTopNews, isCanceled, isPostponed, isUpdated, isMagazine } from 'utils/event';
import DateBlock from 'components/DateBlockComponent';
import { stringForLang, stringLabelForLang } from 'utils/locale';

export const EventComponent = ({ event, locale, lang, userLanguages, day, timezone, t }) => {
    const eventClass = classNames('event', {
        topnews: isTopNews(event),
        canceled: isCanceled(event),
        magazine: isMagazine(event, lang, userLanguages) && !isTopNews(event),
    });

    const renderCoverages = coverages => {
        if (!coverages) {
            return '';
        }

        return Object.keys(coverages)
            .map(code => code)
            .join(' , ');
    };

    return (
        <div className={eventClass}>
            <DateBlock event={event} locale={locale} timezone={timezone} day={day} />
            <div className="text">
                {process.env.NODE_ENV === 'development' && (
                    <div className="coverages">{renderCoverages(event.coverages)}</div>
                )}
                <div className="dateline">
                    {event.locations &&
                        stringLabelForLang({ field: event.locations[0].volatileFields, lang, userLanguages })}
                </div>
                <div
                    className="title"
                    title={stringForLang({
                        field: event.title,
                        lang,
                        userLanguages,
                        fallbackMessage: `event:${event.sequenceId}`,
                    })}>
                    {(isUpdated(event) || isPostponed(event) || isCanceled(event)) && (
                        <span className="status">
                            {isUpdated(event) && !isPostponed(event) && t('statusUpdated')}
                            {isPostponed(event) && t('statusPostponed')}
                            {isCanceled(event) && t('statusCanceled')}
                        </span>
                    )}
                    <Link to={`/events/${event.sequenceId}?day=${day}`}>
                        {stringForLang({
                            field: event.title,
                            lang,
                            userLanguages,
                            fallbackMessage: `event:${event.sequenceId}`,
                        })}
                    </Link>
                </div>
            </div>
        </div>
    );
};

EventComponent.propTypes = {
    event: PropTypes.shape({}).isRequired,
    day: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    timezone: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(EventComponent);
