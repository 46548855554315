import { schema } from 'normalizr';

export const eventSchema = new schema.Entity('events', {}, { idAttribute: 'sequenceId' });
export const eventListSchema = new schema.Array(eventSchema);

export const filterSchema = new schema.Entity('filters');
export const filterListSchema = new schema.Array(filterSchema);

export const metadataSchema = new schema.Entity('metadatas');
export const metadataListSchema = new schema.Array(metadataSchema);
