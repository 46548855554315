import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import SearchForm from './SearchFormComponent';

const Search = ({ t, handleSubmit, handleSaveFilter, handleResetSearch, ...props }) => (
    <div className="content-wrapper search-container">
        <h2>{t('search.label')}</h2>
        <form>
            <SearchForm {...props} />

            <div className="actions">
                <button className="btn-primary" type="submit" onClick={handleSubmit}>
                    {/* <span className="icon do-search-icon" /> */}
                    {/* &nbsp; */}
                    {t('displayFilter')}
                </button>
                <button type="button" className="btn-outline" onClick={handleResetSearch}>
                    {/* <span className="icon search-icon" /> */}
                    {/* &nbsp; */}
                    {t('newSearch')}
                </button>
            </div>
        </form>
    </div>
);

Search.propTypes = {
    lang: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSaveFilter: PropTypes.func.isRequired,
    handleResetSearch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default translate()(Search);
