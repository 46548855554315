import React from 'react';
import { PropTypes } from 'prop-types';

import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { withState } from 'recompose';
import qs from 'qs';

import { DateTime } from 'luxon';
import { Collapse } from 'react-collapse';
import Linkify from 'react-linkify';

import ReactGA from 'react-ga';

import DateBlock from 'components/DateBlockComponent';
import BackButton from 'components/BackButton';

import { isEmpty } from 'utils/utils';
import { isTopNews, isCanceled, isPostponed, isMagazine, areDatesNotConfirmed } from 'utils/event';
import { ShortDate, isDateOnly, Time } from 'utils/date';
import { stringForLang, stringLabelForLang } from 'utils/locale';

const DateHeader = ({ day, event, locale, timezone }) => {
    const today = DateTime.local()
        .setLocale(locale)
        .setZone((timezone && timezone.code) || 'local');

    const getDate = date =>
        DateTime.fromISO(date)
            .setLocale(locale)
            .setZone((timezone && timezone.code) || 'local', { keepLocalTime: isDateOnly(date) });

    const getDateReference = (date, startDate, endDate, currentDate) => {
        const dayDate = date && getDate(date);
        const eventStartDate = startDate && getDate(startDate);
        const eventEndDate = endDate && getDate(endDate);

        if (dayDate && dayDate < eventEndDate) {
            return dayDate;
        }
        if (!dayDate && currentDate > eventStartDate && currentDate < eventEndDate) {
            return currentDate;
        }
        return eventStartDate;
    };

    const date = getDate(getDateReference(day, event.dateBegin, event.dateEnd, today));

    return (
        <section className="header">
            {event && (
                <div className="datetime-wrapper">
                    <div className="date-container">
                        {/* date à laquelle on a cliqué */}
                        <div className="day">{date.toLocaleString({ weekday: 'long' })}</div>
                        <div className="dateline">{date.toLocaleString(DateTime.DATE_FULL)}</div>
                    </div>

                    <DateBlock
                        className="date-block hour"
                        event={event}
                        locale={locale}
                        timezone={timezone}
                        day={day}
                        absolute
                    />
                </div>
            )}
        </section>
    );
};

DateHeader.propTypes = {
    event: PropTypes.shape({}).isRequired,
    day: PropTypes.string,
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.shape({}).isRequired,
};

DateHeader.defaultProps = {
    day: undefined,
};

const Location = ({ event, lang, userLanguages }) =>
    event.locations ? (
        <section className="location">
            {event.locations
                .map(location => stringLabelForLang({ field: location.volatileFields, lang, userLanguages }))
                .join(', ')}
        </section>
    ) : null;
Location.propTypes = {
    event: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};

const Title = ({ event, lang, userLanguages }) => (
    <h3>
        {event &&
            stringForLang({ field: event.title, lang, userLanguages, fallbackMessage: `event:${event.sequenceId}` })}
    </h3>
);
Title.propTypes = {
    event: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};

const strongify = text => {
    const colonRegex = /^(.*? {0,1}:(?!\/\/))(.*)$/;
    const matches = colonRegex.exec(text);
    if (matches && matches.length > 2) {
        return (
            <React.Fragment>
                <strong>{matches[1]}</strong> {matches[2]}
            </React.Fragment>
        );
    }
    return text;
};

const pify = text => text && text.split('\n').map((line, index) => <p key={index.toString()}>{strongify(line)}</p>);

const Description = ({ event, lang, userLanguages }) => {
    const getDescription = () => event && stringForLang({ field: event.description, lang, fallback: false });

    const desc = getDescription();
    return (
        <div className="description">
            <Linkify properties={{ target: '_blank', rel: 'noreferrer noopener' }}>{pify(desc)}</Linkify>
        </div>
    );
};
Description.propTypes = {
    event: PropTypes.shape({}),
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
};
Description.defaultProps = {
    event: undefined,
};

const CoverageItem = withState('isOpened', 'setIsOpened', {})(
    ({ coverage, label, isOpened, setIsOpened, locale, lang, userLanguages, timezone, t }) => {
        const onToggleCollapse = () => {
            setIsOpened({ ...isOpened, [label]: !isOpened[label] });
        };

        const iconForCoverage = status => `${status.toLowerCase()}-icon`;
        const advisoriesInUserLocale =
            (coverage && coverage.advisories && coverage.advisories.filter(advisory => advisory.lang === lang)) || {};

        return (
            <li key={label}>
                <div
                    className="collapse-header"
                    onClick={onToggleCollapse}
                    onKeyPress={onToggleCollapse}
                    tabIndex="-1"
                    role="button">
                    <div className="flex-grow-1">
                        <span
                            className={`icon ${iconForCoverage(
                                coverage && coverage.status ? coverage.status : 'undecided'
                            )}`}
                        />
                        {label}
                    </div>
                    {advisoriesInUserLocale.length > 0 && (
                        <React.Fragment>
                            <span className="count">{advisoriesInUserLocale.length}</span>
                            <span className={`open-close icon ${isOpened[label] ? 'collapse-icon' : 'expand-icon'}`} />
                        </React.Fragment>
                    )}
                </div>

                <Collapse isOpened={!!isOpened[label]}>
                    <div>
                        {advisoriesInUserLocale.map(advisory => (
                            <React.Fragment key={advisory.id}>
                                <div className="prev">
                                    <div className="day">
                                        <ShortDate
                                            date={advisory.details[0].date}
                                            locale={locale}
                                            timezone={timezone}
                                        />
                                    </div>
                                    <div className="details">
                                        <div className="hour-attribute">
                                            {advisory.details[0].toBeActualized && t('toBeActualized')}
                                            {advisory.details[0].toBeDefined && t('toBeDefined')}
                                            {!advisory.details[0].toBeActualized &&
                                                !advisory.details[0].toBeDefined && (
                                                    <Time
                                                        date={advisory.details[0].date}
                                                        locale={locale}
                                                        timezone={timezone}
                                                    />
                                                )}

                                            {advisory.details[0].attribute && advisory.details[0].attribute.label && (
                                                <React.Fragment>
                                                    <span>&nbsp;—&nbsp;</span>
                                                    {stringForLang({
                                                        field: advisory.details[0].attribute.label,
                                                        lang,
                                                        userLanguages,
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="label">{advisory.label}</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </Collapse>
            </li>
        );
    }
);

CoverageItem.propTypes = {
    coverage: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
};
CoverageItem.defaultProps = {
    coverage: undefined,
};

const Coverages = ({ services, coverages, lang, t }) => (
    <section className="coverages">
        <h4>{t('eventCoverages')}</h4>
        {isEmpty(coverages) && <p>{t('noCoverageYet')}</p>}
        {coverages && (
            <ul>
                {services
                    .sort((a, b) => a.rank - b.rank)
                    .map(({ code, rank, ...rest }) => {
                        const coverage = coverages && coverages[code];
                        return (
                            coverage && (
                                <CoverageItem
                                    key={code}
                                    coverage={coverage}
                                    lang={lang}
                                    t={t}
                                    label={t(`services.${code}`)}
                                />
                            )
                        );
                    })}
            </ul>
        )}
    </section>
);
Coverages.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    coverages: PropTypes.shape({}),
    lang: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};
Coverages.defaultProps = {
    coverages: {},
};

const ShareButtons = ({ afoDocs, event, t }) => {
    const hasAFODocs = afoDocs && !!afoDocs.nbItems;

    const showOnAfpForum = () => {
        ReactGA.event({
            category: 'afpforum',
            action: 'to define',
            label: `View AFO Docs: ${event.sequenceId}`,
        });
        const refWindow = window.open(afoDocs.afoLink, '_blank');
        refWindow.opener = null;
    };

    return (
        <div className="share actions">
            <Link to={`/events/${event.sequenceId}/share`} className="btn btn-secondary">
                <span className="icon arrow-right-icon" />
                {t('share')}
            </Link>
            {hasAFODocs && (
                <button type="button" className="btn btn-primary" onClick={showOnAfpForum}>
                    <span className="icon afpforum-icon" />
                    {t('showAfpForum')}
                </button>
            )}
        </div>
    );
};
ShareButtons.propTypes = {
    afoDocs: PropTypes.shape({}),
    event: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
};

ShareButtons.defaultProps = {
    afoDocs: undefined,
};

// https://www.regextester.com/1978 (phone number regex)
// ^\+?(?:[\(]?[0-9][\(\) -]?){6,14}[0-9]$

const EventDetail = ({ event, afoDocs, services, locale, lang, userLanguages, location, timezone, t }) => {
    const { day } = qs.parse(location.search.slice(1));

    let eventStatus = '';

    // @TODO: à améliorer
    if (isCanceled(event)) {
        eventStatus = 'status-canceled';
    } else if (isTopNews(event)) {
        eventStatus = 'status-topnews';
    } else if (isMagazine(event, lang, userLanguages)) {
        eventStatus = 'status-feature';
    } else if (isPostponed(event)) {
        eventStatus = 'status-postponed';
    }

    return (
        <div className={`event-detail ${eventStatus}`}>
            <DateHeader event={event} locale={locale} timezone={timezone} day={day} t={t} />

            <Location event={event} lang={lang} userLanguages={userLanguages} />

            <section className="content">
                {eventStatus && (
                    <div className="info">
                        {isPostponed(event) && (
                            <React.Fragment>
                                <div className="status-message">{t('eventPostponed')}</div>
                                <div className="show-event-link">
                                    <Link to={`/events/${event.replacedBy.sequenceId}`}>{t('showEventUpdated')}</Link>
                                </div>
                            </React.Fragment>
                        )}
                        {isCanceled(event) && <div className="status-message">{t('statusCanceled')}</div>}
                        {!isCanceled(event) && isTopNews(event) && t('statusTopNews')}{' '}
                        {isMagazine(event, lang, userLanguages) && t('statusMagazine')}
                    </div>
                )}

                {!isCanceled(event) && areDatesNotConfirmed(event) && (
                    <div className="status-message tbc">{t('datesNotConfirmed')}</div>
                )}

                <Title event={event} lang={lang} userLanguages={userLanguages} />

                <p>
                    {t('lastUpdate')}{' '}
                    {DateTime.fromISO(event.dateLastModified)
                        .setLocale(locale)
                        .setZone((timezone && timezone.code) || 'local')
                        .toLocaleString(DateTime.DATETIME_MED)}
                </p>

                <Description event={event} lang={lang} userLanguages={userLanguages} />

                {isPostponed(event) || isCanceled(event) || <ShareButtons afoDocs={afoDocs} event={event} t={t} />}

                <div className="address-contact">
                    {event.address && (
                        <div className="address">
                            <h5>{t('address')}</h5>
                            <div>
                                <Linkify properties={{ target: '_blank', rel: 'noreferrer noopener' }}>
                                    {pify(event.address)}
                                </Linkify>
                            </div>
                        </div>
                    )}

                    {event.contact && (
                        <div className="contact">
                            <h5>{t('contact')}</h5>
                            <div>
                                <Linkify properties={{ target: '_blank', rel: 'noreferrer noopener' }}>
                                    {pify(event.contact)}
                                </Linkify>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Coverages
                services={services}
                event={event}
                coverages={event.coverages}
                lang={lang}
                userLanguages={userLanguages}
                t={t}
            />

            {/* {renderIptc({ iptcs: event.iptcMediaTopics, t })} */}

            <section className="back">
                <BackButton className="btn-outline-strong">
                    <span className="icon slide-left-icon" />
                    &nbsp;
                    {t('actions.back')}
                </BackButton>
            </section>
        </div>
    );
};

EventDetail.propTypes = {
    event: PropTypes.shape({}).isRequired,
    afoDocs: PropTypes.shape({}),
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    location: PropTypes.shape({}).isRequired,
    timezone: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
};

EventDetail.defaultProps = {
    afoDocs: undefined,
};

export default translate()(EventDetail);
