import React from 'react';
import PropTypes from 'prop-types';

import CGUEn from './CGU.en';
import CGUFr from './CGU.fr';

const CGU = ({ lang }) => (lang === 'fr' ? <CGUFr /> : <CGUEn />);

CGU.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default CGU;
