import { notify } from 'reapop';
import * as api from 'api/events';
import i18n from 'i18n';

import {
  requestEvent,
  receiveEvent,
  requestAfoDocs,
  receiveAfoDocs,
} from './eventActions';

export const fetchEvent = sequenceId => dispatch => {
  dispatch(requestEvent(sequenceId));

  return api
    .fetchEvent(sequenceId)
    .then(event => {
      dispatch(receiveEvent(event));
    })
    .catch(() => {
      // dispatch({
      //     type: types.FETCH_EVENT_FAILURE,
      // });
      dispatch(
        notify({ title: i18n.t('feedback.failedFetchEvent'), status: 'error' })
      );
    });
};

export const fetchAFODocs = sequenceId => dispatch => {
  dispatch(requestAfoDocs(sequenceId));

  return api
    .fetchAFODocs(sequenceId)
    .then(afoDocs => dispatch(receiveAfoDocs(afoDocs)));
};
