import { createActions } from 'redux-actions';

const afoActions = createActions({
    AFO: {
        ADVISORIES: {
            FETCH: {
                REQUEST: undefined,
                RESPONSE: afoLink => afoLink,
            },
        },
    },
});

export const requestAfoAdvisoriesLink = afoActions.afo.advisories.fetch.request;
export const receiveAfoAdvisoriesLink = afoActions.afo.advisories.fetch.response;
