import api, { mapResponseData, getTokenAuthHeader } from 'api';

export const saveUserPreferences = preferences => {
  const url = `user/preferences`;

  return api.put(url, preferences).then(mapResponseData);
};

export const loginWithToken = (token, getAuthHeader = getTokenAuthHeader) => {
  const url = `/user/session`;

  const headers = { Authorization: getAuthHeader(token) };

  return api.get(url, { headers }).then(mapResponseData);
};

export const logout = () => {
  const url = `/user/logout`;

  return api.post(url, null);
};
