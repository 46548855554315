import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getIsAuthenticated } from 'reducers/selectors';

const PrivateRoute = ({ component: PrivateComponent, isAuthenticated, location, ...rest }) =>
    isAuthenticated ?
        <Route {...rest} render={props => <PrivateComponent {...props} />} /> :
        <Redirect to={{ pathname: '/login', state: { from: location } }} />;

const mapStateToProps = state => ({
    isAuthenticated: getIsAuthenticated(state),
});

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.shape({ login: PropTypes.bool, password: PropTypes.bool }).isRequired,
    component: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
