import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { sidebarMenuActions, collapseActions } from './uiActions';

const isMenuVisible = handleActions(
    {
        [sidebarMenuActions.toggle]: state => !state,
        [combineActions(sidebarMenuActions.open, sidebarMenuActions.close)]: (_, action) => action.payload,
        '@@router/LOCATION_CHANGE': () => false,
    },
    false
);

const isAgendaSectionOpen = handleActions(
    {
        [collapseActions.userAgenda.toggle]: state => !state,
        [combineActions(collapseActions.userAgenda.open, collapseActions.userAgenda.close)]: (_, action) =>
            action.payload,
    },
    true
);

const isAfpAgendaSectionOpen = handleActions(
    {
        [collapseActions.afpAgenda.toggle]: state => !state,
        [combineActions(collapseActions.afpAgenda.open, collapseActions.afpAgenda.close)]: (_, action) =>
            action.payload,
    },
    false
);

export default combineReducers({ isMenuVisible, isAgendaSectionOpen, isAfpAgendaSectionOpen });
