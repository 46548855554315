import { createSelector } from 'reselect';

import { mapMetadataToOption } from 'utils/utils';
import { parseToLang } from 'utils/locale';
import { METADATA_TYPES } from 'utils/constants';

import * as uiSelectors from 'reducers/ui/uiSelectors';
import * as commonSelectors from 'reducers/common/commonSelectors';
import * as userSelectors from 'reducers/users/userSelectors';
import * as metadatasSelectors from 'reducers/metadatas/metadatasSelectors';

import * as filtersSelectors from 'reducers/filters/filtersSelectors';
import * as eventsSelectors from 'reducers/events/eventsSelectors';
import * as searchSelectors from 'reducers/search/searchSelectors';
import { filterByDay } from 'utils/event';
import { DateTime } from 'luxon';

const ROUTING = 'routing';
const EVENTS = 'events';
const FILTERS = 'filters';
const UI = 'ui';
const USER = 'user';
const COMMON = 'common';
const SEARCH = 'search';
const METADATAS = 'metadatas';

export const getUserTimezone = state => userSelectors.getTimezone(state[USER]);
export const getUserPreferences = state => userSelectors.getUserPreferences(state[USER]);
export const getUser = state => userSelectors.getUser(state[USER]);
export const getUserLanguages = state => userSelectors.getUserLanguages(state[USER]);
export const getAreCredentialsInvalid = state => userSelectors.getAreCredentialsInvalid(state[USER]);
export const getIsAuthenticated = state => userSelectors.getIsAuthenticated(state[USER]);
export const getHasTimeout = state => userSelectors.getHasTimeout(state[USER]);
export const getIsSessionLoading = state => userSelectors.getIsSessionLoading(state[USER]);
export const getHasAttemptedAuth = state => userSelectors.getHasAttemptedAuth(state[USER]);

export const getFilterIdFromMatch = (_, { match }) => match.params.filterId;
export const getEventIdFromMatch = (_, { match }) => match.params.eventId;

export const getRoutingLocation = state => state[ROUTING].location;

export const getLocale = state => commonSelectors.getLocale(state[COMMON]);
export const getLang = createSelector(
    getLocale,
    locale => parseToLang(locale)
);
export const getRedirectTo = state => commonSelectors.getRedirectTo(state[COMMON]);

export const getFilterIdMap = state => filtersSelectors.getIdMap(state[FILTERS]);
export const getFilters = state => filtersSelectors.getFilters(state[FILTERS]);
export const getFilter = (state, filterId) => filtersSelectors.getFilter(state[FILTERS], filterId);
export const getFilterFromMatch = createSelector(
    getFilterIdMap,
    getFilterIdFromMatch,
    (idMap, id) => idMap[id]
);

export const getQuery = state => searchSelectors.getQuery(state[SEARCH]);
export const getNextLink = state => searchSelectors.getNextLink(state[SEARCH]);
export const getIsSearchLoading = state => searchSelectors.getIsLoading(state[SEARCH]);
export const getSearchFilterId = state => searchSelectors.getSearchFilterId(state[SEARCH]);
export const getResultIds = state => searchSelectors.getResultIds(state[SEARCH]);

export const getEventIdMap = state => eventsSelectors.getIdMap(state[EVENTS]);
export const getResultEvents = createSelector(
    getResultIds,
    getEventIdMap,
    (ids, idMap) => ids.map(id => idMap[id])
);

export const getDaysFromEvents = createSelector(
    getResultEvents,
    getQuery,
    getUserTimezone,
    (events, query, timezone) => {
        const startDate = query && query.fixedDateFrom ? DateTime.fromISO(query.fixedDateFrom) : DateTime.local();

        return filterByDay(events, startDate, timezone);
    }
);

export const getEvent = (state, id) => eventsSelectors.getEvent(state[EVENTS], id);
export const getEventFromMatch = createSelector(
    getEventIdMap,
    getEventIdFromMatch,
    (idMap, id) => idMap[id]
);
export const getSelectedEventId = state => eventsSelectors.getSelectedEventId(state[EVENTS]);
export const isSelectedEventLoading = state => eventsSelectors.isSelectedEventLoading(state[EVENTS]);
export const getSelectedEventAfoDocs = state => eventsSelectors.getSelectedEventAfoDocs(state[EVENTS]);
export const isAfoDocsLoading = state => eventsSelectors.isAfoDocsLoading(state[EVENTS]);

export const getMetadata = (state, id) => metadatasSelectors.getMetadata(state[METADATAS], id);
export const getServices = state => metadatasSelectors.getServices(state[METADATAS]);
export const getServicesAsOptions = createSelector(
    getServices,
    getLang,
    getUserLanguages,
    (services, lang, userLanguages) =>
        services.sort((a, b) => a.rank - b.rank).map(mapMetadataToOption(lang, userLanguages)(METADATA_TYPES.service))
);
export const getIptcs = state => metadatasSelectors.getIptcs(state[METADATAS]);
export const getIptcsAsOptions = createSelector(
    getIptcs,
    getLang,
    getUserLanguages,
    (iptcs, lang, userLanguages) => iptcs.map(mapMetadataToOption(lang, userLanguages)(METADATA_TYPES.iptc))
);
export const getRegions = state => metadatasSelectors.getRegions(state[METADATAS]);
export const getRegionsAsOptions = createSelector(
    getRegions,
    getLang,
    getUserLanguages,
    (regions, lang, userLanguages) => regions.map(mapMetadataToOption(lang, userLanguages)(METADATA_TYPES.region))
);
export const getAgendaPresets = state => metadatasSelectors.getAgendaPresets(state[METADATAS]);
export const getAgendaPresetsAsOptions = createSelector(
    getAgendaPresets,
    getLang,
    getUserLanguages,
    (agendaPresets, lang, userLanguages) =>
        agendaPresets.map(mapMetadataToOption(lang, userLanguages)(METADATA_TYPES.agendaPreset))
);
export const getSearchAgendaId = state => searchSelectors.getSearchAgendaId(state[SEARCH]);
export const getAgenda = (state, agendaId) => metadatasSelectors.getAgenda(state[METADATAS], agendaId);

export const getRelativeDurations = state => metadatasSelectors.getRelativeDurations(state[METADATAS]);
export const getRelativeDurationsAsOptions = createSelector(
    getRelativeDurations,
    getLang,
    getUserLanguages,
    (relativeDurations, lang, userLanguages) =>
        relativeDurations.map(mapMetadataToOption(lang, userLanguages)(METADATA_TYPES.relativeDuration))
);

export const getIsMenuVisible = state => uiSelectors.getIsMenuVisible(state[UI]);
export const getIsAgendaSectionOpen = state => uiSelectors.getIsAgendaSectionOpen(state[UI]);
export const getIsAfpAgendaSectionOpen = state => uiSelectors.getIsAfpAgendaSectionOpen(state[UI]);

export const getAFOAdvisoriesLink = state => searchSelectors.getAfoLink(state[COMMON]);
