import { createActions } from 'redux-actions';

// Action Creators
const icsExportActions = createActions({
    ICS_EXPORT: {
        FETCH: {
            REQUEST: undefined,
            RESPONSE: undefined,
        },
    },
});
export const requestIcsExport = icsExportActions.icsExport.fetch.request;
export const receiveIcsExport = icsExportActions.icsExport.fetch.response;
