import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { requestIcsExport, receiveIcsExport } from 'reducers/ics/icsActions';

// Reducer
const isLoading = handleActions(
  {
    [requestIcsExport]: () => true,
    [receiveIcsExport]: () => false,
  },
  false
);

export default combineReducers({ isLoading });
