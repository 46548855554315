import React from 'react';
import PropTypes from 'prop-types';

import { Collapse } from 'react-collapse';

const CollapseSection = ({ title, iconClassname, children, isOpened, onToggleCollapse }) => (
    <div className="section filters">
        <div onClick={onToggleCollapse} onKeyPress={onToggleCollapse} role="button" tabIndex="0">
            <h4>
                <span className={`icon ${iconClassname}`} />
                {title}
                <span className={`open-close icon ${isOpened ? 'collapse-icon' : 'expand-icon'}`} />
            </h4>
        </div>
        <Collapse isOpened={isOpened}>{children}</Collapse>
    </div>
);

CollapseSection.propTypes = {
    title: PropTypes.string.isRequired,
    iconClassname: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onToggleCollapse: PropTypes.func.isRequired,
};

export default CollapseSection;
