import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ type, children, ...props }) => (
    /* eslint-disable react/button-has-type */
    <button type={type} {...props}>
        {children}
    </button>
    /* eslint-enable react/button-has-type */
);

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    children: PropTypes.node.isRequired,
};

Button.defaultProps = {
    type: 'button',
};

export default Button;
