import * as api from 'api/events';

import { getFilter, getAgenda, getMetadata, getIptcs, getAgendaPresets } from 'reducers/selectors';
import { requestEvents, receiveEvents } from 'reducers/events/eventsActions';
import { DateTime } from 'luxon';
import ReactGA from 'react-ga';
import { DEFAULT_QUERY } from 'utils/constants';
import { setQueryFromSearch, setQueryFromFilter, setQueryFromAgenda, resetQuery } from './searchActions';

const buildGALabels = ({ query, getState }) => {
    let label = 'Tous les événements';

    if (query.text) {
        label += ` ${query.text}`;
    }

    if (query.topNewsOnly) {
        label += ' dans les dominantes';
    }

    if (query.regions && query.regions.length > 0) {
        if (query.regions.length === 1) {
            label += ` dans la région ${query.regions[0]}`;
        } else {
            label += ` dans les régions ${query.regions.join(', ')}`;
        }
    }

    if (query.iptcs && query.iptcs.length > 0) {
        const iptcs = getIptcs(getState());

        if (query.iptcs.length === 1) {
            label += ` dans la catégorie ${
                iptcs.filter(iptc => iptc.externalIds[0].id === query.iptcs[0])[0].label.fr
                }`;
        } else {
            label += ` dans les catégories ${query.iptcs
                .map(iptcId => iptcs.filter(iptc => iptc.externalIds[0].id === iptcId)[0].label.fr)
                .join(', ')}`;
        }
    }

    if (query.fixedDateFrom) {
        label += ` à partir du ${DateTime.fromISO(query.fixedDateFrom)
            .setLocale('fr')
            .toFormat('dd/MM/yyyy')}`;
    }

    if (query.relativeDuration) {
        label += ` (+${query.relativeDuration})`;
    }

    if (query.fixedDateTo) {
        label += ` jusqu'au ${DateTime.fromISO(query.fixedDateTo)
            .setLocale('fr')
            .toFormat('dd/MM/yyyy')}`;
    }

    if (query.agendas && query.agendas.length > 0) {
        const agendas = getAgendaPresets(getState());
        if (query.agendas.length === 1) {
            label += ` à partir de l'agenda ${agendas.filter(agenda => agenda.id === query.agendas[0])[0].label.fr}`;
        } else {
            label += ` à partir des agendas ${query.agendas
                .map(agendaId => agendas.filter(agenda => agenda.id === agendaId)[0].label.fr)
                .join(', ')}`;
        }
    }

    if (query.services && query.services.length > 0) {
        label += ` par type de contenus ${query.services.join(', ')}`;
    }

    return label;
};

// Side Effects
export const simpleSearch = ({ query = '', nextLink = '', lang, timezone }) => (dispatch, getState) => {
    if (!query && !nextLink) {
        return Promise.resolve();
    }

    dispatch(requestEvents());

    ReactGA.event({
        category: 'Search',
        action: 'Simple',
        label: buildGALabels({ query, getState }),
    });

    return api.simpleSearch({ query, nextLink, lang, timezone }).then(({ events, next }) => {
        dispatch(receiveEvents(events, next));
    });
};

export const resetAndSearch = (query, lang, timezone) => dispatch => {
    dispatch(setQueryFromSearch(query));

    dispatch(simpleSearch({ query, lang, timezone }));
};

export const setFilterSearch = filterId => (dispatch, getState) => {
    const filter = getFilter(getState(), filterId) || getMetadata(getState(), filterId);

    if (filter) {
        ReactGA.event({
            category: 'Search',
            action: 'Filter',
            label: buildGALabels({ query: filter.query, getState }),
        });

        dispatch(resetQuery());
        dispatch(setQueryFromFilter(filter));
    }
};

export const setAgendaSearch = agendaId => (dispatch, getState) => {
    const agenda = getAgenda(getState(), agendaId) || getMetadata(getState(), agendaId);

    if (agenda) {
        ReactGA.event({
            category: 'Search',
            action: 'Agenda',
            label: buildGALabels({ query: {}, getState }),
        });

        dispatch(resetQuery());
        dispatch(setQueryFromAgenda({ ...DEFAULT_QUERY, agendas: [agendaId] }, agenda));
    }
};
