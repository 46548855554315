import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import { DateTime } from 'luxon';

import { notify } from 'reapop';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { isSameDay, isAllDay, isDateWithTime /* toISODate */ } from 'utils/date';

import {
    getEventFromMatch,
    getEventIdFromMatch,
    getLocale,
    getFilterFromMatch,
    getFilterIdFromMatch,
    getLang,
    getUserLanguages,
} from 'reducers/selectors';

import { fetchFilter } from 'reducers/filters/filtersEffects';
import { fetchEvent } from 'reducers/events/eventEffects';
import { exportEventsToICS } from 'reducers/ics/icsEffects';

import BackButton from 'components/BackButton';
import { isMobile } from 'utils/utils';
import { stringForLang } from 'utils/locale';

const branchSharing = (
    { isSharingEvent, isSharingFilter },
    { isSharingEventAction, isSharingFilterAction, defaultAction }
) => {
    if (isSharingEvent) {
        return isSharingEventAction;
    }
    if (isSharingFilter) {
        return isSharingFilterAction;
    }
    return defaultAction;
};

class ShareContainer extends PureComponent {
    state = {
        isCrappyIE: false,
    };

    componentWillMount() {
        let isCrappyIE = false;
        if (typeof window !== 'undefined' && window.navigator.msSaveOrOpenBlob && window.Blob) {
            isCrappyIE = true;
        }

        this.setState({ isCrappyIE });
    }

    componentDidMount() {
        const {
            dispatch,
            event,
            eventId,
            filter,
            filterId,
            isSharingEvent,
            isSharingFilter,
            lang,
            userLanguages,
        } = this.props;

        if (isSharingEvent) {
            dispatch(fetchEvent(eventId));
        }

        if (isSharingFilter) {
            dispatch(fetchFilter(filterId));
            this.updateTitle(filter.label.fr);
        }

        if (event) {
            dispatch(exportEventsToICS([event.id])).then(ics => this.setState({ ics }));

            this.updateTitle(
                stringForLang({
                    field: event.title,
                    lang,
                    userLanguages,
                    fallbackMessage: `event:${event.sequenceId}`,
                })
            );
        }
    }

    componentWillReceiveProps({ event: newEvent }) {
        const { dispatch, event: oldEvent, lang, userLanguages } = this.props;

        const oldEventId = oldEvent && oldEvent.id;
        const newEventId = newEvent && newEvent.id;

        if (oldEventId !== newEventId) {
            dispatch(exportEventsToICS([newEventId])).then(ics => this.setState({ ics }));

            this.updateTitle(
                stringForLang({
                    field: newEvent.title,
                    lang,
                    userLanguages,
                    fallbackMessage: `event:${newEvent.sequenceId}`,
                })
            );
        }
    }

    updateTitle = title => {
        const { t } = this.props;
        document.title = `${t('titles.Share')}: ${title}`;
    };

    // Arrow fx for binding
    handleExportIcsClick = e => {
        const { eventId } = this.props;
        const { isCrappyIE, ics } = this.state;

        e.preventDefault();

        const url = e.currentTarget.getAttribute('href');

        if (!isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
            const filename = `AFP Agenda - Event ${eventId}.ics`;
            const blob = new Blob([ics], { type: 'text/calendar;charset=utf-8' });

            if (isCrappyIE) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // many browsers do not properly support downloading data URIs
                // (even with "download" attribute in use) so this solution
                // ensures the event will download cross-browser
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            const refWindow = window.open(url, '_blank');
            refWindow.opener = null;
        }
    };

    handleShareFilterClick = () => {
        // Share
    };

    handleMailTo = () => {
        // subject: AFP Agenda: <Titre de l'événement> | Agenda <Titre de l'agenda>
        // body:
    };

    buildUrl = () => {
        const { ics, isCrappyIE } = this.state;
        if (!isCrappyIE && isMobile()) {
            return encodeURI(`data:text/calendar;charset=utf8,${ics}`);
        }
        return ics;
    };

    computeDateline() {
        const { event, locale, t } = this.props;
        if (!event) {
            return '';
        }

        const toTitleCase = text =>
            text
                .toLowerCase()
                .split(' ')
                .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');

        const beginMoment = DateTime.fromISO(event.dateBegin).setLocale(locale);
        // .setZone((timezone && timezone.code) || 'local')
        const endMoment = DateTime.fromISO(event.dateEnd).setLocale(locale);
        // .setZone((timezone && timezone.code) || 'local')

        const beginDate = beginMoment.toLocaleString(DateTime.DATE_HUGE);
        const beginTime = beginMoment.toLocaleString(DateTime.TIME_SIMPLE);

        const endDate = endMoment.toLocaleString(DateTime.DATE_HUGE);
        const endTime = endMoment.toLocaleString(DateTime.TIME_SIMPLE);

        const begin = isDateWithTime(event.dateBegin) ? `${beginDate} ${beginTime}` : beginDate;
        const end = isDateWithTime(event.dateEnd) ? `${endDate} ${endTime}` : endDate;

        if (isAllDay(event) && isSameDay(event.dateBegin, event.dateEnd)) {
            return `${beginDate} - ${t('allDay')}`;
        }

        return `${toTitleCase(begin)} - ${toTitleCase(end)}`;
    }

    computeData() {
        const { event, lang, userLanguages, eventId } = this.props;
        const title =
            event &&
            stringForLang({ field: event.title, lang, userLanguages, fallbackMessage: `event:${event.sequenceId}` });

        const dateline = this.computeDateline();

        const locations =
            event &&
            event.locations.map(location => stringForLang({ field: location.label, lang, userLanguages })).join(', ');
        const description = event && stringForLang({ field: event.description, lang, userLanguages });
        const address = event && event.address;
        const contact = event && event.contact;
        const url = `${window.location.protocol}//${window.location.host}/events/${eventId}`;

        return { title, dateline, locations, address, description, contact, url };
    }

    renderEventEmailButton() {
        const { t } = this.props;

        const { title, dateline, locations, address, description, contact, url } = this.computeData();

        const replaceLineBreaks = text => text.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');

        const desc = description ? `${replaceLineBreaks(description)}%0D%0A%0D%0A` : '';

        const addr = address ? `${replaceLineBreaks(address)}%0D%0A%0D%0A` : '';

        const cont = contact ? `${replaceLineBreaks(contact)}%0D%0A%0D%0A` : '';

        const subject = t('emailEventSubject', { title });

        const body = `${dateline}%0D%0A
${locations}%0D%0A%0D%0A
${addr}
${desc}
${cont}
${t('emailEventFind')}: ${url}`;

        return (
            <a href={`mailto:?subject=${subject}&body=${body}`} className="btn-primary" onClick={() => {}}>
                {/* <span className="icon share-icon" />  */}
                {t('email')}
            </a>
        );
    }

    renderEventExportICSButton() {
        const { t, event } = this.props;

        if (!event) {
            return null;
        }

        return (
            <a
                className="btn-primary"
                onClick={this.handleExportIcsClick}
                href={this.buildUrl()}
                // eslint-disable-next-line
                target="_blank">
                {t('exportIcs')}
            </a>
        );
    }

    renderEventCopyButton() {
        const { dispatch, t } = this.props;

        const { title, dateline, locations, address, description, contact, url } = this.computeData();
        const desc = description ? `${description}\n` : '';
        const addr = address ? `${address}\n` : '';
        const cont = contact ? `${contact}\n` : '';
        const text = `${title}\n
${dateline}\n
${locations}\n
${addr}
${desc}
${cont}
${url}`;

        const onCopy = () => {
            dispatch(
                notify({
                    status: 'success',
                    title: t('feedback.eventCopied'),
                    dismissible: true,
                })
            );
        };

        return (
            <CopyToClipboard text={text} onCopy={onCopy}>
                <div
                    className="btn-primary"
                    onClick={this.handleShareFilterClick}
                    onKeyPress={this.handleShareFilterClick}
                    role="button"
                    tabIndex="0">
                    {/* <span className="icon share-icon" />  */}
                    {t('copyEvent')}
                </div>
            </CopyToClipboard>
        );
    }

    renderFilterEmailButton() {
        const { filterId, lang, userLanguages, filter, t } = this.props;

        const url = `${window.location.protocol}//${window.location.host}/filters/${filterId}`;
        const title = stringForLang({ field: filter.label, lang, userLanguages });

        // const replaceLineBreaks = text => text.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');

        const subject = t('emailAgendaSubject', { title });

        const body = `${title}%0D%0A
${t('emailAgendaFind')}: ${url}`;

        return (
            <a href={`mailto:?subject=${subject}&body=${body}`} className="btn-primary" onClick={() => {}}>
                {/* <span className="icon share-icon" />  */}
                {t('email')}
            </a>
        );
    }

    renderFilterCopyButton() {
        const { dispatch, filterId, lang, userLanguages, filter, t } = this.props;

        const url = `${window.location.protocol}//${window.location.host}/filters/${filterId}`;
        const title = stringForLang({ field: filter.label, lang, userLanguages });

        const text = `${title}\n
${url}`;

        const onCopy = () => {
            dispatch(
                notify({
                    status: 'success',
                    title: t('feedback.eventCopied'),
                    dismissible: true,
                })
            );
        };

        return (
            <CopyToClipboard text={text} onCopy={onCopy}>
                <div
                    className="btn-primary"
                    onClick={this.handleShareFilterClick}
                    onKeyPress={this.handleShareFilterClick}
                    role="button"
                    tabIndex="0">
                    {/* <span className="icon share-icon" />  */}
                    {t('copyEvent')}
                </div>
            </CopyToClipboard>
        );
    }

    render() {
        const { isSharingEvent, isSharingFilter, event, filter, t } = this.props;

        const sharePageTitle = branchSharing(
            { isSharingEvent, isSharingFilter },
            {
                isSharingEventAction: t('titleEvent'),
                isSharingFilterAction: t('titleAgenda'),
                defaultAction: t('titleShare'),
            }
        );

        return (
            <div className="content-wrapper share-content">
                <h2>{sharePageTitle}</h2>
                {isSharingEvent && event && this.renderEventEmailButton()}
                {isSharingEvent && event && this.renderEventExportICSButton()}
                {isSharingEvent && event && this.renderEventCopyButton()}
                {isSharingFilter && filter && this.renderFilterEmailButton()}
                {isSharingFilter && filter && this.renderFilterCopyButton()}
                {/* {isSharingFilter && this.renderExportICSButton()} */}
                <BackButton className="btn-outline">{t('actions.back')}</BackButton>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    eventId: getEventIdFromMatch(state, ownProps),
    filterId: getFilterIdFromMatch(state, ownProps),
    filter: getFilterFromMatch(state, ownProps),
    event: getEventFromMatch(state, ownProps),
    locale: getLocale(state),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
});

ShareContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    eventId: PropTypes.string,
    filterId: PropTypes.string,
    isSharingEvent: PropTypes.bool,
    isSharingFilter: PropTypes.bool,
    event: PropTypes.shape({}),
    filter: PropTypes.shape({}),
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    t: PropTypes.func.isRequired,
};

ShareContainer.defaultProps = {
    eventId: undefined,
    event: undefined,
    filter: undefined,
    filterId: undefined,
    isSharingEvent: false,
    isSharingFilter: false,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(ShareContainer);
