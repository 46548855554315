import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { receiveEvent } from 'reducers/events/eventActions';
import { receiveEvents } from 'reducers/events/eventsActions';

import selectedEvent from './eventReducers';

// Reducers
const byId = handleActions(
  {
    [combineActions(receiveEvent, receiveEvents)]: (state, action) => ({
      ...state,
      ...action.payload.entities.events,
    }),
  },
  {}
);

const eventsReducers = combineReducers({
  byId,
  selectedEvent,
});
export default eventsReducers;
