import React, { PureComponent } from 'react';

import AsyncSelect from 'react-select/lib/Async';
import { PropTypes } from 'prop-types';
import { SEARCH_FIELD_TYPES } from 'utils/constants';
import { fetchLocations } from 'reducers/metadatas/metadatasEffects';
import isEmpty from 'lodash/isEmpty';
import { mapMetadataToOption } from 'utils/utils';
import { translate } from 'react-i18next';

const Select = ({ options, placeholder, labelTransform, ...props }) => (
    <select className="custom-select" {...props}>
        <option key="__none__">{placeholder}</option>
        {options.map(option => (
            <option key={option.value} value={option.value}>
                {labelTransform(option.label)}
            </option>
        ))}
    </select>
);
Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    placeholder: PropTypes.string.isRequired,
    labelTransform: PropTypes.func,
};
Select.defaultProps = {
    labelTransform: x => x,
};

class Refinement extends PureComponent {
    deleteRefinement = () => {
        const { handleDeleteRefinement, refinement } = this.props;
        handleDeleteRefinement(refinement.id);
    };

    changeTypeRefinement = event => {
        const { handleChangeTypeRefinement, refinement } = this.props;
        handleChangeTypeRefinement(refinement.id, event.target.value);
    };

    changeValueRefinement = event => {
        const { handleChangeValueRefinement, refinement } = this.props;
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        } else {
            ({ value } = event.target);
        }

        handleChangeValueRefinement(refinement.id, value);
    };

    changeValuesRefinement = values => {
        const { handleChangeValueRefinement, refinement } = this.props;
        handleChangeValueRefinement(refinement.id, values);
    };

    loadLocations = inputValue => {
        const { lang, userLanguages } = this.props;
        if (!inputValue) {
            return Promise.resolve({ options: [] });
        }

        return fetchLocations(inputValue, lang).then(
            results => results.map(mapMetadataToOption(lang, userLanguages)(SEARCH_FIELD_TYPES.location)),
            () => ({ options: [] })
        );
    };

    renderSelect = ({ ...props }) => {
        const { refinement } = this.props;

        return (
            <Select
                value={refinement.value}
                onChange={this.changeValueRefinement}
                onBlur={this.onBlurRefinement}
                {...props}
            />
        );
    };

    renderFieldServices = () => {
        const { services, t } = this.props;

        return this.renderSelect({
            options: services,
            placeholder: t('selectService'),
        });
    };

    renderFieldIptcs = () => {
        const { iptcs, t } = this.props;
        return this.renderSelect({
            options: iptcs,
            placeholder: t('selectIptc'),
        });
    };

    renderFieldRegions = () => {
        const { regions, t } = this.props;

        return this.renderSelect({
            options: regions,
            placeholder: t('selectRegion'),
        });
    };

    renderFieldAgendaPresets = () => {
        const { agendaPresets, t } = this.props;
        return this.renderSelect({
            options: agendaPresets,
            placeholder: t('selectAgendaPreset'),
        });
    };

    renderFieldLocations = () => {
        const { refinement, t } = this.props;
        return (
            <AsyncSelect
                className="react-select"
                classNamePrefix="react-select"
                value={refinement.value}
                loadOptions={this.loadLocations}
                onChange={this.changeValuesRefinement}
                placeholder={t('selectLocationPlaceholder')}
                loadingMessage={() => t('optionsLoading')}
                noOptionsMessage={({ inputValue }) =>
                    isEmpty(inputValue) ? t('selectLocation') : t('optionsNoResults')
                }
            />
        );
    };

    renderFieldFromDate = () => {
        const { refinement } = this.props;
        return (
            <input
                type="date"
                className="form-control"
                value={refinement.value || ''}
                onChange={this.changeValueRefinement}
            />
        );
    };

    renderFieldToDate = () => {
        const { refinement } = this.props;
        return (
            <input
                type="date"
                className="form-control"
                value={refinement.value || ''}
                onChange={this.changeValueRefinement}
            />
        );
    };

    renderFieldRelativeDuration = () => {
        const { relativeDurations, t } = this.props;
        return this.renderSelect({
            options: relativeDurations,
            placeholder: t('selectRelativeDuration'),
        });
    };

    render() {
        const { t, refinement, types } = this.props;

        return (
            <div className="refinement">
                <div className="fields">
                    <div className="select-type">
                        <select className="custom-select" value={refinement.type} onChange={this.changeTypeRefinement}>
                            <option value="">{t('selectCriteria')}</option>
                            {types.map(type => (
                                <option key={type.type} value={type.type} disabled={type.disabled}>
                                    {t(type.label)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="field">
                        {refinement.type === SEARCH_FIELD_TYPES.service && this.renderFieldServices()}
                        {refinement.type === SEARCH_FIELD_TYPES.location && this.renderFieldLocations()}
                        {refinement.type === SEARCH_FIELD_TYPES.iptcLvl1 && this.renderFieldIptcs()}
                        {refinement.type === SEARCH_FIELD_TYPES.region && this.renderFieldRegions()}
                        {refinement.type === SEARCH_FIELD_TYPES.fromdate && this.renderFieldFromDate()}
                        {refinement.type === SEARCH_FIELD_TYPES.todate && this.renderFieldToDate()}
                        {refinement.type === SEARCH_FIELD_TYPES.agendaPreset && this.renderFieldAgendaPresets()}
                        {refinement.type === SEARCH_FIELD_TYPES.relativeDuration && this.renderFieldRelativeDuration()}
                    </div>
                </div>

                <div
                    className="btn-criteria"
                    onClick={this.deleteRefinement}
                    onKeyPress={this.deleteRefinement}
                    role="button"
                    tabIndex="0">
                    <span className="icon remove-icon" />
                    {t('removeCriteria')}
                </div>
            </div>
        );
    }
}

Refinement.propTypes = {
    refinement: PropTypes.shape({}).isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    iptcs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    regions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    agendaPresets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    relativeDurations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleChangeTypeRefinement: PropTypes.func.isRequired,
    handleChangeValueRefinement: PropTypes.func.isRequired,
    handleDeleteRefinement: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(Refinement);
