import { createSelector } from 'reselect';

// Selectors
export const getIds = state => state.byList;
export const getIdMap = state => state.byId;
export const getFilter = (state, id) => getIdMap(state)[id];
export const getFilters = createSelector(
    getIds,
    getIdMap,
    (ids, idMap) => ids.map(id => idMap[id])
);
export const hasFetchFilter = (state, id) => !!getFilter(state, id);
