import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

const SpinnerComponent = ({ t }) => (
    <div className="loading">
        <div className="spinner" />
        <span className="text">{t('loading')}</span>
    </div>
);

SpinnerComponent.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate()(SpinnerComponent);
