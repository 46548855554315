import React from 'react';

const IcnSend = () => (
    // <svg width={48} height={48} {...props}>
    //     <g fill="none" fillRule="evenodd">
    //         <path d="M0 0h48v48H0z" />
    //         <path
    //             className="icon"
    //             d="M30.156 14.264l7.056 6.504c.168.192.288.432.288.696 0 .264-.096.528-.264.744l-7.008 7.464a.972.972 0 0 1-.72.336c-.144 0-.264 0-.384-.072-.384-.144-.624-.528-.624-.936v-3c-6.792 0-10.488 1.536-12.504 7.992h-1.992c.168-8.136 5.808-15.816 14.496-15.984v-3c0-.408.24-.768.6-.912a.945.945 0 0 1 1.056.168z"
    //             fill="#A3ACB1"
    //         />
    //     </g>
    // </svg>
    <span className="icon arrow-right-icon" />
);

export default IcnSend;
