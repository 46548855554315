import React from 'react';

const LegalDisclaimer = () => (
    <div className="legal-content">
        <h3>Editeur</h3>
        <p>
            Agence France-Presse, organisme autonome doté de la personnalité civile, immatriculé au Registre du Commerce
            et des Sociétés de Paris sous le numéro 775.658.354, dont le siège social est situé 11-13-15 place de la
            Bourse, 75002 Paris.
        </p>
        <p>Tel : 01 40 41 46 46</p>
        <p>
            Courriel : <a href="mailto:contact@afp.com">contact@afp.com</a>
        </p>

        <h3>Hébergeur : Agence France-Presse</h3>
        <p>Directeur de la publication : Fabrice FRIES, Président-Directeur Général de l’Agence France-Presse </p>
    </div>
);

export default LegalDisclaimer;
