import api, { mapResponseData } from 'api';

export const fetchFilters = () => {
  const url = `/filters`;

  return api.get(url).then(mapResponseData);
};

export const fetchFilter = filterId => {
  const url = `/filters/${filterId}`;

  return api.get(url).then(mapResponseData);
};

export const addFilter = filter => {
  const url = `/filters`;
  return api.post(url, filter).then(mapResponseData);
};

export const updateFilter = filter => {
  const url = `/filters`;

  return api.post(url, filter).then(mapResponseData);
};

export const deleteFilter = filterId => {
  const url = `/filters/${filterId}`;

  return api.delete(url).then(mapResponseData);
};
