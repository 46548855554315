import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import Refinement from './RefinementComponent';

const Refinements = ({ t, refinements, handleAddRefinement, ...props }) => (
    <div className="refinements">
        {refinements.map(refinement => (
            <Refinement key={refinement.id} refinement={refinement} {...props} />
        ))}
        <div
            className="btn-criteria"
            onClick={handleAddRefinement}
            onKeyPress={handleAddRefinement}
            role="button"
            tabIndex="0">
            <span className="icon add-icon" />
            <span className="text">{t('addCriteria')}</span>
        </div>
    </div>
);

Refinements.propTypes = {
    refinements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleAddRefinement: PropTypes.func.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(Refinements);
