import axios from 'axios';
import { notify } from 'reapop';

import { getIsAuthenticated } from 'reducers/selectors';

import i18n from 'i18n';
import { authTimeout } from 'reducers/users/usersActions';

export const API_URL = '/api';

export const getBasicAuthHeader = token => `Basic ${token}`;

export const getTokenAuthHeader = token => `Token ${token}`;

export const mapResponseData = response => response && response.data;

const instance = axios.create({
    baseURL: `${API_URL}`,
    data: {},
    headers: {
        'Content-Type': 'application/json',
    },
});

export const apiAbsolute = axios.create({
    data: {},
    headers: {
        'Content-Type': 'application/json',
    },
});

export const setUpInterceptors = (store /* , history */) => {
    instance.interceptors.response.use(
        response => response,
        error => {
            const {
                response: { status /* data */ },
                message,
            } = error;

            console.warn(error); // eslint-disable-line no-console

            const notification = {
                title: message || i18n.t(`httpError.${status}`),
                // message: `${data || ''}`,
                status,
                dismissible: true,
                // closeButton: true
                // dismissAfter: 3000
            };

            if (status === 401) {
                const state = store.getState();
                if (!state.user.hasTimeout) {
                    const isAuthenticated = getIsAuthenticated(state);
                    if (isAuthenticated) {
                        store.dispatch(authTimeout());
                    }
                }
            } else {
                // other error
                store.dispatch(
                    notify({
                        ...notification,
                    })
                );
            }
            return Promise.reject(error);
        }
    );
};

export default instance;
