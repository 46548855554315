import React from 'react';

const LegalDisclaimer = () => (
    <div className="legal-content">
        <h3>Published by</h3>
        <p>
            Agence France-Presse, an autonomous entity endowed with civil personality and operating in accordance with
            commercial rules registered in the Paris Trade and Companies Registrar under number 775 658 354
        </p>
        <p>Address: 11-15, Place de la Bourse, 75002 Paris (FRANCE)</p>
        <p>Phone: 01 40 41 46 46</p>
        <p>
            Email: <a href="mailto:contact@afp.com">contact@afp.com</a>
        </p>
        <p>Publication Director: Fabrice FRIES, Chairman & Chief Executive Officer</p>

        <h3>Hosted by:</h3>
        <p>Agence France-Presse</p>
    </div>
);

export default LegalDisclaimer;
