import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { translate } from 'react-i18next';

import { DateTime } from 'luxon';

import { isSameDay, isAllDay, Date, Time } from 'utils/date';

const shouldDisplayDate = event => {
  if (isSameDay(event.dateBegin, event.dateEnd)) {
    return !event.isAllDayEvent;
  }
  return true;
};

export const DateBegin = ({ event, day, absolute, locale, timezone, t }) => {
  if (!isAllDay(event) && isSameDay(event.dateBegin, day)) {
    // if (event.isAllDayEvent) {
    //     return <span className="allday">{t('allDay')}</span>;
    // }
    return <Time date={event.dateBegin} locale={locale} timezone={timezone} />;
  }
  if (
    !isAllDay(event) &&
    !isSameDay(event.dateBegin, day) &&
    isSameDay(event.dateEnd, day)
  ) {
    return <span className="from-today-to">{t('todayUntilTime')}</span>;
  }
  if (absolute && !isSameDay(event.dateBegin, day)) {
    return <Date date={event.dateBegin} locale={locale} timezone={timezone} />;
  }
  return <span className="from-today-to">{t('todayUntil')}</span>;
};
DateBegin.propTypes = {
  event: PropTypes.shape({}).isRequired,
  day: PropTypes.string,
  absolute: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  timezone: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};
DateBegin.defaultProps = {
  day: undefined,
  absolute: false,
};

export const DateEnd = ({ event, day, locale, timezone }) => {
  if (
    !isAllDay(event) &&
    !isSameDay(event.dateBegin, day) &&
    isSameDay(event.dateEnd, day)
  ) {
    return <Time date={event.dateEnd} locale={locale} timezone={timezone} />;
  }
  if (!isAllDay(event) && isSameDay(event.dateEnd, day)) {
    // if (event.isAllDayEvent) {
    //     return <Date date={event.dateEnd} locale={locale} timezone={timezone} />;
    // }
    // if (event.dateBegin !== event.dateEnd) {
    //     return <Time date={event.dateEnd} locale={locale} timezone={timezone} />;
    // }
    return null;
  }
  return <Date date={event.dateEnd} locale={locale} timezone={timezone} />;
};
DateEnd.propTypes = {
  event: PropTypes.shape({}).isRequired,
  day: PropTypes.string,
  locale: PropTypes.string.isRequired,
  timezone: PropTypes.shape({}).isRequired,
};
DateEnd.defaultProps = {
  day: undefined,
};

export const DateBlockComponent = ({
  className,
  event,
  day,
  absolute,
  locale,
  timezone,
  t,
}) => {
  const dateBeginClass = classNames(
    'from',
    isSameDay(event.dateBegin, day) && !event.isAllDayEvent ? 'time' : 'date'
  );
  const dateEndClass = classNames('to', {
    date: !isSameDay(event.dateEnd, day) || event.isAllDayEvent,
    time: isSameDay(event.dateEnd, day) && !event.isAllDayEvent,
  });

  return (
    <div className={classNames('datetime', className)}>
      {' '}
      {shouldDisplayDate(event) ? (
        <div>
          <div className={dateBeginClass}>
            <DateBegin
              event={event}
              locale={locale}
              day={day}
              absolute={absolute}
              timezone={timezone}
              t={t}
            />
          </div>
          {DateTime.fromISO(event.dateBegin).hasSame(
            DateTime.fromISO(event.dateEnd),
            'day'
          ) && !event.isAllDayEvent ? (
              <span className="separator" />
            ) : (
              <span className="arrow-down" />
            )}
          <div className={dateEndClass}>
            <DateEnd
              event={event}
              day={day}
              locale={locale}
              timezone={timezone}
            />
          </div>
        </div>
      ) : (
          <span className="allday alone"> {t('allDay')} </span>
        )}{' '}
    </div>
  );
};

DateBlockComponent.propTypes = {
  className: PropTypes.string,
  event: PropTypes.shape({}).isRequired,
  day: PropTypes.string,
  absolute: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  timezone: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

DateBlockComponent.defaultProps = {
  day: undefined,
  absolute: false,
  className: '',
};

export default translate()(DateBlockComponent);
