import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { requestAfoAdvisoriesLink, receiveAfoAdvisoriesLink } from 'reducers/afo/afoActions';
import { setLocale, apiError, redirectTo, resetRedirect } from './commonActions';

const errorReducer = handleActions(
    {
        [apiError]: (_, action) => action.payload,
    },
    null
);

const redirectReducer = handleActions(
    {
        [redirectTo]: (_, action) => action.payload,
        [resetRedirect]: () => null,
        'AUTH/LOGOUT': () => '/',
    },
    null
);

const localeReducer = handleActions(
    {
        [setLocale]: (_, action) => action.payload,
    },
    'en-GB'
);

const afoAdvisoriesLink = handleActions(
    {
        [requestAfoAdvisoriesLink]: () => '',
        [receiveAfoAdvisoriesLink]: (_, action) => action.payload,
    },
    ''
);

export default combineReducers({
    locale: localeReducer,
    error: errorReducer,
    redirectTo: redirectReducer,
    afoLink: afoAdvisoriesLink,
});
