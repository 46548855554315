import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { resetSelectedEvent } from 'reducers/events/eventActions';
import { fetchEvent, fetchAFODocs } from 'reducers/events/eventEffects';
import { exportEventsToICS } from 'reducers/ics/icsEffects';
import { stringForLang } from 'utils/locale';
import {
    getServices,
    getLocale,
    getLang,
    getUserTimezone,
    getEventFromMatch,
    getSelectedEventAfoDocs,
    getRoutingLocation,
    getEventIdFromMatch,
    getUserLanguages,
} from 'reducers/selectors';

import EventDetail from './EventDetailComponent';

class EventDetailContainer extends PureComponent {
    componentDidMount() {
        const { dispatch, eventId, event } = this.props;

        dispatch(fetchEvent(eventId));
        dispatch(fetchAFODocs(eventId));
        if (event) {
            this.updateTitle(event);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch, eventId } = this.props;
        const { eventId: nextEventId, event } = nextProps;

        if (nextEventId !== eventId) {
            dispatch(fetchEvent(nextEventId));
            dispatch(fetchAFODocs(nextEventId));
            this.updateTitle(event);
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(resetSelectedEvent());
    }

    updateTitle = event => {
        const { t, lang, userLanguages } = this.props;

        document.title = `${t('titles.Event')}: ${stringForLang({
            field: event.title,
            lang,
            userLanguages,
            fallbackMessage: `event:${event.sequenceId}`,
        })}`;
    };

    handleExportToIcs = () => {
        const { dispatch, event } = this.props;
        dispatch(exportEventsToICS([event.eventId]));
    };

    render() {
        const { isLoading, services, event, afoDocs, locale, lang, userLanguages, timezone, location } = this.props;

        return !isLoading && event ? (
            <EventDetail
                event={event}
                afoDocs={afoDocs}
                partialPath=""
                services={services}
                locale={locale}
                lang={lang}
                userLanguages={userLanguages}
                timezone={timezone}
                location={location}
                actions={{
                    handleExportToIcs: this.handleExportToIcs,
                }}
            />
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => ({
    afoDocs: getSelectedEventAfoDocs(state),
    event: getEventFromMatch(state, ownProps),
    eventId: getEventIdFromMatch(state, ownProps),
    isLoading: false,
    locale: getLocale(state),
    lang: getLang(state),
    userLanguages: getUserLanguages(state),
    location: getRoutingLocation(state),
    services: getServices(state),
    timezone: getUserTimezone(state),
});

EventDetailContainer.propTypes = {
    t: PropTypes.func.isRequired,
    afoDocs: PropTypes.shape([]),
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.shape({}),
    eventId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    userLanguages: PropTypes.arrayOf(PropTypes.array).isRequired,
    location: PropTypes.shape({}).isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    timezone: PropTypes.shape({}).isRequired,
};

EventDetailContainer.defaultProps = {
    afoDocs: undefined,
    event: undefined,
};

export default compose(
    translate(),
    connect(mapStateToProps)
)(EventDetailContainer);
