import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import { stringForLang } from './locale';
import { isToday, isDateOnly, isTomorrow, duringLast48Hours } from './date';

export const isUpdated = event =>
    (isToday(event.dateBegin) || isTomorrow(event.dateBegin)) &&
    duringLast48Hours(event.dateMainPublicationInfoUpdated);

export const isPostponed = event => event.status === 'POSTPONED';

export const isCanceled = event => event.status === 'CANCELED';

export const isMagazine = (event, lang, userLanguages) =>
    event.eventTags &&
    event.eventTags.map(tag => stringForLang({ field: tag.label, lang, userLanguages })).includes('MAGAZINEFEATURE');

export const isTopNews = event => {
    if (event.topNews === undefined) {
        return false;
    }

    let value = 0;
    Object.keys(event.topNews).forEach(cat => {
        if (event.topNews[cat] > value) {
            value = event.topNews[cat];
        }
    });

    return value > 0;
};

export const areDatesNotConfirmed = event => event.areDatesNotConfirmed;

export const getDay = (date, timezone) =>
    date && DateTime.fromISO(date).setZone((timezone && timezone.code) || 'local', { keepLocalTime: isDateOnly(date) });

export const filterByDay = (events, startDate, timezone) => {
    const days = groupBy(events, event => event && getDay(event.dateBegin, timezone).toISODate());
    const daysBefore = Object.keys(days).filter(day => DateTime.fromISO(day) < startDate.startOf('day'));

    const isEndDateAfterStartDate = event => getDay(event.dateEnd, timezone) >= startDate.startOf('day');

    const notPassedEventsFromDaysBefore = daysBefore
        .reduce((acc, day) => [...acc, ...days[day]], [])
        .filter(isEndDateAfterStartDate);

    const startDay = getDay(startDate, timezone).toISODate();
    days[startDay] = [...(notPassedEventsFromDaysBefore || []), ...(days[startDay] || [])];
    daysBefore.forEach(day => delete days[day]);

    return days;
};
