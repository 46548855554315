import { createActions } from 'redux-actions';
import { normalize } from 'normalizr';

import * as schema from 'reducers/schema';

// Action Creators
const eventActions = createActions({
  EVENT: {
    FETCH: {
      REQUEST: undefined,
      RESPONSE: event => normalize(event, schema.eventSchema),
      AFO_DOCS: {
        REQUEST: undefined,
        RESPONSE: undefined,
      },
    },
  },
  SELECTED_EVENT: {
    RESET: undefined,
  },
});

// Action Creators
export const resetSelectedEvent = eventActions.selectedEvent.reset;
export const requestEvent = eventActions.event.fetch.request;
export const receiveEvent = eventActions.event.fetch.response;
export const requestAfoDocs = eventActions.event.fetch.afoDocs.request;
export const receiveAfoDocs = eventActions.event.fetch.afoDocs.response;
